import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useNotification from '../../../core/hooks/use_notification';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { getDefaultPaginationQueryValues } from '../../../core/utils/api_utils';
import deepmerge from 'deepmerge';
import FoodPartnerFilters from './filters';
import { getFoodPartnersNotificationData } from '../../../core/utils/notification_utils';
import PaginationControls from '../../common/pagination_controls';
import { getFoodPartnersAction, getFoodPartnersState } from '../../../features/food_partner/get_food_partners';
import FoodPartnersList from './food_partners_list';
import { Button } from '../../common/button';
import { ROUTE_FOOD_PARTNER_FORMS_NEW } from '../../../core/constants';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    query: ''
};

const FoodPartnersPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showErrorMessage } = useNotification();

    const getFoodPartners = useSelector(getFoodPartnersState);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const refreshList = useCallback(() => {
        dispatch(getFoodPartnersAction(queryParams))
            .then(response => showErrorMessage(getFoodPartnersNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [dispatch, queryParams, refreshList, showErrorMessage]);

    return (
        <Grid>
            <Box mb={ 4 }>
                <Typography variant="h1">Dostawcy</Typography>
            </Box>
            <Box mb={ 4 }>
                <FoodPartnerFilters
                    onClear={ () => setQueryParams(prevState => deepmerge(prevState, { query: '' })) }
                    onSearchInputChange={ query => setQueryParams(prevState => deepmerge(prevState, { query })) }
                />
            </Box>
            <Box mb={ 2 }>
                <Button onClick={ () => navigate(ROUTE_FOOD_PARTNER_FORMS_NEW) }>
                    Dodaj dostawcę
                </Button>
            </Box>
            {
                getFoodPartners?.loading &&
                <LinearProgress/>
            }
            {
                getFoodPartners?.data?.content &&
                <Box mb={ 4 }>
                    <FoodPartnersList
                        refreshList={ refreshList }/>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getFoodPartners?.data?.pageable?.pageNumber }
                            totalPages={ getFoodPartners?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Box>
            }
        </Grid>
    );
};

export default FoodPartnersPage;
