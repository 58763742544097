import { deepFreeze } from '../../utils/misc_utils';

export const PriceSuccessMessageEnum = deepFreeze({
    UPDATE_PROMOTION: 'Pomyślnie dodano/zaktualizowano promocje',
    DELETE_PROMOTION: 'Pomyślnie usunięto promocję',
    DELETE_PRODUCT_EXPIRATION_PROMOTION: 'Pomyślnie usunięto promocję',
    UPDATE_PRICES: 'Pomyślnie zaktualizowano ceny',
    UPDATE_COUPON_STATUS: 'Pomyślnie zaktualizowano status kuponu',
    DELETE_COUPON: 'Pomyślnie usunięto kupon',
    CREATE_COUPON: 'Pomyślnie dodano kupon',
    UPDATE_COUPON: 'Pomyślnie zaktualizowano kupon',
    UPDATE_COUPON_TRANSLATION: 'Pomyślnie zaktualizowano wersję językową kuponu',
    UPDATE_AUTOMATIC_COUPON_SETTING: 'Pomyślnie zaktualizowano ustawienia',
    UPDATE_AUTOMATIC_COUPON_SETTING_STATUS: 'Pomyślnie zaktualizowano status ustawienia',
    UPDATE_AUTOMATIC_COUPON_SETTING_TRANSLATION: 'Pomyślnie zaktualizowano wersję językową ustawienia',
    UPDATE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING: 'Pomyślnie zaktualizowano ustawienia',
    UPDATE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING_STATE: 'Pomyślnie zaktualizowano status ustawienia',
    DELETE_PROMOTION_CAMPAIGN: 'Pomyślnie usunięto akcje promocyjną',
    UPDATE_PROMOTION_CAMPAIGN_STATUS: 'Pomyślnie zaktualizowano status akcji promocyjnej',
    CREATE_PROMOTION_CAMPAIGN: 'Pomyślnie dodano akcję promocyjną',
    UPDATE_PROMOTION_CAMPAIGN: 'Pomyślnie zaktualizowano akcję promocyjną',
    UPDATE_PROMOTION_CAMPAIGN_NOTIFICATION: 'Pomyślnie zaktualizowano notyfikacje akcji promocyjnej',
    DELETE_PROMOTION_CAMPAIGN_NOTIFICATION: 'Pomyślnie usunięto notyfikacje akcji promocyjnej',
    UPDATE_PROMOTION_CAMPAIGN_ADVERTISEMENT: 'Pomyślnie zaktualizowano grafikę akcji promocyjnej',
    DELETE_PROMOTION_CAMPAIGN_ADVERTISEMENT: 'Pomyślnie usunięto grafikę akcji promocyjnej',
});

export const PriceErrorMessageEnum = deepFreeze({
    GET_PROMOTION: 'Pobranie promocji nie powiodło się',
    UPDATE_PROMOTION: 'Dodanie/Zaktualizowanie promocji nie powiodło się',
    DELETE_PROMOTION: 'Usunięcie promocji nie powiodło się',
    GET_PRICE: 'Pobranie ceny produktu nie powiodło się',
    UPDATE_PRICES: 'Aktualizacja cen nie powiodła się',
    GET_COUPON: 'Pobranie kuponu nie powiodło się',
    GET_COUPONS: 'Pobranie kuponów nie powiodło się',
    UPDATE_COUPON_STATUS: 'Aktualizacja statusu kuponu nie powiodła się',
    DELETE_COUPON: 'Usunięcie kuponu nie powiodło się',
    GET_COUPON_TRANSLATION: 'Pobranie tłumaczenia kuponu nie powiodło się',
    CREATE_COUPON: 'Dodanie kuponu nie powiodło się',
    UPDATE_COUPON: 'Aktualizacja kuponu nie powiodła się',
    UPDATE_COUPON_TRANSLATION: 'Aktualizacja wersji językowej kuponu nie powiodła się',
    GET_AUTOMATIC_COUPON_SETTINGS: 'Pobranie ustawień nie powiodło się',
    GET_AUTOMATIC_COUPON_SETTING: 'Pobranie ustawienia nie powiodło się',
    UPDATE_AUTOMATIC_COUPON_SETTING: 'Aktualizacja ustawienia nie powiodła się',
    UPDATE_AUTOMATIC_COUPON_SETTING_STATUS: 'Aktualizacja statusu ustawienia nie powiodła się',
    GET_AUTOMATIC_COUPON_SETTING_TRANSLATION: 'Pobranie tłumaczenia ustawienia nie powiodło się',
    UPDATE_AUTOMATIC_COUPON_SETTING_TRANSLATION: 'Aktualizacja wersji językowej ustawienia nie powiodła się',
    GET_AUTOMATIC_EXPIRATION_PROMOTION_SETTINGS: 'Pobranie ustawień nie powiodło się',
    GET_AUTOMATIC_EXPIRATION_PROMOTION_SETTING: 'Pobranie ustawienia nie powiodło się',
    UPDATE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING: 'Aktualizacja ustawienia nie powiodła się',
    UPDATE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING_STATE: 'Aktualizacja statusu ustawienia nie powiodła się',
    GET_PROMOTION_CAMPAIGN: 'Pobranie akcji promocyjnej nie powiodło się',
    GET_PROMOTION_CAMPAIGNS: 'Pobranie akcji promocyjnych nie powiodło się',
    DELETE_PROMOTION_CAMPAIGN: 'Usunięcie akcji promocyjnej nie powiodło się',
    UPDATE_PROMOTION_CAMPAIGN_STATUS: 'Aktualizacja statusu akcji promocyjnej nie powiodła się',
    CREATE_PROMOTION_CAMPAIGN: 'Dodanie akcji promocyjnej nie powiodło się',
    UPDATE_PROMOTION_CAMPAIGN: 'Aktualizacja akcji promocyjnej nie powiodła się',
    GET_PROMOTION_CAMPAIGN_NOTIFICATION: 'Pobranie notyfikacji akcji promocyjnej nie powiodło się',
    UPDATE_PROMOTION_CAMPAIGN_NOTIFICATION: 'Aktualizacja notyfikacji akcji promocyjnej nie powiodła się',
    DELETE_PROMOTION_CAMPAIGN_NOTIFICATION: 'Usunięcie notyfikacji akcji promocyjnej nie powiodło się',
    GET_PROMOTION_CAMPAIGN_ADVERTISEMENT: 'Pobranie grafiki akcji promocyjnej nie powiodło się',
    UPDATE_PROMOTION_CAMPAIGN_ADVERTISEMENT: 'Aktualizacja grafiki akcji promocyjnej nie powiodła się',
    DELETE_PROMOTION_CAMPAIGN_ADVERTISEMENT: 'Usunięcie grafiki akcji promocyjnej nie powiodło się',
});

const PriceServerErrorMessageEnum = deepFreeze({
    INVALID_PRODUCT_ID: {
        message: 'Niepoprawny produkt',
        errorCode: '400.1'
    },
    INVALID_AUTOMATIC_EXPIRATION_PROMOTION_SETTING_TYPE: {
        message: 'Niepoprawny typ',
        errorCode: '400.7'
    },
    INVALID_DISCOUNT_AMOUNT: {
        message: 'Niepoprawna ilość obniżki',
        errorCode: '400.8'
    },
    AUTOMATIC_EXPIRATION_PROMOTION_SETTING_NOT_COMPLETED: {
        message: 'Ustawienie jest niekompletne',
        errorCode: '400.9'
    },
    MISSING_POD_IDS: {
        message: 'Niepoprawna ilość podów',
        errorCode: '400.14'
    },
    INVALID_PROMOTION_CAMPAIGN_STATUS: {
        message: 'Niepoprawny status akcji promocyjnej',
        errorCode: '400.15'
    },
    CANNOT_ACTIVATE_EXPIRED_PROMOTION_CAMPAIGN: {
        message: 'Brak możliwości aktywacji archiwalnej akcji promocyjnej',
        errorCode: '400.16'
    },
    CANNOT_CHANGE_DATES_FOR_NOT_DEACTIVATED_PROMOTION_CAMPAIGN: {
        message: 'Zmiana dat niemożliwa dla nie dezaktywowanej akcji promocyjnej',
        errorCode: '400.17'
    },
    INVALID_PROMOTION_CAMPAIGN_TYPE: {
        message: 'Niepoprawny typ akcji promocyjnej',
        errorCode: '400.18'
    },
});

export const getPriceErrorMessage = (message, defaultErrorMessage) => {
    for (let key in PriceServerErrorMessageEnum) {
        if (PriceServerErrorMessageEnum.hasOwnProperty(key)) {
            if (message?.substring(0, message?.indexOf(' ')) === PriceServerErrorMessageEnum[key].errorCode) {
                return PriceServerErrorMessageEnum[key].message;
            }
        }
    }

    return defaultErrorMessage;
};
