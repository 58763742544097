import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import { FormHelperText, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import EditIcon from '@mui/icons-material/Edit';
import _ from 'lodash';

import useNotification from '../../../../../../core/hooks/use_notification';
import { Button, SubmitButton } from '../../../../../common/button';
import TextFieldFormik from '../../../../../common/text_field';
import { updateProductQuantityLimitAction, updateProductQuantityLimitState } from '../../../../../../features/reservation/update_prduct_quantity_limit';
import { getAllPodsState } from '../../../../../../features/pod/get_all_pods';
import { updateProductQuantityLimitNotificationData } from '../../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../../core/enums/common/api';

const UpdateProductQuantityLimitDialog = ({ product, date, refreshCalendar }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const updateProductQuantityLimit = useSelector(updateProductQuantityLimitState);
    const getAllPods = useSelector(getAllPodsState);

    const [open, setOpen] = useState(false);

    const onClose = () => setOpen(false);

    const initialValues = {
        newGeneralQuantityLimit: product?.generalQuantityLimit || '',
        pods: getAllPods?.data?.map(pod => (
            {
                id: pod?.id,
                quantityLimit: product?.podsQuantityLimits[pod?.id] || ''
            }
        ))
    };

    const schema = Yup.object().shape({
        newGeneralQuantityLimit: Yup.number().required(),
        pods: Yup.array().of(
            Yup.object().shape(
                {
                    id: Yup.string().required(),
                    quantityLimit: Yup.number().notRequired()
                }
            )
        )
    });

    const onSubmit = (values, { setErrors }) => {
        const errors = validate(values);

        if (errors.pods) {
            setErrors(errors);

            return;
        }

        const newPodsQuantityLimits = values?.pods
            ?.filter((podQuantityLimit) => !!podQuantityLimit.quantityLimit)
            ?.reduce((accumulator, current) => {
                accumulator[current.id] = current.quantityLimit;
                return accumulator;
            }, {});

        const form = {
            ..._.omit(values, ['pods']),
            newPodsQuantityLimits,
            date,
            productId: product?.productId
        };

        dispatch(updateProductQuantityLimitAction(form))
            .then(response => {
                showNotification(updateProductQuantityLimitNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshCalendar();
            });
    };

    const validate = (values) => {
        const errors = {
            pods: ''
        };

        const podsQuantity = values?.pods
            ?.map(pod => pod?.quantityLimit)
            ?.reduce((accumulator, current) => accumulator + current, 0);
        const generalQuantityLimit = values?.newGeneralQuantityLimit;

        if (podsQuantity > generalQuantityLimit) {
            errors.pods = `Przydzielono podom ${ podsQuantity } szt., gdy globalny limit wynosi: ${ generalQuantityLimit } szt.`;
        }

        return errors;
    };

    return (
        <>
            <EditIcon onClick={ () => setOpen(true) } cursor="pointer"/>
            <Dialog PaperProps={ { sx: { maxHeight: 800 } } } open={ open } onClose={ onClose } fullWidth maxWidth={ 'lg' }>
                <DialogTitle>Edytuj limit</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={ schema }
                        validateOnChange={ false }
                        validateOnBlur={ false }
                        onSubmit={ onSubmit }>
                        { () =>
                            <Grid component={ Form } container item rowGap={ 2 }>
                                <Grid container item>
                                    <Grid item>
                                        <TextFieldFormik
                                            name="newGeneralQuantityLimit"
                                            type="number"
                                            label="Globalny limit rezerwacji"
                                            inputProps={ { min: '1', step: '1' } }
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item>
                                    <TableContainer>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Pod</TableCell>
                                                    <TableCell align="center">Ilość zarezerwowana</TableCell>
                                                    <TableCell align="center">Limit rezerwacji</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <FieldArray name="pods">
                                                    { () => (
                                                        getAllPods?.data?.map((pod, index) => (
                                                            <TableRow
                                                                key={ pod?.id }
                                                                hover
                                                            >
                                                                <TableCell>
                                                                    {
                                                                        pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + pod?.serviceId
                                                                    }
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {
                                                                        product?.podsQuantityReservations[pod?.id] || 0
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextFieldFormik
                                                                        name={ `pods.${ index }.quantityLimit` }
                                                                        type="number"
                                                                        inputProps={ { min: '0', step: '1' } }
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    ) }
                                                </FieldArray>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid container item mt={ 2 } justifyContent="flex-end">
                                    <Grid item>
                                        <Grid container item>
                                            <FormHelperText error>
                                                <ErrorMessage name="pods"/>
                                            </FormHelperText>
                                        </Grid>
                                        <Grid item>
                                            <DialogActions sx={ { gap: 2 } }>
                                                <Button onClick={ onClose }>Zamknij</Button>
                                                <SubmitButton isLoading={ updateProductQuantityLimit?.loading }>Zapisz</SubmitButton>
                                            </DialogActions>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default UpdateProductQuantityLimitDialog;
