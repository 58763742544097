import { isNullOrUndefined } from '../utils/misc_utils';

//---------------------------------------------------------------------------------------------------------------------- environment

const ENVIRONMENT_DEVELOPMENT = 'development';
const ENVIRONMENT_TEST1 = 'test1';
const ENVIRONMENT_TEST2 = 'test2';
const ENVIRONMENT_PRODUCTION = 'production';

const getEnvironment = () => {
    if (isNullOrUndefined(process.env.REACT_APP_ENVIRONMENT)) {
        return ENVIRONMENT_DEVELOPMENT;
    }

    return process.env.REACT_APP_ENVIRONMENT.trim()
        .toLowerCase();
};

export const ENVIRONMENT = getEnvironment();

//---------------------------------------------------------------------------------------------------------------------- API

const API_BASE_URL_PRODUCTION = 'https://bento.prod.sisms.pl';
const API_BASE_URL_TEST_1 = 'https://bento1.test.sisms.pl';
const API_BASE_URL_TEST_2 = 'https://bento2.test.sisms.pl';
const API_BASE_URL_DEVELOPMENT = API_BASE_URL_TEST_1;

const getApiRootUrl = () => {
    switch (ENVIRONMENT) {
        case ENVIRONMENT_DEVELOPMENT:
            return API_BASE_URL_DEVELOPMENT;
        case ENVIRONMENT_TEST1:
            return API_BASE_URL_TEST_1;
        case ENVIRONMENT_TEST2:
            return API_BASE_URL_TEST_2;
        case ENVIRONMENT_PRODUCTION:
        default:
            return API_BASE_URL_PRODUCTION;
    }
};

export const API_BASE_URL = getApiRootUrl();

export const API_AUTHORIZATION_HEADER = 'authorization';
export const API_CONTENT_TYPE_HEADER = 'content-type';

//---------------------------------------------------------------------------------------------------------------------- API PATH's

// authorization module
export const TOKEN_PATH = '/auth/token';

// communication module
export const NOTIFICATIONS_PATH = '/communication/api/v1/web/notifications';
export const NOTIFICATION_TYPES_PATH = '/communication/api/v1/web/notifications/types';
export const DELIVERY_NOTIFICATIONS_PATH = '/communication/api/v1/web/delivery-notifications';

// customer module
export const CUSTOMER_PATH = '/customer/api/v1/web/customers';

// inventory module
export const PRODUCT_BATCHES_PATH = '/inventory/api/v1/web/product-batches';
export const PRODUCT_DEFINITION_PATH = '/inventory/api/v1/web/products';
export const INVENTORIES_PATH = '/inventory/api/v1/web/inventories'
export const COUNTERPARTIES_PATH = '/inventory/api/v1/web/counterparties'
export const WAREHOUSE_DOCUMENTS_PATH = '/inventory/api/v1/web/documents'
export const FOOD_PARTNER_ORDER_PATH = '/inventory/api/v1/web/food-partner-orders'
export const WAREHOUSES_PATH = '/inventory/api/v1/web/warehouses'

// pod module
export const PODS_PATH = '/pod/api/v1/web/pods';
export const ADVERTISEMENTS_PATH = '/pod/api/v1/web/advertisements'

// price module
export const EXPIRATION_PROMOTIONS_PATH = '/price/api/v1/web/expiration-promotions';
export const PRODUCT_PROMOTIONS_PATH = '/price/api/v1/web/product-promotions';
export const AUTOMATIC_EXPIRATION_PROMOTION_SETTINGS_PATH = '/price/api/v1/web/automatic-expiration-promotion-settings';
export const PRICES_PATH = '/price/api/v1/web/prices';
export const PROMOTION_CAMPAIGN_PATH = '/price/api/v1/web/promotion-campaigns';

// coupon module
export const COUPONS_PATH = '/coupon/api/v1/web/coupons';
export const AUTOMATIC_COUPON_SETTINGS_PATH = '/coupon/api/v1/web/automatic-coupon-settings';

// product module
export const PRODUCTS_PATH = '/product/api/v1/web/products';
export const LANGUAGES_PATH = '/product/api/v1/web/languages';
export const CONSTANTS_PATH = '/product/api/v1/web/constants';
export const PRODUCERS_PATH = '/product/api/v1/web/producers';

// food partner module
export const FOOD_PARTNERS_PATH = '/food-partner/api/v1/web/food-partners';

// store module
export const BUSINESS_DATA_PATH = '/store/api/v1/web/business-data';
export const BUSINESS_DECISIONS_PATH = '/store/api/v1/web/business-decisions';
export const BUSINESS_CLASSIFICATIONS_PATH = '/store/api/v1/web/business-classifications';
export const BUSINESS_NOTES_PATH = '/store/api/v1/web/business-notes';
export const SUPPLIERS_PATH = '/store/api/v1/web/suppliers';

// benefit module
export const EMPLOYERS_PATH = '/benefit/api/v1/web/employers';
export const EMPLOYEES_PATH = '/benefit/api/v1/web/employee-accounts';

// reservation module
export const RESERVATIONS_PATH = '/reservation/api/v1/web/reservations';
export const PRODUCTS_AVAILABILITY_PATH = '/reservation/api/v1/web/product-availabilities';

//---------------------------------------------------------------------------------------------------------------------- routes

export const ROUTE_SIGN_IN = '/logowanie';

export const ROUTE_PRODUCTS = '/produkty';
export const ROUTE_PRODUCT_FORMS = '/produkt/:productId';

export const GET_ROUTE_PRODUCT_FORMS = (productId) => {
    if (isNullOrUndefined(productId)) {
        return ROUTE_PRODUCTS;
    }

    return ROUTE_PRODUCT_FORMS.replace(':productId', productId);
};

export const ROUTE_PRODUCERS = '/producenci';
export const ROUTE_PRODUCER_FORMS = '/producenci/:producerId';
export const ROUTE_PRODUCER_FORMS_NEW = '/producenci/dodaj';


export const GET_ROUTE_PRODUCER_FORMS = (producerId) => {
    if (isNullOrUndefined(producerId)) {
        return ROUTE_PRODUCERS;
    }

    return ROUTE_PRODUCER_FORMS.replace(':producerId', producerId);
};

export const ROUTE_FOOD_PARTNERS = '/dostawcy';
export const ROUTE_FOOD_PARTNER_FORMS = '/dostawcy/:foodPartnerId';
export const ROUTE_FOOD_PARTNER_FORMS_NEW = '/dostawcy/dodaj';


export const GET_ROUTE_FOOD_PARTNER_FORMS = (foodPartnerId) => {
    if (isNullOrUndefined(foodPartnerId)) {
        return ROUTE_FOOD_PARTNERS;
    }

    return ROUTE_FOOD_PARTNER_FORMS.replace(':foodPartnerId', foodPartnerId);
};

export const ROUTE_EMPLOYERS = '/pracodawcy';
export const ROUTE_EMPLOYER = '/pracodawca/:employerId'

export const GET_ROUTE_EMPLOYER = (employerId) => {
    if (isNullOrUndefined(employerId)) {
        return ROUTE_EMPLOYERS;
    }

    return ROUTE_EMPLOYER.replace(':employerId', employerId);
};

export const ROUTE_SUPPLIERS = '/zaopatrzeniowcy';
export const ROUTE_SUPPLIERS_NEW = '/zaopatrzeniowcy/dodaj';
export const ROUTE_SUPPLIERS_EDIT = '/zaopatrzeniowcy/:supplierId';

export const GET_ROUTE_SUPPLIERS_EDIT = (supplierId) => {
    if (isNullOrUndefined(supplierId)) {
        return ROUTE_SUPPLIERS;
    }

    return ROUTE_SUPPLIERS_EDIT.replace(':supplierId', supplierId);
};

export const ROUTE_SALES = '/sprzedaz';
export const ROUTE_POD_SALES = '/sprzedaz/:podId';

export const GET_ROUTE_POD_SALES = (podId) => {
    if (isNullOrUndefined(podId)) {
        return ROUTE_SALES;
    }

    return ROUTE_POD_SALES.replace(':podId', podId);
};

export const ROUTE_PROMOTIONS = '/promocje';
export const ROUTE_EXPIRATION_PROMOTIONS = '/promocje/krotka-data-przydatnosci/:productId';

export const GET_ROUTE_EXPIRATION_PROMOTIONS = (productId) => {
    if (isNullOrUndefined(productId)) {
        return ROUTE_PROMOTIONS;
    }

    return ROUTE_EXPIRATION_PROMOTIONS.replace(':productId', productId);
};

export const ROUTE_PRODUCT_PROMOTIONS = '/promocje/produkt/:productId';

export const GET_ROUTE_PRODUCT_PROMOTIONS = (productId) => {
    if (isNullOrUndefined(productId)) {
        return ROUTE_PROMOTIONS;
    }

    return ROUTE_PRODUCT_PROMOTIONS.replace(':productId', productId);
};

export const ROUTE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING_FORMS = '/ustawienia-automatycznych-promocji-na-krotka-date-przydatnosci/:type'

export const GET_ROUTE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING_FORMS = (type) => {
    if (isNullOrUndefined(type)) {
        return ROUTE_PROMOTIONS;
    }

    return ROUTE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING_FORMS.replace(':type', type);
};

export const ROUTE_PROMOTION_CAMPAIGN_FORMS = '/akcje-promocyjne/:promotionCampaignId'
export const ROUTE_PROMOTION_CAMPAIGN_FORMS_NEW = '/akcje-promocyjne/dodaj';

export const GET_ROUTE_PROMOTION_CAMPAIGN_FORMS = (promotionCampaignId) => {
    if (isNullOrUndefined(promotionCampaignId)) {
        return ROUTE_PROMOTION_CAMPAIGN_FORMS;
    }

    return ROUTE_PROMOTION_CAMPAIGN_FORMS.replace(':promotionCampaignId', promotionCampaignId);
};

export const ROUTE_NOTIFICATIONS = '/powiadomienia';
export const ROUTE_NOTIFICATIONS_NEW = '/powiadomienia/dodaj';

export const ROUTE_PRICES = '/ceny';

export const ROUTE_COUPONS = '/kupony';
export const ROUTE_COUPON_FORMS = '/kupon/:couponId';
export const ROUTE_COUPON_FORMS_NEW = '/kupon/dodaj';

export const GET_ROUTE_COUPON_FORMS = (couponId) => {
    if (isNullOrUndefined(couponId)) {
        return ROUTE_COUPONS;
    }

    return ROUTE_COUPON_FORMS.replace(':couponId', couponId);
};

export const ROUTE_AUTOMATIC_COUPON_SETTING_FORMS = '/ustawienia-automatycznych-kuponow/:automaticCouponType'

export const GET_ROUTE_AUTOMATIC_COUPON_SETTING_FORMS = (automaticCouponType) => {
    if (isNullOrUndefined(automaticCouponType)) {
        return ROUTE_COUPONS;
    }

    return ROUTE_AUTOMATIC_COUPON_SETTING_FORMS.replace(':automaticCouponType', automaticCouponType);
};

export const ROUTE_ADVERTISEMENTS = '/reklamy'
export const ROUTE_POD_ADVERTISEMENTS = '/reklamy/:podId'

export const GET_ROUTE_POD_ADVERTISEMENTS = (podId) => {
    if (isNullOrUndefined(podId)) {
        return ROUTE_ADVERTISEMENTS;
    }

    return ROUTE_POD_ADVERTISEMENTS.replace(':podId', podId);
};

export const ROUTE_RESERVATIONS = '/rezerwacje';

export const ROUTE_WAREHOUSE = '/magazyn';
export const ROUTE_WAREHOUSE_DOCUMENT_FORM = '/magazyn/dodaj'

export const ROUTE_FOOD_PARTNERS_ORDER = '/zamowienia-od-dostawcow';
export const ROUTE_FOOD_PARTNER_ORDERS_NEW = '/zamowienia-od-dostawcow/dodaj';
export const ROUTE_FOOD_PARTNER_ORDERS_EDIT = '/zamowienia-od-dostawcow/edytuj/:foodPartnerOrderId';
export const ROUTE_FOOD_PARTNER_ORDERS_VIEW = '/zamowienia-od-dostawcow/:foodPartnerOrderId';

export const GET_ROUTE_FOOD_PARTNER_ORDER_EDIT = (foodPartnerOrderId) => {
    if (isNullOrUndefined(foodPartnerOrderId)) {
        return ROUTE_FOOD_PARTNERS_ORDER;
    }

    return ROUTE_FOOD_PARTNER_ORDERS_EDIT.replace(':foodPartnerOrderId', foodPartnerOrderId);
};

export const GET_ROUTE_FOOD_PARTNER_ORDER = (foodPartnerOrderId) => {
    if (isNullOrUndefined(foodPartnerOrderId)) {
        return ROUTE_FOOD_PARTNERS_ORDER;
    }

    return ROUTE_FOOD_PARTNER_ORDERS_VIEW.replace(':foodPartnerOrderId', foodPartnerOrderId);
};

export const ROUTE_DELIVERY_NOTIFICATIONS = '/powiadomienia-dostaw';
export const ROUTE_DELIVERY_NOTIFICATIONS_NEW = '/powiadomienia-dostaw/dodaj';
export const ROUTE_DELIVERY_NOTIFICATIONS_EDIT = '/powiadomienia-dostaw/:deliveryNotificationId';

export const GET_ROUTE_ROUTE_DELIVERY_NOTIFICATIONS_EDIT = (deliveryNotificationId) => {
    if (isNullOrUndefined(deliveryNotificationId)) {
        return ROUTE_DELIVERY_NOTIFICATIONS;
    }

    return ROUTE_DELIVERY_NOTIFICATIONS_EDIT.replace(':deliveryNotificationId', deliveryNotificationId);
};

//---------------------------------------------------------------------------------------------------------------------- misc

// configuration related
export const MISC_STORAGE_ENGINE_NAME = 'store-web-app';

// date related
export const MISC_DATE_ISO_DATE_ONLY_FORMAT = 'YYYY-MM-DD';
export const MISC_DATE_ISO_TIME_ONLY_FORMAT = 'HH:mm:ss';
export const MISC_DATE_ISO_FORMAT = MISC_DATE_ISO_DATE_ONLY_FORMAT + 'T' + MISC_DATE_ISO_TIME_ONLY_FORMAT;
export const MISC_DATE_POLISH_FORMAT = 'DD.MM.YYYY HH:mm:ss';
export const MISC_DATE_POLISH_DATE_ONLY_FORMAT = 'DD.MM.YYYY';

// rest
export const MISC_REST_CONTENT_TYPE_APPLICATION_JSON = 'application/json';
export const MISC_REST_CONTENT_TYPE_MULTIPART = 'multipart/form-data';
export const MISC_REST_CONTENT_TYPE_URLENCODED = 'application/x-www-form-urlencoded';
