import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Form, Formik } from 'formik';
import { Grid, Typography } from '@mui/material';
import _ from 'lodash';
import * as Yup from 'yup';
import moment, { isMoment } from 'moment';

import useNotification from '../../../../../core/hooks/use_notification';
import { getConstantsState } from '../../../../../features/product/get_constants';
import { getCouponState } from '../../../../../features/coupon/get_coupon';
import FileInput from '../../../../common/file_input';
import { isNullOrUndefined } from '../../../../../core/utils/misc_utils';
import TextFieldFormik from '../../../../common/text_field';
import { SubmitButton } from '../../../../common/button';
import RadioGroupFormik from '../../../../common/radio_group';
import { AutocompleteFormik, AutocompleteMultipleFormik, AutocompleteMultipleSearchOnTypeFormik } from '../../../../common/autocomplete';
import { getCustomersByPhoneNumberAction } from '../../../../../features/customer/get_customers_by_phone_number';
import { createCouponNotificationData, getCustomersByPhoneNumberNotificationData, getProductBatchesNotificationData, updateCouponNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import { SelectFieldFormik, SelectFieldMultipleFormik } from '../../../../common/select_field';
import { CouponTypeEnum } from '../../../../../core/enums/price/coupon';
import { getAllProductsState } from '../../../../../features/product/get_all_products';
import { getProductBatchesAction, getProductBatchesState } from '../../../../../features/inventory/get_product_batches';
import { getCustomersState } from '../../../../../features/customer/get_customers';
import { createCouponAction, createCouponState } from '../../../../../features/coupon/create_coupon';
import { updateCouponAction, updateCouponState } from '../../../../../features/coupon/update_coupon';
import { GET_ROUTE_COUPON_FORMS, MISC_DATE_ISO_FORMAT } from '../../../../../core/constants';
import { FormRouteEnum } from '../../../../../core/enums/common/route';
import { yesNoOptions } from '../../../../common/form_utils';
import { parseDateString } from '../../../../../core/utils/date_utils';
import { DateTimePickerFormik } from '../../../../common/date_picker';
import { getCouponPicture } from '../../../../../features/coupon';
import { updateCouponTranslationAction } from '../../../../../features/coupon/update_coupon_translation';

const couponDiscountTypeOptions = [
    { value: 'PERCENTAGE', label: 'Procentowa' },
    { value: 'AMOUNT', label: 'Kwotowa' },
];

const couponTypeOptions = [
    { value: CouponTypeEnum.ORDER, label: 'Zamówienie' },
    { value: CouponTypeEnum.MULTI_PACK, label: 'Multi pack' },
    { value: CouponTypeEnum.SET_PRODUCT, label: 'Zestaw - produkty' },
    { value: CouponTypeEnum.SET_FOOD_TYPE, label: 'Zestaw - typy produktów' },
    { value: CouponTypeEnum.PRODUCT, label: 'Produkt' },
    { value: CouponTypeEnum.PRODUCT_BATCH, label: 'Partie produktu' },
    { value: CouponTypeEnum.FOOD_TYPE, label: 'Typy produktu' },
    { value: CouponTypeEnum.TYPE_CUISINE, label: 'Typy kuchni' },
    { value: CouponTypeEnum.DIET, label: 'Diety' },
    { value: CouponTypeEnum.ALLERGEN, label: 'Alergeny' },
    { value: CouponTypeEnum.ATTRIBUTE, label: 'Atrybuty' },
];

const CouponForm = ({ createNewForm, couponId, refreshData, duplicateMode, translationToDuplicate, disableForm }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showNotification, showErrorMessage } = useNotification();

    const getCoupon = useSelector(getCouponState);
    const getAllPods = useSelector(getAllPodsState);
    const getConstants = useSelector(getConstantsState);
    const getAllProducts = useSelector(getAllProductsState);
    const getProductBatches = useSelector(getProductBatchesState);
    const getCustomers = useSelector(getCustomersState);
    const createCoupon = useSelector(createCouponState);
    const updateCoupon = useSelector(updateCouponState);

    const formikRef = useRef();

    const mapCustomersOptions = useCallback((data) => {
        return data?.map(customer => ({
            key: customer?.id + Math.random(),
            value: customer?.id,
            label: customer?.name + ', tel: ' + customer?.phoneNumber
        }));
    }, []);

    const mapProductBatchOptions = useCallback((data) => {
        return data?.map(productBatch => ({
            key: productBatch?.id + Math.random(),
            value: productBatch?.productBatchId,
            label: `${ productBatch?.productBatchId }, nr magazynowy: ${ productBatch?.warehouseBatchNumber }`,
            expiryDate: productBatch?.expiryDate
        }));
    }, []);

    const mapProductOptions = useCallback((data) => {
        return data?.map(product => ({
            key: product?.id + Math.random(),
            value: product?.id,
            label: product?.name
        }));
    }, []);

    const [picture, setPicture] = useState(null);
    const [customersOptions, setCustomersOptions] = useState(mapCustomersOptions(getCustomers?.data) || []);
    const [loadingCustomersOptions, setLoadingCustomersOptions] = useState(false);
    const [productBatchOptions, setProductBatchOptions] = useState(mapProductBatchOptions(getProductBatches?.data) || []);

    const initialValues = {
        workingName: getCoupon?.data?.workingName || '',
        couponDiscountType: getCoupon?.data?.couponDiscountType || couponDiscountTypeOptions[0].value,
        discount: getCoupon?.data?.discount || '',
        requiredLoyaltyPoints: getCoupon?.data?.requiredLoyaltyPoints || 0,
        timeFrom: duplicateMode ? '' : (getCoupon?.data?.timeFrom ? moment(getCoupon?.data?.timeFrom) : ''),
        withoutEndDate: duplicateMode ? true : getCoupon?.data?.withoutEndDate || false,
        timeTo: duplicateMode ? '' : (getCoupon?.data?.timeTo ? moment(getCoupon?.data?.timeTo) : ''),
        allCustomers: getCoupon?.data?.allCustomers || false,
        customers: mapCustomersOptions(getCustomers?.data?.filter(customer => getCoupon?.data?.customerIds?.includes(customer?.id))) || [],
        allPods: getCoupon?.data?.allPods || false,
        podIds: getCoupon?.data?.podIds || [],
        couponType: getCoupon?.data?.couponType || '',
        product: [getAllProducts?.data?.find(product => product?.id === getCoupon?.data?.productId)]
            .map(product => product === undefined
                ? ''
                : ({
                    key: product?.id + Math.random(),
                    value: product?.id,
                    label: product?.name
                })
            )[0] || '',
        productBatches: mapProductBatchOptions(getProductBatches?.data?.filter(productBatch => getCoupon?.data?.productBatchIds?.includes(productBatch?.id))) || [],
        constantValueIds: getCoupon?.data?.constantValueIds || [],
        products: mapProductOptions(getAllProducts?.data?.filter(product => getCoupon?.data?.productIds?.includes(product?.id))) || [],
        multiPackQuantity: getCoupon?.data?.multiPackQuantity || '',
        reusable: getCoupon?.data?.reusable || false,
        automaticallyAddCustomerCoupons: getCoupon?.data?.automaticallyAddCustomerCoupons || false,
        picture: null
    };

    const schema = Yup.object().shape({
        workingName: Yup.string().required(),
        couponDiscountType: Yup.string().required(),
        discount: Yup.number()
            .required()
            .min(0.01)
            .max(100.00),
        requiredLoyaltyPoints: Yup.number().when('automaticallyAddCustomerCoupons', {
            is: true,
            then: Yup.number()
                .min(0, 'Automatyczne aktywowanie kuponu klientom musi mieć 0 punktów promocyjnych')
                .max(0, 'Automatyczne aktywowanie kuponu klientom musi mieć 0 punktów promocyjnych')
                .required(),
            otherwise: Yup.number()
                .min(0)
                .required()
        }),
        timeFrom: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required(),
        withoutEndDate: Yup.bool().required(),
        timeTo: Yup.date().when('withoutEndDate', {
            is: false,
            then: Yup.date()
                .transform(parseDateString)
                .nullable()
                .required('Data do wymagana'),
            otherwise: Yup.date()
                .transform(parseDateString)
                .nullable()
                .notRequired()
        }),
        allCustomers: Yup.bool().required(),
        customers: Yup.array().when('allCustomers', {
            is: false,
            then: Yup.array().min(1),
            otherwise: Yup.array().notRequired()
        }),
        allPods: Yup.bool().required(),
        podIds: Yup.array().when('allPods', {
            is: false,
            then: Yup.array().min(1),
            otherwise: Yup.array().notRequired()
        }),
        couponType: Yup.string().required(),
        product: Yup.object().when('couponType', {
            is: (couponType) => [CouponTypeEnum.PRODUCT, CouponTypeEnum.PRODUCT_BATCH, CouponTypeEnum.MULTI_PACK].includes(couponType),
            then: Yup.object().required(),
            otherwise: Yup.object().notRequired()
        }),
        productBatches: Yup.array().when('couponType', {
            is: (couponType) => CouponTypeEnum.PRODUCT_BATCH === couponType,
            then: Yup.array().min(1),
            otherwise: Yup.array().notRequired()
        }),
        constantValueIds: Yup.array()
            .when('couponType', {
                is: (couponType) => [CouponTypeEnum.FOOD_TYPE, CouponTypeEnum.TYPE_CUISINE, CouponTypeEnum.DIET, CouponTypeEnum.ALLERGEN, CouponTypeEnum.ATTRIBUTE].includes(couponType),
                then: Yup.array().min(1),
                otherwise: Yup.array().notRequired()
            })
            .when('couponType', {
                is: (couponType) => couponType === CouponTypeEnum.SET_FOOD_TYPE,
                then: Yup.array().min(2),
                otherwise: Yup.array().notRequired()
            }),
        products: Yup.array().when('couponType', {
            is: (couponType) => couponType === CouponTypeEnum.SET_PRODUCT,
            then: Yup.array().min(2),
            otherwise: Yup.array().notRequired()
        }),
        multiPackQuantity: Yup.number().when('couponType', {
            is: (couponType) => couponType === CouponTypeEnum.MULTI_PACK,
            then: Yup.number().required().min(1),
            otherwise: Yup.number().notRequired()
        }),
        reusable: Yup.bool().required(),
        automaticallyAddCustomerCoupons: Yup.bool().required()
    });

    useEffect(() => {
        (duplicateMode && getCoupon?.data?.pictureUrl !== null) && dispatch(getCouponPicture({ pictureUrl: getCoupon.data.pictureUrl }))
            .then(response => {
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    const file = new File([response.payload], getCoupon.data.pictureUrl.substring(getCoupon.data.pictureUrl.lastIndexOf('/') + 1));
                    onAttach(file, formikRef.current);
                }
            });
    }, [getCoupon, duplicateMode, dispatch, formikRef, showErrorMessage]);

    const onCustomersInputChange = (phoneNumber, formik) => {
        if (phoneNumber.length >= 3) {
            setCustomersOptions([]);
            setLoadingCustomersOptions(true);

            getCustomersDebounced(phoneNumber, formik);
        }
    };

    const getCustomersDebounced = _.debounce((phoneNumber, formik) => {
        dispatch(getCustomersByPhoneNumberAction(phoneNumber))
            .then(response => {
                showErrorMessage(getCustomersByPhoneNumberNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    const newCustomerOptions = mapCustomersOptions(response?.payload).concat(formik.values.customers);
                    const newDistinctCustomerOptions = [...new Map(newCustomerOptions.map((option) => [option['value'], option])).values()];
                    setCustomersOptions(newDistinctCustomerOptions);
                }
                setLoadingCustomersOptions(false);
            });
    }, 600);

    const onCouponTypeChange = (couponType, formik) => {
        ![CouponTypeEnum.PRODUCT, CouponTypeEnum.PRODUCT_BATCH, CouponTypeEnum.MULTI_PACK].includes(couponType) && formik.setFieldValue('product', '');
        formik.setFieldValue('productBatches', []);
        formik.setFieldValue('constantValueIds', []);
        formik.setFieldValue('multiPackQuantity', '');
        formik.setFieldValue('products', []);

        if (couponType === CouponTypeEnum.PRODUCT_BATCH && formik.values.product) {
            onProductChange(formik.values.product, formik, true);
        }
    };

    const onProductChange = (product, formik, force) => {
        if (formik.values.couponType === CouponTypeEnum.PRODUCT_BATCH || force) {
            formik.setFieldValue('productBatches', []);
            dispatch(getProductBatchesAction(product.value))
                .then(response => {
                    showErrorMessage(getProductBatchesNotificationData(response));
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && setProductBatchOptions(mapProductBatchOptions(response?.payload));
                });
        }
    };

    const onAttach = (file, formik) => {
        let reader = new FileReader();
        reader.onloadend = () => {
            setPicture(reader.result);
        };
        reader.readAsDataURL(file);

        formik && formik.setFieldValue('picture', file);
    };

    const onRemove = (formik) => {
        formik.setFieldValue('picture', null);
        setPicture(null);
        formik.setFieldValue('deletePicture', true);
    };

    const onSubmit = (values) => {
        if (disableForm) return;

        const customerIds = _.pick(values, ['customers'])?.customers?.map(customer => customer?.value) || [];
        const productBatchIds = _.pick(values, ['productBatches'])?.productBatches?.map(productBatch => productBatch?.value) || [];
        const productId = _.pick(values, ['product'])?.product?.value || '';
        const productIds = _.pick(values, ['products'])?.products?.map(product => product?.value) || [];
        const timeFrom = _.pick(values, ['timeFrom'])?.timeFrom?.format(MISC_DATE_ISO_FORMAT);
        const timeTo = isMoment(_.pick(values, ['timeTo'])?.timeTo) ? _.pick(values, ['timeTo']).timeTo.format(MISC_DATE_ISO_FORMAT) : '';

        const form = {
            ..._.omit(values, ['picture', 'customers', 'productBatches', 'product', 'products', 'timeFrom', 'timeTo',]),
            customerIds,
            productBatchIds,
            productId,
            productIds,
            timeFrom,
            timeTo
        };
        const picture = values.picture;

        if (createNewForm) {
            dispatch(createCouponAction({ form, picture }))
                .then(response => {
                    showNotification(createCouponNotificationData(response));
                    if (duplicateMode) {
                        translationToDuplicate && translationToDuplicate.forEach(translation => {
                            dispatch(updateCouponTranslationAction({ form: translation, couponId: response?.payload }));
                        });
                    }
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && navigate(GET_ROUTE_COUPON_FORMS(response?.payload), { state: { action: FormRouteEnum.EDIT } });
                });
        } else {
            dispatch(updateCouponAction({ form, couponId, picture }))
                .then(response => {
                    showNotification(updateCouponNotificationData(response));
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshData();
                });
        }
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmit }
            innerRef={ formikRef }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 4 }>
                    <Grid container item md={ 12 } lg={ 6 } direction="column" rowGap={ 4 }>
                        <Grid container item justifyContent="center">
                            {
                                picture || (!duplicateMode && !formik?.values?.deletePicture && getCoupon?.data?.pictureUrl)
                                    ?
                                    <img src={ picture || getCoupon?.data?.pictureUrl }
                                         alt="Nie udało się wczytać zdjęcia kuponu"
                                         width={ 400 }
                                         height={ 300 }/>
                                    : <Typography width={ 400 } textAlign="center" variant="h6">Brak zdjęcia</Typography>
                            }
                        </Grid>
                        {
                            !disableForm &&
                            <Grid container item>
                                <FileInput
                                    label="Zdjęcie kuponu"
                                    text="Dodaj lub przeciągnij plik png/jpg"
                                    errorMessage="Niepoprawny plik. Wymagany png lub jpg."
                                    validate={ picture => ['image/png', 'image/jpeg'].includes(picture.type) }
                                    isAttached={ !isNullOrUndefined(formik.values.picture) || (!formik?.values?.deletePicture && getCoupon?.data?.pictureUrl) }
                                    onAttach={ (picture) => onAttach(picture, formik) }
                                    onRemove={ () => onRemove(formik) }
                                    uploadedFileName={ 'Aktualne zdjęcie kuponu' }
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid container item md={ 12 } lg={ 6 } rowGap={ 2 } alignContent="flex-start">
                        <Grid container item>
                            <TextFieldFormik name="workingName" label="Nazwa robocza" required disabled={ disableForm }/>
                        </Grid>
                        <Grid container item>
                            <RadioGroupFormik
                                name="couponDiscountType"
                                label="Typ obniżki"
                                options={ couponDiscountTypeOptions }
                                disabled={ disableForm }
                                booleanOptions={ false }
                            />
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <TextFieldFormik name="discount" label="Obniżka" type="number" required disabled={ disableForm } inputProps={ { min: '0.01', max: '100.00', step: '0.01' } }/>
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <TextFieldFormik name="requiredLoyaltyPoints" label="Wymagana ilość punktów promocyjnych" type="number" required disabled={ disableForm } inputProps={ { min: '0', step: '1' } }/>
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <DateTimePickerFormik
                                    name="timeFrom"
                                    label="Data od"
                                    required
                                    disabled={ disableForm }
                                    InputLabelProps={ { shrink: true } }
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <RadioGroupFormik
                                name="withoutEndDate"
                                label="Bez daty końcowej"
                                options={ yesNoOptions }
                                disabled={ disableForm }
                                onChange={ (value) => value === true && formik.setFieldValue('timeTo', '') }
                            />
                        </Grid>
                        {
                            formik.values.withoutEndDate === false &&
                            <Grid container item>
                                <Grid item xs={ 6 }>
                                    <DateTimePickerFormik
                                        name="timeTo"
                                        label="Data do"
                                        required={ formik.values.withoutEndDate === false }
                                        disabled={ disableForm }
                                        InputLabelProps={ { shrink: true } }
                                    />
                                </Grid>
                            </Grid>
                        }
                        <Grid container item>
                            <RadioGroupFormik
                                name="allCustomers"
                                label="Wszyscy klienci"
                                options={ yesNoOptions }
                                disabled={ disableForm }
                                onChange={ (value) => value === true && formik.setFieldValue('customers', []) }
                            />
                        </Grid>
                        {
                            formik.values.allCustomers === false &&
                            <Grid container item>
                                <AutocompleteMultipleSearchOnTypeFormik
                                    options={ customersOptions }
                                    name="customers"
                                    label="Klienci - wiele (wpisz min. 3 cyfry telefonu)"
                                    required={ formik.values.allCustomers === false }
                                    onInputChange={ (value) => onCustomersInputChange(value, formik) }
                                    disabled={ disableForm }
                                    loading={ loadingCustomersOptions }
                                />
                            </Grid>
                        }
                        <Grid container item>
                            <RadioGroupFormik
                                name="allPods"
                                label="Wszystkie pody"
                                options={ yesNoOptions }
                                disabled={ disableForm }
                                onChange={ (value) => value === true && formik.setFieldValue('podIds', []) }
                            />
                        </Grid>
                        {
                            formik.values.allPods === false &&
                            <Grid container item>
                                <SelectFieldMultipleFormik
                                    name="podIds"
                                    label="Wybierz pody - wiele"
                                    options={ getAllPods?.data?.map(pod => ({ value: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + pod?.serviceId })) }
                                    disabled={ disableForm }
                                    required={ formik.values.allPods === false }
                                />
                            </Grid>
                        }
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="couponType"
                                    label="Typ kuponu"
                                    options={ couponTypeOptions }
                                    required
                                    disabled={ disableForm }
                                    onChange={ (value) => onCouponTypeChange(value, formik) }
                                />
                            </Grid>
                        </Grid>
                        {
                            [CouponTypeEnum.PRODUCT, CouponTypeEnum.PRODUCT_BATCH, CouponTypeEnum.MULTI_PACK].includes(formik.values.couponType) &&
                            <Grid container item>
                                <AutocompleteFormik
                                    name="product"
                                    label="Produkt"
                                    options={ mapProductOptions(getAllProducts?.data) }
                                    required={ [CouponTypeEnum.PRODUCT, CouponTypeEnum.PRODUCT_BATCH, CouponTypeEnum.MULTI_PACK].includes(formik.values.couponType) }
                                    disabled={ disableForm }
                                    onChange={ (value) => onProductChange(value, formik) }
                                />
                            </Grid>
                        }
                        {
                            (formik.values.couponType === CouponTypeEnum.PRODUCT_BATCH && formik.values.product) &&
                            <Grid container item>
                                <AutocompleteMultipleFormik
                                    options={ productBatchOptions }
                                    name="productBatches"
                                    label="Wybierz partie produktu - wiele"
                                    required={ formik.values.couponType === CouponTypeEnum.PRODUCT_BATCH }
                                    disabled={ disableForm }
                                    groupBy={ option => option.expiryDate }
                                />
                            </Grid>
                        }
                        {
                            (formik.values.couponType === CouponTypeEnum.MULTI_PACK && formik.values.product) &&
                            <Grid container item>
                                <Grid item xs={ 6 }>
                                    <TextFieldFormik name="multiPackQuantity" label="Multi pack - ilość sztuk" type="number" required disabled={ disableForm } inputProps={ { min: '1', step: '1' } }/>
                                </Grid>
                            </Grid>
                        }
                        {
                            [
                                CouponTypeEnum.FOOD_TYPE,
                                CouponTypeEnum.TYPE_CUISINE,
                                CouponTypeEnum.DIET,
                                CouponTypeEnum.ALLERGEN,
                                CouponTypeEnum.ATTRIBUTE,
                                CouponTypeEnum.SET_FOOD_TYPE
                            ].includes(formik.values.couponType) &&
                            <Grid container item>
                                <SelectFieldMultipleFormik
                                    name="constantValueIds"
                                    label="Zmienne - wiele"
                                    options={ getConstants?.data?.productConstants?.[formik.values.couponType === CouponTypeEnum.SET_FOOD_TYPE ? CouponTypeEnum.FOOD_TYPE : formik.values.couponType]?.map(productConstant => ({ value: productConstant?.id, label: productConstant?.body })) }
                                    required={ [
                                        CouponTypeEnum.FOOD_TYPE,
                                        CouponTypeEnum.TYPE_CUISINE,
                                        CouponTypeEnum.DIET,
                                        CouponTypeEnum.ALLERGEN,
                                        CouponTypeEnum.ATTRIBUTE,
                                        CouponTypeEnum.SET_FOOD_TYPE
                                    ].includes(formik.values.couponType) }
                                    disabled={ disableForm }
                                />
                            </Grid>
                        }
                        {
                            formik.values.couponType === CouponTypeEnum.SET_PRODUCT &&
                            <Grid container item>
                                <AutocompleteMultipleFormik
                                    options={ mapProductOptions(getAllProducts?.data) }
                                    name="products"
                                    label="Wybierz produkty - wiele"
                                    required={ formik.values.couponType === CouponTypeEnum.SET_PRODUCT }
                                    disabled={ disableForm }
                                />
                            </Grid>
                        }
                        <Grid container item>
                            <RadioGroupFormik
                                name="reusable"
                                label="Wielokrotnego użytku"
                                options={ yesNoOptions }
                                disabled={ disableForm }
                            />
                        </Grid>
                        <Grid container item>
                            <RadioGroupFormik
                                name="automaticallyAddCustomerCoupons"
                                label="Automatycznie aktywuj kupon klientom"
                                options={ yesNoOptions }
                                disabled={ disableForm }
                            />
                        </Grid>
                        {
                            !disableForm &&
                            <Grid container item mt={ 2 } justifyContent="flex-end">
                                <SubmitButton isLoading={ createNewForm ? createCoupon?.loading : updateCoupon?.loading }>Zapisz</SubmitButton>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            }
        </Formik>
    );
};

export default CouponForm;
