import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Accordion, AccordionDetails, AccordionSummary, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import moment from 'moment';

import { getNotificationsState } from '../../../features/communication/get_notifications';
import { MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT, ROUTE_NOTIFICATIONS_NEW } from '../../../core/constants';
import { getAllPodsState } from '../../../features/pod/get_all_pods';
import HtmlTooltip from '../../common/html_tooltip';
import { DeleteButton, DuplicateButton } from '../../common/button';
import { deleteNotificationAction, deleteNotificationState } from '../../../features/communication/delete_notification';
import { deleteNotificationNotificationData } from '../../../core/utils/notification_utils';
import useNotification from '../../../core/hooks/use_notification';
import { ApiRequestStatusEnum } from '../../../core/enums/common/api';
import { useNavigate } from "react-router-dom";

const NotificationsList = ({ refreshList, showCancelButton }) => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getNotifications = useSelector(getNotificationsState);
    const deleteNotification = useSelector(deleteNotificationState);
    const getAllPods = useSelector(getAllPodsState);

    const getTooltipContent = (podIds) => {
        return (
            <React.Fragment>
                {
                    podIds.map(podId => (
                        <p key={ podId }>
                            {
                                getAllPods?.data.filter(pod => pod.id === podId).map(pod => pod.indoorPartner?.name + " (" + pod.description + ') - ' + pod.serviceId)
                            }
                        </p>
                    ))
                }
            </React.Fragment>
        );
    };

    const cancelNotification = (id) => {
        dispatch(deleteNotificationAction(id))
            .then(response => {
                showNotification(deleteNotificationNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    return (

        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Data utworzenia</TableCell>
                        <TableCell>Data wysyłki</TableCell>
                        <TableCell>Typ notyfikacji</TableCell>
                        <TableCell>Wyślij do wszystkich</TableCell>
                        <TableCell align="center">Treść</TableCell>
                        <TableCell align="center">Duplikuj</TableCell>
                        {
                            showCancelButton &&
                            <TableCell align="center">Anuluj wysyłkę</TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getNotifications?.data?.content?.map(notificationRequest => (
                            <TableRow
                                key={ notificationRequest?.id }
                                hover
                            >
                                <TableCell>
                                    {
                                        moment(notificationRequest?.requestPlaced, MISC_DATE_ISO_FORMAT)
                                            .format(MISC_DATE_POLISH_FORMAT)
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                        moment(notificationRequest?.requestedRealization, MISC_DATE_ISO_FORMAT)
                                            .format(MISC_DATE_POLISH_FORMAT)
                                    }
                                </TableCell>
                                <TableCell>{ notificationRequest?.notificationType }</TableCell>
                                <TableCell>
                                    {
                                        notificationRequest?.podIds?.length === 0 &&
                                        'tak'
                                    }
                                    {
                                        notificationRequest?.podIds?.length > 0 &&
                                        <HtmlTooltip title={ getTooltipContent(notificationRequest?.podIds) } arrow>
                                            <span>
                                                { 'nie (' + notificationRequest?.podIds?.length + ')' }
                                            </span>
                                        </HtmlTooltip>
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                        notificationRequest?.translatedMessages.map(message => (
                                            <Accordion key={ message.languageCode }>
                                                <AccordionSummary>
                                                    <Typography><b>{ message.languageCode }</b> - { message.title }</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>{ message.body }</Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <DuplicateButton onClick={ () => navigate(ROUTE_NOTIFICATIONS_NEW, {state: {notificationRequest}}) }/>
                                </TableCell>
                                {
                                    showCancelButton &&
                                    <TableCell align="center">
                                        <DeleteButton onDeleteClick={ () => cancelNotification(notificationRequest?.id) } isLoading={ deleteNotification?.loading }/>
                                    </TableCell>
                                }
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default NotificationsList;
