import { deepFreeze } from '../../utils/misc_utils';

export const PodSuccessMessageEnum = deepFreeze({
    CREATE_ADVERTISEMENTS: 'Pomyślnie dodano reklamę',
    UPDATE_ADVERTISEMENTS_DISPLAY_ORDER: 'Pomyślnie zaktualizowano kolejność reklam',
    UPDATE_ADVERTISEMENT_DISPLAY_TIME: 'Pomyślnie zaktualizowano daty obowiązywania reklamy',
    UPDATE_ADVERTISEMENT_PRODUCTS: 'Pomyślnie zaktualizowano powiązane produkty',
    DELETE_ADVERTISEMENT: 'Pomyślnie usunięto reklamę'
});

export const PodErrorMessageEnum = deepFreeze({
    GET_POD: 'Pobranie poda nie powiodło się',
    GET_PODS: 'Pobranie podów nie powiodło się',
    GET_ADVERTISEMENTS: 'Pobranie reklam nie powiodło się',
    CREATE_ADVERTISEMENTS: 'Dodanie reklamy nie powiodło się',
    UPDATE_ADVERTISEMENTS_DISPLAY_ORDER: 'Aktualizacja kolejności reklam nie powiodła się',
    UPDATE_ADVERTISEMENT_DISPLAY_TIME: 'Aktualizacja dat obowiązywania reklamy nie powiodła się',
    UPDATE_ADVERTISEMENT_PRODUCTS: 'Aktualizacja powiązanych produktów nie powiodła się',
    DELETE_ADVERTISEMENT: 'Usunięcie reklamy nie powiodło się'
});

const PodServerErrorMessageEnum = deepFreeze({
    DISPLAY_ORDER_CANNOT_BE_EMPTY: {
        message: 'Brak kolejności reklamy',
        errorCode: '400.3'
    },
    INVALID_TIMES_COMBINATION: {
        message: 'Niepoprawna kombinacja dat',
        errorCode: '400.7'
    },
    CANNOT_CHANGE_DISPLAY_DATE_FROM_FOR_ACTIVE_ADVERTISEMENT: {
        message: 'Zmiana daty od jest niemożliwa dla aktywnej reklamy',
        errorCode: '400.8'
    },
});

export const getPodErrorMessage = (message, defaultErrorMessage) => {
    for (let key in PodServerErrorMessageEnum) {
        if (PodServerErrorMessageEnum.hasOwnProperty(key)) {
            if (message?.substring(0, message?.indexOf(' ')) === PodServerErrorMessageEnum[key].errorCode) {
                return PodServerErrorMessageEnum[key].message;
            }
        }
    }

    return defaultErrorMessage;
};
