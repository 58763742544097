import React from 'react';
import { Link } from 'react-router-dom';

import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RestaurantIcon from '@mui/icons-material/RestaurantMenu';
import DiscountIcon from '@mui/icons-material/Discount';
import MessageIcon from '@mui/icons-material/Message';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import CutIcon from '@mui/icons-material/ContentCut';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import CampaignIcon from '@mui/icons-material/Campaign';
import GroupsIcon from '@mui/icons-material/Groups';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

import { ROUTE_ADVERTISEMENTS, ROUTE_COUPONS, ROUTE_DELIVERY_NOTIFICATIONS, ROUTE_EMPLOYERS, ROUTE_FOOD_PARTNERS, ROUTE_FOOD_PARTNERS_ORDER, ROUTE_NOTIFICATIONS, ROUTE_PRICES, ROUTE_PRODUCERS, ROUTE_PRODUCTS, ROUTE_PROMOTIONS, ROUTE_RESERVATIONS, ROUTE_SALES, ROUTE_SUPPLIERS, ROUTE_WAREHOUSE } from '../../../core/constants';

export const SidebarListItems = () => (
    <>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_PRODUCTS }>
                <ListItemIcon>
                    <RestaurantIcon/>
                </ListItemIcon>
                <ListItemText primary="Produkty"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_PRODUCERS }>
                <ListItemIcon>
                    <FoodBankIcon/>
                </ListItemIcon>
                <ListItemText primary="Producenci"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_FOOD_PARTNERS }>
                <ListItemIcon>
                    <LocalShippingIcon/>
                </ListItemIcon>
                <ListItemText primary="Dostawcy"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_EMPLOYERS }>
                <ListItemIcon>
                    <GroupsIcon/>
                </ListItemIcon>
                <ListItemText primary="Pracodawcy"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_SUPPLIERS }>
                <ListItemIcon>
                    <DeliveryDiningIcon/>
                </ListItemIcon>
                <ListItemText primary="Zaopatrzeniowcy"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_SALES }>
                <ListItemIcon>
                    <DiscountIcon/>
                </ListItemIcon>
                <ListItemText primary="Sprzedaż"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_PRICES }>
                <ListItemIcon>
                    <MoneyIcon/>
                </ListItemIcon>
                <ListItemText primary="Ceny"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_PROMOTIONS }>
                <ListItemIcon>
                    <CampaignIcon/>
                </ListItemIcon>
                <ListItemText primary="Promocje"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_COUPONS }>
                <ListItemIcon>
                    <CutIcon/>
                </ListItemIcon>
                <ListItemText primary="Kupony"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_FOOD_PARTNERS_ORDER }>
                <ListItemIcon>
                    <AddShoppingCartIcon/>
                </ListItemIcon>
                <ListItemText primary="Zamówienia od dostawców"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_RESERVATIONS }>
                <ListItemIcon>
                    <ShoppingCartIcon/>
                </ListItemIcon>
                <ListItemText primary="Rezerwacje"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_WAREHOUSE }>
                <ListItemIcon>
                    <WarehouseIcon/>
                </ListItemIcon>
                <ListItemText primary="Magazyn"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_NOTIFICATIONS }>
                <ListItemIcon>
                    <MessageIcon/>
                </ListItemIcon>
                <ListItemText primary="Powiadomienia"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_DELIVERY_NOTIFICATIONS }>
                <ListItemIcon>
                    <MessageIcon/>
                </ListItemIcon>
                <ListItemText primary="Powiadomienia dostaw"/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton component={ Link } to={ ROUTE_ADVERTISEMENTS }>
                <ListItemIcon>
                    <LiveTvIcon/>
                </ListItemIcon>
                <ListItemText primary="Reklamy"/>
            </ListItemButton>
        </ListItem>
    </>
);

export default SidebarListItems;
