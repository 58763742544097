import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment/moment';

import { getProductExpirationPromotionsState } from '../../../../features/price/get_product_expiration_promotions';
import { MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT } from '../../../../core/constants';
import { DeleteButton } from '../../../common/button';
import { deletePromotionNotificationData } from '../../../../core/utils/notification_utils';
import useNotification from '../../../../core/hooks/use_notification';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { getAllPodsState } from '../../../../features/pod/get_all_pods';
import { deleteProductExpirationPromotionAction, deleteProductExpirationPromotionState } from "../../../../features/price/delete_product_expiration_promotion";

const ExpirationPromotionsList = ({ refreshList }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const getProductExpirationPromotions = useSelector(getProductExpirationPromotionsState);
    const getAllPods = useSelector(getAllPodsState);
    const deleteExpirationPromotion = useSelector(deleteProductExpirationPromotionState);

    const onDeleteExpirationPromotion = (id) => {
        dispatch(deleteProductExpirationPromotionAction(id))
            .then(response => {
                showNotification(deletePromotionNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Partia</TableCell>
                        <TableCell align="center">Pod</TableCell>
                        <TableCell align="center">Data przydatności</TableCell>
                        <TableCell align="center">Ilość</TableCell>
                        <TableCell align="center">Cena bazowa</TableCell>
                        <TableCell align="center">Aktualna cena produktu</TableCell>
                        <TableCell align="center">Cena promocyjna końcowa</TableCell>
                        <TableCell align="center">Upust [%]</TableCell>
                        <TableCell align="center">Promocja od</TableCell>
                        <TableCell align="center">Promocja do</TableCell>
                        <TableCell align="center">Usuń</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getProductExpirationPromotions?.data?.content?.map((productExpirationPromotion, index) => (
                            <TableRow
                                key={ index }
                                hover
                            >
                                <TableCell>
                                    <b>{ productExpirationPromotion?.productBatchId }</b><br/>
                                    { 'nr magazynowy: ' + productExpirationPromotion?.warehouseBatchNumber }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        getAllPods?.data?.map(pod => ({ id: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + pod?.serviceId }))
                                            .find(pod => pod?.id === productExpirationPromotion?.podId)?.label
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        moment(productExpirationPromotion?.expirationDate + 'T23:59:59', MISC_DATE_ISO_FORMAT)
                                            .format(MISC_DATE_POLISH_FORMAT)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        productExpirationPromotion?.quantity
                                    }
                                </TableCell>
                                <TableCell align="center">{ productExpirationPromotion?.basicGrossPrice?.toFixed(2)}</TableCell>
                                <TableCell align="center">{ productExpirationPromotion?.currentProductGrossPrice?.toFixed(2) }</TableCell>
                                <TableCell align="center">{ productExpirationPromotion?.promotionGrossPrice?.toFixed(2) }</TableCell>
                                <TableCell align="center">{ productExpirationPromotion?.discount }</TableCell>
                                <TableCell align="center">
                                    {
                                        moment(productExpirationPromotion?.timeFrom, MISC_DATE_ISO_FORMAT)
                                            .format(MISC_DATE_POLISH_FORMAT)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        moment(productExpirationPromotion?.timeTo, MISC_DATE_ISO_FORMAT)
                                            .format(MISC_DATE_POLISH_FORMAT)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <DeleteButton
                                        onDeleteClick={ () => onDeleteExpirationPromotion(productExpirationPromotion?.expirationPromotionId) }
                                        isLoading={ deleteExpirationPromotion?.loading }/>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ExpirationPromotionsList;
