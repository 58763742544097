import { WarehouseDocumentTypeEnum } from '../../../core/enums/inventory/warehouse_document';
import { WarehouseTypeEnum } from '../../../core/enums/inventory/warehouse';

export const getWarehouseDocumentTypeOptions = [
    { value: WarehouseDocumentTypeEnum.ONLY_GRN_DOCUMENT.value, label: WarehouseDocumentTypeEnum.ONLY_GRN_DOCUMENT.label },
    { value: WarehouseDocumentTypeEnum.GRN_AND_DELIVERY_DOCUMENTS.value, label: WarehouseDocumentTypeEnum.GRN_AND_DELIVERY_DOCUMENTS.label },
    { value: WarehouseDocumentTypeEnum.ONLY_DELIVERY_DOCUMENTS.value, label: WarehouseDocumentTypeEnum.ONLY_DELIVERY_DOCUMENTS.label }
];

export const getWarehouseTypeName = (warehouseType) => {
    switch (warehouseType) {
        case WarehouseTypeEnum.MAIN.value:
            return 'Magazyn ' + WarehouseTypeEnum.MAIN.label;
        case WarehouseTypeEnum.INVENTORY.value:
            return 'Magazyn ' + WarehouseTypeEnum.INVENTORY.label;
        default:
            return WarehouseTypeEnum.UNKNOWN.label;
    }
};