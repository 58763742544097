import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Grid, Tab, Tabs } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';

import { getAutomaticExpirationPromotionSettingsAction } from '../../../features/price/get_automatic_expiration_promotion_settings';
import { getAutomaticExpirationPromotionSettingsNotificationData } from '../../../core/utils/notification_utils';
import useNotification from '../../../core/hooks/use_notification';
import AutomaticExpirationPromotionSettingList from './lists/automatic_expiration_promotion_setting_list';
import ProductPromotionsList from './lists/product_promotion_list';
import ExpirationPromotionsList from './lists/expiration_promotions_list';
import ActivePromotionCampaignList from './lists/active_promotion_campaign_list';
import ArchivedPromotionCampaignList from './lists/archived_promotion_campaign_list';

const PromotionsPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const location = useLocation();

    const [tab, setTab] = useState(location?.state?.tab || 0);

    const refreshAutomaticExpirationPromotionSettingList = () => {
        dispatch(getAutomaticExpirationPromotionSettingsAction())
            .then(response => showErrorMessage(getAutomaticExpirationPromotionSettingsNotificationData(response)));
    };

    const onTabChange = (tabValue) => {
        navigate(location.pathname, {});
        setTab(tabValue);
        if (tabValue === 4) refreshAutomaticExpirationPromotionSettingList();
    };

    const renderView = () => {
        if (tab === 0) {
            return <ProductPromotionsList tab={ tab }/>;
        }
        if (tab === 1) {
            return <ExpirationPromotionsList tab={ tab }/>;
        }
        if (tab === 2) {
            return <ActivePromotionCampaignList tab={ tab }/>;
        }
        if (tab === 3) {
            return <ArchivedPromotionCampaignList tab={ tab }/>;
        }
        if (tab === 4) {
            return (
                <AutomaticExpirationPromotionSettingList
                    refreshAutomaticExpirationPromotionSettingList={ refreshAutomaticExpirationPromotionSettingList }
                    tab={ tab }/>
            );
        }

        return <></>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 }>
                <Tabs
                    value={ tab }
                    onChange={ (_, value) => onTabChange(value) }
                    variant="scrollable"
                    scrollButtons
                    sx={ {
                        [`& .${ tabsClasses.scrollButtons }`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    } }
                >
                    <Tab label="Promocje na towar"/>
                    <Tab label="Promocje na towary z krótką datą przydatności"/>
                    <Tab label="Aktywne akcje promocyjne"/>
                    <Tab label="Archiwalne akcje promocyjne"/>
                    <Tab label="Ustawienia automatycznych promocji na towary z krótką datą przydatności"/>
                </Tabs>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default PromotionsPage;