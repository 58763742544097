import { combineReducers } from '@reduxjs/toolkit';

import getPrice from './get_price';
import updatePrices from './update_prices';
import getProductExpirationPromotion from './get_product_expiration_promotion';
import updateProductExpirationPromotion from './update_product_expiration_promotion';
import deleteProductExpirationPromotion from './delete_product_expiration_promotion';
import getProductExpirationPromotions from './get_product_expiration_promotions';
import createOrUpdateProductExpirationPromotions from './create_or_update_product_expiration_promotions';
import deleteProductExpirationPromotions from './delete_product_expiration_promotions';
import getProductPromotion from './get_product_promotion';
import updateProductPromotion from './update_product_promotion';
import deleteProductPromotion from './delete_product_promotion';
import getProductPromotions from './get_product_promotions';
import createProductPromotions from './create_product_promotions';
import deleteProductPromotions from './delete_product_promotions';
import getAutomaticExpirationPromotionSettings from './get_automatic_expiration_promotion_settings';
import updateAutomaticExpirationPromotionSettingStatus from './update_automatic_expiration_promotion_setting_status';
import getAutomaticExpirationPromotionSetting from './get_automatic_expiration_promotion_setting';
import updateAutomaticExpirationPromotionSetting from './update_automatic_expiration_promotion_setting';
import getPromotionCampaign from './get_promotion_campaign';
import getPromotionCampaigns from './get_promotion_campaigns';
import updatePromotionCampaignStatus from './update_promotion_campaign_status';
import createPromotionCampaign from './create_promotion_campaign';
import updatePromotionCampaign from './update_promotion_campaign';
import getPromotionCampaignAdvertisement from "./get_promotion_campaign_advertisement";
import updatePromotionCampaignAdvertisement from "./update_promotion_campaign_advertisement";
import deletePromotionCampaignAdvertisement from "./delete_promotion_campaign_advertisement";
import getPromotionCampaignNotification from "./get_promotion_campaign_notification";
import updatePromotionCampaignNotification from "./update_promotion_campaign_notification";
import deletePromotionCampaignNotification from "./delete_promotion_campaign_notification";
import { webClientFileDownload } from "../../core/web_client";
import { extractResponseData } from "../../core/utils/api_utils";

export const getAdvertisementFileAsBlob = async (fileUrl) => {
    const response = await webClientFileDownload.get(fileUrl);
    return window.URL.createObjectURL(new Blob([extractResponseData(response)]));
};

export const getAdvertisementFileAsRaw = async (fileUrl) => {
    const response = await webClientFileDownload.get(fileUrl);
    return extractResponseData(response);
};

export default combineReducers({
    getPrice, updatePrices,
    getProductExpirationPromotion, updateProductExpirationPromotion, deleteProductExpirationPromotion,
    getProductExpirationPromotions, createOrUpdateProductExpirationPromotions, deleteProductExpirationPromotions,
    getProductPromotion, updateProductPromotion, deleteProductPromotion,
    getProductPromotions, createProductPromotions, deleteProductPromotions, getAutomaticExpirationPromotionSettings,
    updateAutomaticExpirationPromotionSettingStatus, getAutomaticExpirationPromotionSetting, updateAutomaticExpirationPromotionSetting,
    getPromotionCampaign, getPromotionCampaigns, updatePromotionCampaignStatus, createPromotionCampaign, updatePromotionCampaign,
    getPromotionCampaignAdvertisement, updatePromotionCampaignAdvertisement, deletePromotionCampaignAdvertisement,
    getPromotionCampaignNotification, updatePromotionCampaignNotification, deletePromotionCampaignNotification
});
