import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DialogContentText, Grid } from '@mui/material';
import { Form, Formik } from 'formik';

import useNotification from '../../../../../core/hooks/use_notification';
import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import { getProductBatchesState } from '../../../../../features/inventory/get_product_batches';
import { Button, SubmitButton } from '../../../../common/button';
import { SelectFieldMultipleFormik } from '../../../../common/select_field';
import { AutocompleteMultipleFormik } from '../../../../common/autocomplete';
import { deleteProductExpirationPromotionsAction, deleteProductExpirationPromotionsState } from '../../../../../features/price/delete_product_expiration_promotions';
import { deleteProductExpirationPromotionsNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { isEmptyArray } from '../../../../../core/utils/misc_utils';

const DeletePromotionsFormDialog = ({ productId, refreshList }) => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();

    const getAllPods = useSelector(getAllPodsState);
    const getProductBatches = useSelector(getProductBatchesState);
    const deleteProductExpirationPromotions = useSelector(deleteProductExpirationPromotionsState);

    const [open, setOpen] = useState(false);

    const mapProductBatchOptions = useCallback((data) => {
        return data?.map(productBatch => ({
            key: productBatch?.id + Math.random(),
            value: productBatch?.productBatchId,
            label: `${productBatch?.productBatchId }, nr magazynowy: ${ productBatch?.warehouseBatchNumber }`,
            expiryDate: productBatch?.expiryDate
        }));
    }, []);

    const productBatchOptions = [...mapProductBatchOptions(getProductBatches?.data)];

    const initialValues = {
        podIds: [],
        productBatches: []
    };

    const schema = Yup.object().shape({
        podIds: Yup.array().when('productBatches', {
            is: (productBatches) => isEmptyArray(productBatches),
            then: Yup.array().min(1),
            otherwise: Yup.array().notRequired()
        }),
        productBatches: Yup.array().when('podIds', {
            is: (podIds) => isEmptyArray(podIds),
            then: Yup.array().min(1),
            otherwise: Yup.array().notRequired()
        })
    }, ['podIds', 'productBatches']);

    const onClose = () => setOpen(false);

    const onSubmit = (values) => {
        const form = {
            ..._.omit(values, ['productBatches']),
            productBatchIds: values.productBatches.map(productBatch => productBatch.value)
        };

        dispatch(deleteProductExpirationPromotionsAction({ productId, form }))
            .then(response => {
                showNotification(deleteProductExpirationPromotionsNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    refreshList && refreshList();
                    onClose();
                }
            });
    };

    return (
        <>
            <Button onClick={ () => setOpen(true) }>
                Usuń promocje
            </Button>
            <Dialog open={ open } onClose={ onClose } fullWidth={ true } maxWidth={ 'md' }>
                <DialogTitle>Usuń promocje po pod i/lub numerze partii</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important', display: 'flex', flexDirection: 'column', gap: 4 } }>
                    <Grid>
                        <DialogContentText>1) Tylko pody => Usuniętę zostaną wszystkie promocje produktu w podanych podach</DialogContentText>
                        <DialogContentText>2) Tylko numery partii =>  Usuniętę zostaną wszystkie promocje produktu o podanych numerach partii</DialogContentText>
                        <DialogContentText>3) Pody oraz partie => Wykona się usuwanie 1, a następnie 2 punktu</DialogContentText>
                    </Grid>
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={ schema }
                        validateOnChange
                        validateOnBlur={ false }
                        onSubmit={ onSubmit }>
                        <Grid component={ Form } container rowGap={ 4 }>
                            <Grid container item md={ 12 } direction="column" rowGap={ 4 }>
                                <Grid container item columnGap={ 4 }>
                                    <Grid item xs={ 10 }>
                                        <SelectFieldMultipleFormik
                                            name="podIds"
                                            label="Wybierz pody - wiele"
                                            options={ getAllPods?.data?.map(pod => ({ value: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + pod?.serviceId })) }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item columnGap={ 4 }>
                                    <Grid item xs={ 10 }>
                                        <AutocompleteMultipleFormik
                                            options={ productBatchOptions }
                                            name="productBatches"
                                            label="Wybierz partie produktu - wiele"
                                            groupBy={ option => option.expiryDate }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item mt={ 2 } justifyContent="flex-end">
                                    <DialogActions sx={ { gap: 2 } }>
                                        <Button onClick={ onClose }>Zamknij</Button>
                                        <SubmitButton isLoading={ deleteProductExpirationPromotions?.loading }>Zapisz</SubmitButton>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DeletePromotionsFormDialog;
