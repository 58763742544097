import React from 'react';

import { Grid } from '@mui/material';

import { Button } from '../../../common/button';
import ConfirmDialog from '../../common/confirm_dialog';

const SummaryButtons = ({ onSave, onSaveAndSend, displaySaveAndSendAction, withoutGenerateLabels, onReturn, isLoading }) => (
    <Grid container item mt={ 2 } justifyContent="space-between">
        <Grid item>
            <Button onClick={ () => onReturn() }>Wróć</Button>
        </Grid>
        <Grid item>
            <Grid container item gap={ 2 }>
                {
                    withoutGenerateLabels
                        ? <Button isLoading={ isLoading } onClick={ onSave }>Zapisz</Button>
                        : <ConfirmDialog
                            isLoading={ isLoading }
                            title={ 'Wygenerować paczkę naklejek?' }
                            secondaryAction={ () => onSave(false) }
                            secondaryText={ 'Pomiń' }
                            primaryAction={ () => onSave(true) }
                            primaryText={ 'Wygeneruj' }
                            displayIcon={ false }
                            buttonText={ 'Zapisz' }/>
                }
                {
                    displaySaveAndSendAction &&
                    <ConfirmDialog
                        isLoading={ isLoading }
                        title={ 'Wygenerować i wysłać paczkę naklejek do dostawcy?' }
                        secondaryAction={ () => onSaveAndSend(false) }
                        secondaryText={ 'Wyślij bez naklejek' }
                        primaryAction={ () => onSaveAndSend(true) }
                        primaryText={ 'Wygeneruj i wyślij' }
                        displayIcon={ false }
                        buttonText={ 'Zapisz i wyślij' }/>
                }
            </Grid>
        </Grid>
    </Grid>
);

export default SummaryButtons;
