import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient, { webClientFileDownload } from '../../core/web_client';
import { exportFile, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import { WAREHOUSE_DOCUMENTS_PATH } from '../../core/constants';
import { WarehouseDocumentTypeEnum } from '../../core/enums/inventory/warehouse_document';

export const createWarehouseDocumentAction = createAsyncThunk(
    'inventory/createWarehouseDocumentAction',
    async (form, { fulfillWithValue, rejectWithValue }) => {
        try {
            if (form.generateLabels && (form.type === WarehouseDocumentTypeEnum.ONLY_GRN_DOCUMENT.value || form.type === WarehouseDocumentTypeEnum.GRN_AND_DELIVERY_DOCUMENTS.value)) {
                const response = await webClientFileDownload.post(`${ WAREHOUSE_DOCUMENTS_PATH }/generate`, form);
                if (response?.status === 200) {
                    const fileName = response.headers['content-disposition']?.split('filename=')[1]?.replaceAll("\"", "");
                    exportFile(extractResponseData(response), fileName ? fileName : 'naklejki.zip');
                    return fulfillWithValue();
                } else {
                    return fulfillWithValue("generateLabelsFailed");
                }
            } else {
                const response = await webClient.post(`${ WAREHOUSE_DOCUMENTS_PATH }/generate`, form);
                return fulfillWithValue(extractResponseData(response));
            }
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const createWarehouseDocumentSlice = createSlice({
    name: 'createWarehouseDocumentSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(createWarehouseDocumentAction.pending, () => STATE__API__STARTED);
        builder.addCase(createWarehouseDocumentAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(createWarehouseDocumentAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const createWarehouseDocumentState = (state) => state.inventory.createWarehouseDocument;
export default createWarehouseDocumentSlice.reducer;
