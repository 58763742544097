import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment';
import _ from 'lodash';

import AddProductsDialog from '../../../common/warehouse_and_food_partner_order_form/add_products_dialog';
import useNotification from '../../../../../core/hooks/use_notification';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_ISO_FORMAT } from '../../../../../core/constants';
import { createWarehouseDocumentNotificationData, getFoodPartnerOrderNotificationData, updateFoodPartnerOrderNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import ProductsList from '../common/products_list';
import { getFoodPartnerOrderAction, getFoodPartnerOrderState } from '../../../../../features/inventory/get_food_partner_order';
import { FormRouteEnum } from '../../../../../core/enums/common/route';
import { FoodPartnerOrderStatusEnum } from '../../../../../core/enums/inventory/food_partner_order';
import { SelectFieldFormik } from '../../../../common/select_field';
import { DateTimePickerFormik } from '../../../../common/date_picker';
import RadioGroupFormik from '../../../../common/radio_group';
import { yesNoOptions } from '../../../../common/form_utils';
import UpdateStatusButtons from '../common/update_status_buttons';
import Buttons from './buttons';
import { createWarehouseDocumentAction, createWarehouseDocumentState } from '../../../../../features/inventory/create_warehouse_document';
import { updateFoodPartnerOrderAction, updateFoodPartnerOrderState } from '../../../../../features/inventory/update_food_partner_order';
import { WarehouseDocumentTypeEnum } from '../../../../../core/enums/inventory/warehouse_document';
import AdditionalOptions from '../common/additional_options';
import { updateAndSendFoodPartnerOrderAction, updateAndSendFoodPartnerOrderState } from '../../../../../features/inventory/update_and_send_food_partner_order';
import { isEmptyObject } from '../../../../../core/utils/misc_utils';
import UploadFiles from '../../../common/warehouse_and_food_partner_order_form/upload_files';

const FormWithoutLocationDivision = ({ action, setAction }) => {
    const dispatch = useDispatch();
    const { showErrorMessage, showNotification } = useNotification();

    const getFoodPartnerOrder = useSelector(getFoodPartnerOrderState);
    const updateFoodPartnerOrder = useSelector(updateFoodPartnerOrderState);
    const updateAndSendFoodPartnerOrder = useSelector(updateAndSendFoodPartnerOrderState);
    const createWarehouseDocument = useSelector(createWarehouseDocumentState);

    const disableForm = action === FormRouteEnum.VIEW;
    const foodPartnerOrderStatus = getFoodPartnerOrder?.data?.status;

    const initialValues = {
        foodPartnerId: getFoodPartnerOrder?.data?.foodPartnerId || '',
        locationDivision: false,
        orderDate: moment(getFoodPartnerOrder?.data?.orderDate) || '',
        productsList: [getFoodPartnerOrder?.data?.positions?.map((position, index) => ({
            position: index,
            name: position?.productName,
            productId: position?.productId,
            quantity: position?.quantity,
            expirationDate: moment(position?.expirationDate) || '',
            price: position?.purchaseNetPrice || ''
        }))] || [],
        attachments: getFoodPartnerOrder?.data?.attachments || [],
        attachmentIdsToDelete: []
    };

    const onChangeSetDate = (newDate, allDates, formik) => {
        const productsList = formik?.values?.productsList[0]?.map(item => {
            if (!allDates && item?.expirationDate) {
                return item;
            }

            return ({ ...item, expirationDate: newDate });
        });

        formik.setFieldValue(`productsList.${ 0 }`, productsList);
    };

    const validateOnSubmit = (formik) => {
        const errors = validate(formik.values);

        if (errors.productsList.length ||
            errors.products.length ||
            !isEmptyObject(errors.destinationsList)) {
            formik.setErrors(errors);
        }
    };

    const onUpdate = (formik, sendEmail, generateLabels) => {
        const locationProducts = [{
            products: [
                ...formik?.values?.productsList?.[0]?.map(item => ({
                    productId: item?.productId,
                    quantity: item?.quantity,
                    price: item?.price,
                    expirationDate: item?.expirationDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
                }))
            ]
        }];
        const form = {
            locationProducts,
            generateLabels,
            orderDate: formik?.values?.orderDate?.format(MISC_DATE_ISO_FORMAT),
            attachmentIdsToDelete: formik?.values?.attachmentIdsToDelete
        };
        const attachments = formik?.values?.attachments || [];
        const dispatchAction = sendEmail ? updateAndSendFoodPartnerOrderAction : updateFoodPartnerOrderAction;

        dispatch(dispatchAction({ id: getFoodPartnerOrder?.data?.id, form, attachments }))
            .then(response => {
                showNotification(updateFoodPartnerOrderNotificationData(response));

                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    dispatch(getFoodPartnerOrderAction(getFoodPartnerOrder?.data?.id))
                        .then(response => showErrorMessage(getFoodPartnerOrderNotificationData(response)));
                }
            });
    };

    const onGenerateWarehouseDocument = (formik, generateLabels) => {
        validateOnSubmit(formik);

        const documents = [{
            documentProducts: [
                ...formik?.values?.productsList?.[0]?.map(item => ({
                    productId: item?.productId,
                    quantity: item?.quantity,
                    expirationDate: item?.expirationDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
                    price: item?.price
                }))
            ]
        }];

        const form = {
            ..._.omit(formik?.values, [
                'orderDate',
                'productsList',
                'locationDivision'
            ]),
            documents,
            generateLabels,
            deliveryDate: formik?.values?.orderDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
            type: WarehouseDocumentTypeEnum.ONLY_GRN_DOCUMENT.value,
            foodPartnerOrderId: getFoodPartnerOrder?.data?.id
        };

        dispatch(createWarehouseDocumentAction(form))
            .then(response => {
                showNotification(createWarehouseDocumentNotificationData(response));

                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    dispatch(getFoodPartnerOrderAction(getFoodPartnerOrder?.data?.id))
                        .then(response => {
                            showErrorMessage(getFoodPartnerOrderNotificationData(response));
                            response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && setAction(FormRouteEnum.VIEW);
                        });
                }
            });
    };

    const validate = (values) => {
        const errors = {
            productsList: [],
            products: []
        };

        if (values.productsList.length === 0) {
            errors.productsList[0] = 'Wybierz minimum 1 produkt';
        } else {
            values.productsList.forEach((products, index) => {
                if (products.length === 0) {
                    errors.productsList[index] = 'Wybierz minimum 1 produkt';
                } else {
                    products.forEach((item, itemIndex) => {
                        if (!item?.quantity) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [itemIndex]: {
                                    ...errors.products?.[index]?.[itemIndex],
                                    quantity: 'Pole wymagane'
                                }
                            };
                        } else {
                            if (item?.quantity <= 0) {
                                const oldValues = errors.products[index];
                                errors.products[index] = {
                                    ...oldValues,
                                    [itemIndex]: {
                                        ...errors.products?.[index]?.[itemIndex],
                                        quantity: 'Proszę podać wartość całkowitą dodatnią'
                                    }
                                };
                            }
                        }

                        if (foodPartnerOrderStatus === FoodPartnerOrderStatusEnum.DELIVERED.value && !item?.expirationDate) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [itemIndex]: {
                                    ...errors.products?.[index]?.[itemIndex],
                                    expirationDate: 'Pole wymagane'
                                }
                            };
                        }

                        if (!item?.price) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [itemIndex]: {
                                    ...errors.products?.[index]?.[itemIndex],
                                    price: 'Pole wymagane'
                                }
                            };
                        } else {
                            if (item?.price <= 0) {
                                const oldValues = errors.products[index];
                                errors.products[index] = {
                                    ...oldValues,
                                    [itemIndex]: {
                                        ...errors.products?.[index]?.[itemIndex],
                                        price: 'Proszę podać wartość dodatnią'
                                    }
                                };
                            }
                        }

                        if (!item?.expirationDate) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [itemIndex]: {
                                    ...errors.products?.[index]?.[itemIndex],
                                    expirationDate: 'Pole wymagane'
                                }
                            };
                        }
                    });
                }
            });
        }

        return errors;
    };

    return (
        <Formik initialValues={ initialValues }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 2 } paddingTop={ 2 }>
                    <UpdateStatusButtons
                        foodPartnerOrderStatus={ foodPartnerOrderStatus }
                        foodPartnerOrderId={ getFoodPartnerOrder?.data?.id }/>
                    {
                        !disableForm &&
                        <Grid container item mb={ 2 }>
                            <Divider style={ { width: '100%' } }/>
                        </Grid>
                    }
                    <Grid container item rowGap={ 2 } mb={ 2 }>
                        <Grid container item gap={ 6 }>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="foodPartnerId"
                                    label="Dostawca"
                                    options={ [{ value: getFoodPartnerOrder?.data?.foodPartnerId, label: getFoodPartnerOrder?.data?.foodPartnerName }] }
                                    required
                                    disabled
                                />
                            </Grid>
                            <Grid item>
                                <RadioGroupFormik
                                    name="locationDivision"
                                    label="Podział na lokalizacje"
                                    options={ yesNoOptions }
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item>
                                <DateTimePickerFormik
                                    name="orderDate"
                                    label="Data zamówienia"
                                    required
                                    InputLabelProps={ { shrink: true } }
                                    disabled={ ![FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value].includes(foodPartnerOrderStatus) }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        [FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value].includes(foodPartnerOrderStatus) &&
                        <AdditionalOptions
                            onChangeSetDate={ (newDate, allDates) => onChangeSetDate(newDate, allDates, formik) }/>
                    }
                    <Grid container item mb={ 2 }>
                        <Divider style={ { width: '100%' } }/>
                    </Grid>
                    <Card sx={ { width: '100%', backgroundColor: '#f5f5f5' } }>
                        <CardContent>
                            <Typography variant="h4" mb={ 4 }>
                                Lista produktów
                            </Typography>
                            <ProductsList
                                formik={ formik }
                                productsListIndex={ 0 }
                                withExpirationDate
                                disabled={ disableForm || foodPartnerOrderStatus === FoodPartnerOrderStatusEnum.DELIVERED.value }/>
                            {
                                [FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value].includes(foodPartnerOrderStatus) &&
                                <AddProductsDialog
                                    foodPartnerId={ formik?.values?.foodPartnerId }
                                    formik={ formik }
                                    dateToCalculateExpirationDate={ formik?.values?.orderDate }
                                    productsListIndex={ 0 }
                                />
                            }
                        </CardContent>
                    </Card>
                    <UploadFiles
                        formik={ formik }
                        downloadOption
                        addAndRemoveOptions={ [FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value].includes(foodPartnerOrderStatus) }
                    />
                    <Buttons
                        onSave={ (sendEmail, generateLabels) => onUpdate(formik, sendEmail, generateLabels) }
                        displaySaveAction={ [FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value].includes(foodPartnerOrderStatus) }
                        displaySaveAndSendAction={ [FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value].includes(foodPartnerOrderStatus) }
                        mailAlreadySent={ getFoodPartnerOrder?.data?.emailStatus !== 'UNSENT' }
                        onGenerate={ (generateLabels) => onGenerateWarehouseDocument(formik, generateLabels) }
                        displayGenerateWarehouseDocumentAction={ foodPartnerOrderStatus === FoodPartnerOrderStatusEnum.DELIVERED.value }
                        isLoading={ updateFoodPartnerOrder?.loading || updateAndSendFoodPartnerOrder?.loading || createWarehouseDocument?.loading }
                    />
                </Grid>
            }
        </Formik>
    );
};

export default FormWithoutLocationDivision;
