import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Grid, LinearProgress, Tab, Tabs, TextField } from '@mui/material';
import deepmerge from 'deepmerge';
import { tabsClasses } from '@mui/material/Tabs';
import _ from 'lodash';

import { getNotificationsNotificationData, getPodsNotificationData, } from '../../../core/utils/notification_utils';
import useNotification from '../../../core/hooks/use_notification';
import { Button } from '../../common/button';
import { ROUTE_NOTIFICATIONS_NEW } from '../../../core/constants';
import { getNotificationsAction, getNotificationsState } from '../../../features/communication/get_notifications';
import PaginationControls from '../../common/pagination_controls';
import { getDefaultPaginationQueryValues } from '../../../core/utils/api_utils';
import NotificationsList from './notifications_list';
import { getAllPodsAction, getAllPodsState } from '../../../features/pod/get_all_pods';
import { NotificationRequestProcessStatusEnum } from '../../../core/enums/communication/notification_request';
import SelectField from '../../common/select_field';
import { ApiRequestStatusEnum } from '../../../core/enums/common/api';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    sort: ['requestPlaced', 'desc'],
    processStatus: NotificationRequestProcessStatusEnum.PLANNED,
    podId: '',
    query: ''
};

const tabOptions = [
    { value: NotificationRequestProcessStatusEnum.PLANNED, label: 'Zaplanowane' },
    { value: NotificationRequestProcessStatusEnum.FINISHED, label: 'Wysłane' },
    { value: NotificationRequestProcessStatusEnum.CANCELED, label: 'Anulowane' },
];

const NotificationsPage = () => {
    const { showErrorMessage } = useNotification();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const getNotifications = useSelector(getNotificationsState);
    const getAllPods = useSelector(getAllPodsState);

    const [tab, setTab] = useState(location?.state?.tab || 0);
    const [queryParams, setQueryParams] = useState(location?.state?.queryParams || initialQueryParams);
    const [podOptions, setPodOptions] = useState([]);
    const [podOption, setPodOption] = useState('');

    const refreshList = useCallback(() => {
        const params = _.clone(queryParams);
        Object.keys(params).forEach(function (key, _) {
            if (key === 'podId' && params[key] === 'none')
                params[key] = '';
        });

        dispatch(getNotificationsAction(params))
            .then(response => showErrorMessage(getNotificationsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [dispatch, refreshList, showErrorMessage]);

    useEffect(() => {
        dispatch(getAllPodsAction())
            .then(response => {
                showErrorMessage(getPodsNotificationData(response));

                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    setPodOptions([{ value: 'none', label: 'Nie wybrano' }, ...response?.payload?.map(pod => ({ value: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + pod?.serviceId }))]);
                    setPodOption(location?.state?.queryParams?.podId || 'none');
                }
            });
    }, [dispatch, location?.state?.queryParams?.podId, showErrorMessage]);

    const onChangePod = (event) => {
        setPodOption(event.target.value);
        setQueryParams(prevState => deepmerge(prevState, { podId: event.target.value }));
    };

    const onSearchInputChangeDebounced = _.debounce((value) => setQueryParams(prevState => deepmerge(prevState, { query: value })), 600);

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } rowGap={ 4 } justifyContent="space-between">
                <Grid container item>
                    <Tabs
                        value={ tab }
                        onChange={ (_, value) => {
                            setTab(value);
                            setQueryParams(prevState => deepmerge(prevState,
                                {
                                    processStatus: value === 0
                                        ? tabOptions[0].value
                                        : value === 1
                                            ? tabOptions[1].value
                                            : tabOptions[2].value
                                }
                            ));
                        } }
                        variant="scrollable"
                        scrollButtons
                        sx={ {
                            [`& .${ tabsClasses.scrollButtons }`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        } }
                    >
                        {
                            tabOptions?.map(tabOption => <Tab key={ tabOption.value } label={ tabOption.label }/>)
                        }
                    </Tabs>
                </Grid>
                <Grid container item alignItems="center" columnGap={ 2 }>
                    <Grid item xs={ 6 }>
                        <SelectField
                            label={ 'Wybierz poda' }
                            value={ podOption }
                            onChange={ onChangePod }
                            options={ podOptions }
                        />
                    </Grid>
                    <Grid item xs={ 4 }>
                        <TextField
                            label="Szukaj po tytule lub treści"
                            fullWidth
                            onChange={ event => onSearchInputChangeDebounced(event.target.value) }
                            placeholder={ queryParams?.query }
                            InputLabelProps={ queryParams?.query ? { shrink: true } : undefined }
                        />
                    </Grid>
                </Grid>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_NOTIFICATIONS_NEW, { state: { queryParams, tab } }) }>
                        Wyślij powiadomienie
                    </Button>
                </Grid>
            </Grid>
            {
                (getNotifications?.loading || getAllPods?.loading) &&
                <LinearProgress/>
            }
            {
                (getNotifications?.data && getAllPods?.data) &&
                <Grid container>
                    <NotificationsList
                        refreshList={ refreshList }
                        showCancelButton={ tab === 0 }/>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getNotifications?.data?.pageable?.pageNumber }
                            totalPages={ getNotifications?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default NotificationsPage;
