import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import { Grid, TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import _ from 'lodash';

import { SelectFieldMultipleFormik } from '../../../../common/select_field';
import { AutocompleteMultipleFormik } from '../../../../common/autocomplete';
import { getProductBatchesState } from '../../../../../features/inventory/get_product_batches';
import useNotification from '../../../../../core/hooks/use_notification';
import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import { Button, SubmitButton } from '../../../../common/button';
import TextFieldFormik from '../../../../common/text_field';
import { createOrUpdateProductExpirationPromotionsAction, createOrUpdateProductExpirationPromotionsState } from '../../../../../features/price/create_or_update_product_expiration_promotions';
import { updatePromotionNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { getPriceState } from '../../../../../features/price/get_price';
import { parseDateString } from '../../../../../core/utils/date_utils';
import { DateTimePickerFormik } from '../../../../common/date_picker';
import { MISC_DATE_ISO_FORMAT } from '../../../../../core/constants';

const PromotionsFormDialog = ({ productId, refreshList }) => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();

    const getAllPods = useSelector(getAllPodsState);
    const getPrice = useSelector(getPriceState);
    const getProductBatches = useSelector(getProductBatchesState);
    const createOrUpdateExpirationPromotions = useSelector(createOrUpdateProductExpirationPromotionsState);

    const [open, setOpen] = useState(false);
    const [startPrice, setStartPrice] = useState(0.00);
    const [discountPrice, setDiscountPrice] = useState(0.00);

    const mapProductBatchOptions = useCallback((data) => {
        return data?.map(productBatch => ({
            key: productBatch?.id + Math.random(),
            value: productBatch?.productBatchId,
            label: `${productBatch?.productBatchId }, nr magazynowy: ${ productBatch?.warehouseBatchNumber }, cena zakupu: ${ productBatch?.purchaseGrossPrice?.toFixed(2) }`,
            expiryDate: productBatch?.expiryDate
        }));
    }, []);

    const productBatchOptions = [...mapProductBatchOptions(getProductBatches?.data)];

    const calculateStartingPrice = (podIds, discount) => {
        const minPrice = Math.min(...podIds?.map(podId => [getPrice?.data?.find(item => item.podId === podId)].map(item => item.promotionGrossPrice?.toFixed(2) || item.basicGrossPrice?.toFixed(2))[0]) || 0.00);
        setStartPrice(minPrice);
        discountPrice !== 0.00 && setDiscountPrice((parseFloat(minPrice).toFixed(2) - parseFloat((discount / 100 * minPrice).toFixed(2))).toFixed(2));
    };

    const initialValues = {
        podIds: [],
        productBatches: [],
        timeFrom: '',
        timeTo: '',
        discount: ''
    };

    const schema = Yup.object().shape({
        podIds: Yup.array().min(1),
        productBatches: Yup.array().min(1),
        timeFrom: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required(),
        timeTo: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required(),
        discount: Yup.number().required()
    });

    const onClose = () => setOpen(false);

    const onSubmit = (values) => {
        const timeFrom = values?.timeFrom?.format(MISC_DATE_ISO_FORMAT);
        const timeTo = values?.timeTo?.format(MISC_DATE_ISO_FORMAT);

        const form = {
            ..._.omit(values, ['productBatches', 'timeFrom', 'timeTo']),
            productBatchIds: values.productBatches.map(productBatch => productBatch.value),
            timeFrom,
            timeTo
        };

        dispatch(createOrUpdateProductExpirationPromotionsAction({ productId, form }))
            .then(response => {
                showNotification(updatePromotionNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    refreshList && refreshList();
                    onClose();
                }
            });
    };

    return (
        <>
            <Button onClick={ () => setOpen(true) }>
                Dodaj promocje
            </Button>
            <Dialog open={ open } onClose={ onClose } fullWidth={ true } maxWidth={ 'md' }>
                <DialogTitle>Promocje na krótką datę przydatności</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={ schema }
                        validateOnChange={ false }
                        validateOnBlur={ false }
                        onSubmit={ onSubmit }>
                        { (formik) =>
                            <Grid component={ Form } container rowGap={ 4 }>
                                <Grid container item direction="column" rowGap={ 4 }>
                                    <Grid container item>
                                        <Grid item xs={ 10 }>
                                            <SelectFieldMultipleFormik
                                                name="podIds"
                                                label="Wybierz pody - wiele"
                                                options={ getAllPods?.data?.map(pod => (
                                                    {
                                                        value: pod?.id,
                                                        label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + pod?.serviceId + ' aktualna cena: '
                                                            + ([getPrice?.data?.find(item => item.podId === pod?.id)].map(item => item.promotionGrossPrice?.toFixed(2) || item.basicGrossPrice?.toFixed(2))[0] || '-')
                                                    }
                                                )) }
                                                required
                                                onChange={ (value) => calculateStartingPrice(value, formik.values.discount) }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item>
                                        <Grid item xs={ 10 }>
                                            <AutocompleteMultipleFormik
                                                options={ productBatchOptions }
                                                name="productBatches"
                                                label="Wybierz partie produktu - wiele"
                                                required
                                                groupBy={ option => option.expiryDate }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item columnGap={ 4 }>
                                        <Grid item xs={ 2 }>
                                            <TextField
                                                label="Aktualna cena produktu"
                                                type="number"
                                                disabled
                                                value={ startPrice?.toFixed(2) }
                                            />
                                        </Grid>
                                        <Grid item xs={ 4 }>
                                            <TextFieldFormik
                                                name="discount"
                                                label="Upust [%]"
                                                type="number"
                                                required
                                                inputProps={ { min: '0.01', max: '100.00', step: '0.01' } }
                                                onChange={ (value) => setDiscountPrice((parseFloat(startPrice).toFixed(2) - parseFloat((value / 100 * startPrice).toFixed(2))).toFixed(2)) }
                                            />
                                        </Grid>
                                        <Grid item xs={ 2 }>
                                            <TextField
                                                label="Cena promocyjna"
                                                type="number"
                                                disabled
                                                value={ discountPrice }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item gap={ 4 }>
                                        <Grid item>
                                            <DateTimePickerFormik
                                                name="timeFrom"
                                                label="Data od"
                                                required
                                                InputLabelProps={ { shrink: true } }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <DateTimePickerFormik
                                                name="timeTo"
                                                label="Data do"
                                                required
                                                InputLabelProps={ { shrink: true } }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item mt={ 2 } justifyContent="flex-end">
                                        <DialogActions sx={ { gap: 2 } }>
                                            <Button onClick={ onClose }>Zamknij</Button>
                                            <SubmitButton isLoading={ createOrUpdateExpirationPromotions?.loading }>Zapisz</SubmitButton>
                                        </DialogActions>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PromotionsFormDialog;
