import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient, { webClientFileDownload } from '../../core/web_client';
import { exportFile, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import { FOOD_PARTNER_ORDER_PATH, MISC_REST_CONTENT_TYPE_APPLICATION_JSON, MISC_REST_CONTENT_TYPE_MULTIPART } from '../../core/constants';

export const createFoodPartnerOrderAction = createAsyncThunk(
    'inventory/createFoodPartnerOrderAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            let body = new FormData();
            const formJson = JSON.stringify(data.form);
            const formBlob = new Blob([formJson], {
                type: MISC_REST_CONTENT_TYPE_APPLICATION_JSON
            });

            body.append('form', formBlob);

            for (const attachment of data?.attachments) {
                body.append('attachments', attachment);
            }

            if (data.form.generateLabels) {
                const response = await webClientFileDownload.post(`${ FOOD_PARTNER_ORDER_PATH }/save`, body, {
                    headers: {
                        'content-type': MISC_REST_CONTENT_TYPE_MULTIPART
                    }
                });
                if (response?.status === 200) {
                    const fileName = response.headers['content-disposition']?.split('filename=')[1]?.replaceAll("\"", "");
                    exportFile(extractResponseData(response), fileName ? fileName : 'naklejki.zip');
                    return fulfillWithValue();
                } else {
                    return fulfillWithValue("generateLabelsFailed");
                }
            } else {
                const response = await webClient.post(`${ FOOD_PARTNER_ORDER_PATH }/save`, body, {
                    headers: {
                        'content-type': MISC_REST_CONTENT_TYPE_MULTIPART
                    }
                });
                return fulfillWithValue(extractResponseData(response));
            }
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const createFoodPartnerOrderSlice = createSlice({
    name: 'createFoodPartnerOrderSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(createFoodPartnerOrderAction.pending, () => STATE__API__STARTED);
        builder.addCase(createFoodPartnerOrderAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(createFoodPartnerOrderAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const createFoodPartnerOrderState = (state) => state.inventory.createFoodPartnerOrder;
export default createFoodPartnerOrderSlice.reducer;
