import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import deepmerge from 'deepmerge';

import useNotification from '../../../../core/hooks/use_notification';
import { getProductsForPromotionAction, getProductsForPromotionState } from '../../../../features/product/get_products_for_promotion';
import { getProductsForPromotionNotificationData } from '../../../../core/utils/notification_utils';
import ProductFilters from './product_filters';
import PaginationControls from '../../../common/pagination_controls';
import { GET_ROUTE_EXPIRATION_PROMOTIONS } from '../../../../core/constants';
import { getDefaultPaginationQueryValues } from '../../../../core/utils/api_utils';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    query: '',
};

const ExpirationPromotionsList = ({ tab }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();
    const location = useLocation();

    const getProductsForPromotion = useSelector(getProductsForPromotionState);

    const [queryParams, setQueryParams] = useState(location?.state?.queryParams || initialQueryParams);

    useEffect(() => {
        dispatch(getProductsForPromotionAction(queryParams))
            .then(response => showErrorMessage(getProductsForPromotionNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    return (
        <Grid>
            <Box mb={ 4 }>
                <ProductFilters
                    queryParams={ queryParams }
                    setQueryParams={ setQueryParams }
                />
            </Box>
            {
                getProductsForPromotion?.loading &&
                <LinearProgress/>
            }
            {
                getProductsForPromotion?.data?.content &&
                <Box>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Nazwa
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getProductsForPromotion?.data?.content?.map(product => (
                                        <TableRow
                                            key={ product?.id }
                                            hover
                                            sx={ { cursor: 'pointer' } }
                                            onClick={ () => navigate(GET_ROUTE_EXPIRATION_PROMOTIONS(product?.id), { state: { queryParams, tab } }) }
                                        >
                                            <TableCell>{ product?.name }</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getProductsForPromotion?.data?.pageable?.pageNumber }
                            totalPages={ getProductsForPromotion?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Box>
            }
        </Grid>
    );
};

export default ExpirationPromotionsList;
