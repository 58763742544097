import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';

import useNotification from '../../../../../core/hooks/use_notification';
import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import { Button, SubmitButton } from '../../../../common/button';
import { SelectFieldMultipleFormik } from '../../../../common/select_field';
import { deleteProductPromotionsNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { deleteProductPromotionsAction, deleteProductPromotionsState } from "../../../../../features/price/delete_product_promotions";

const DeletePromotionsFormDialog = ({ productId, refreshList }) => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();

    const getAllPods = useSelector(getAllPodsState);
    const deleteProductPromotions = useSelector(deleteProductPromotionsState);

    const [open, setOpen] = useState(false);

    const initialValues = {
        podIds: [],
    };

    const schema = Yup.object().shape({
        podIds: Yup.array().min(1)
    }, ['podIds']);

    const onClose = () => setOpen(false);

    const onSubmit = (values) => {
        dispatch(deleteProductPromotionsAction({ productId, form: values }))
            .then(response => {
                showNotification(deleteProductPromotionsNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    refreshList && refreshList();
                    onClose();
                }
            });
    };

    return (
        <>
            <Button onClick={ () => setOpen(true) }>
                Usuń promocje
            </Button>
            <Dialog open={ open } onClose={ onClose } fullWidth={ true } maxWidth={ 'lg' }>
                <DialogTitle>Usuń promocje we wskazanych pod</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important', display: 'flex', flexDirection: 'column', gap: 4 } }>
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={ schema }
                        validateOnChange
                        validateOnBlur={ false }
                        onSubmit={ onSubmit }>
                        <Grid component={ Form } container rowGap={ 4 }>
                            <Grid container item md={ 12 } direction="column" rowGap={ 4 }>
                                <Grid container item columnGap={ 4 }>
                                    <Grid item xs={ 6 }>
                                        <SelectFieldMultipleFormik
                                            name="podIds"
                                            label="Wybierz pody - wiele"
                                            options={ getAllPods?.data?.map(pod => ({ value: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + pod?.serviceId })) }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item mt={ 2 } justifyContent="flex-end">
                                    <DialogActions sx={ { gap: 2 } }>
                                        <Button onClick={ onClose }>Zamknij</Button>
                                        <SubmitButton isLoading={ deleteProductPromotions?.loading }>Zapisz</SubmitButton>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DeletePromotionsFormDialog;
