import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import moment from 'moment';
import _ from 'lodash';

import useNotification from '../../../../../../core/hooks/use_notification';
import { Button, SubmitButton } from '../../../../../common/button';
import TextFieldFormik from '../../../../../common/text_field';
import { getFoodPartnersForReservationState } from '../../../../../../features/food_partner/get_food_partners_for_reservation';
import AutocompleteMultipleSearchOnType, { AutocompleteFormik } from '../../../../../common/autocomplete';
import { getProductsForReservationAction } from '../../../../../../features/product/get_products_for_reservation';
import { createProductAvailabilityNotificationData, getProductsForReservationNotificationData } from '../../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../../core/enums/common/api';
import { createProductAvailabilityAction, createProductAvailabilityState } from '../../../../../../features/reservation/create_product_availability';
import { parseDateString } from '../../../../../../core/utils/date_utils';
import DatePickerFormik from '../../../../../common/date_picker';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from '../../../../../../core/constants';

const ProductAvailabilityForm = ({ form, onClose, setForm, setView, refreshCalendar }) => {
    const dispatch = useDispatch();
    const { showNotification, showErrorMessage } = useNotification();

    const getFoodPartnersForReservation = useSelector(getFoodPartnersForReservationState);
    const createProductAvailability = useSelector(createProductAvailabilityState);

    const [productOptions, setProductOptions] = useState([]);
    const [loadingProductsOptions, setLoadingProductsOptions] = useState(false);
    const [chosenProducts, setChosenProducts] = useState([]);
    const [foodPartner, setFoodPartner] = useState();

    const onProductsInputChange = (product) => {
        if (product.length >= 3) {
            setProductOptions([]);
            setLoadingProductsOptions(true);

            getProductsDebounced(product);
        }
    };

    const getProductsDebounced = _.debounce((query) => {
        dispatch(getProductsForReservationAction({ foodPartnerId: foodPartner?.value, query }))
            .then(response => {
                showErrorMessage(getProductsForReservationNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    const newProductOptions = mapProductOptions(response?.payload).concat(chosenProducts);
                    const newDistinctProductOptions = [...new Map(newProductOptions.map((option) => [option['value'], option])).values()];
                    setProductOptions(newDistinctProductOptions);
                }
                setLoadingProductsOptions(false);
            });
    }, 600);

    const mapFoodPartnerOptions = useCallback((data) => {
        return data?.map(foodPartner => ({
            key: foodPartner?.id + Math.random(),
            value: foodPartner?.id,
            label: foodPartner?.name
        }));
    }, []);

    const mapProductOptions = useCallback((data) => {
        return data?.map(product => ({
            key: product?.id + Math.random(),
            value: product?.id,
            label: product?.name
        }));
    }, []);

    const initialValues = {
        foodPartner: form?.foodPartner || '',
        fromDate: form?.fromDate || '',
        toDate: form?.toDate || '',
        generalQuantityLimit: form?.generalQuantityLimit || ''
    };

    const schema = Yup.object().shape({
        foodPartner: Yup.object().required(),
        fromDate: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required(),
        toDate: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required(),
        generalQuantityLimit: Yup.number().notRequired()
    });

    const onFoodPartnerChange = (foodPartner, formik) => {
        setFoodPartner(foodPartner);
        formik.setFieldValue('productIds', '');
    };

    const onSubmit = (values) => {
        const productIds = chosenProducts.map(product => product.value);
        const foodPartnerId = _.pick(values, ['foodPartner'])?.foodPartner?.value || '';
        const fromDate = values?.fromDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT);
        const toDate = values?.toDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT);

        const form = {
            ..._.omit(values, ['foodPartner', 'fromDate', 'toDate']),
            productIds,
            foodPartnerId,
            fromDate,
            toDate
        };

        setForm(form);

        if (values?.generalQuantityLimit && values?.generalQuantityLimit > 0) {
            setView(1);
        } else {
            dispatch(createProductAvailabilityAction(form))
                .then(response => {
                    showNotification(createProductAvailabilityNotificationData(response));
                    if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                        onClose();
                        refreshCalendar && refreshCalendar();
                    }
                });
        }
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmit }>
            { (formik) =>
                <Grid component={ Form } container item rowGap={ 2 } alignContent="space-between">
                    <Grid container item rowGap={ 2 }>
                        <Grid container item gap={ 2 }>
                            <Grid item xs={ 4 }>
                                <AutocompleteFormik
                                    name="foodPartner"
                                    label="Dostawca"
                                    options={ mapFoodPartnerOptions(getFoodPartnersForReservation?.data) }
                                    required
                                    onChange={ (value) => onFoodPartnerChange(value, formik) }
                                />
                            </Grid>
                            {
                                formik.values.foodPartner &&
                                <Grid item xs={ 7 }>
                                    <AutocompleteMultipleSearchOnType
                                        options={ productOptions }
                                        value={ chosenProducts }
                                        setValue={ (newValue) => setChosenProducts(newValue) }
                                        label="Produkty - wiele (wpisz min. 3 litery)"
                                        required
                                        onInputChange={ (value) => onProductsInputChange(value) }
                                        loading={ loadingProductsOptions }
                                    />
                                </Grid>
                            }
                        </Grid>
                        <Grid container item gap={ 2 }>
                            <Grid item>
                                <DatePickerFormik
                                    name="fromDate"
                                    label="Data od"
                                    required
                                    InputLabelProps={ { shrink: true } }
                                    minDate={ moment() }
                                />
                            </Grid>
                            <Grid item>
                                <DatePickerFormik
                                    name="toDate"
                                    label="Data do"
                                    required
                                    InputLabelProps={ { shrink: true } }
                                    minDate={ moment() }
                                />
                            </Grid>
                            <Grid item>
                                <TextFieldFormik
                                    name="generalQuantityLimit"
                                    type="number"
                                    label="Globalny limit rezerwacji"
                                    inputProps={ { min: '1', step: '1' } }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item mt={ 2 } justifyContent="flex-end">
                        <DialogActions sx={ { gap: 2 } }>
                            <Button onClick={ onClose }>Zamknij</Button>
                            <SubmitButton isLoading={ createProductAvailability?.loading }>
                                {
                                    (formik?.values?.generalQuantityLimit && formik?.values?.generalQuantityLimit > 0)
                                        ? 'Dalej'
                                        : 'Zapisz'
                                }
                            </SubmitButton>
                        </DialogActions>
                    </Grid>
                </Grid>
            }
        </Formik>
    );
};

export default ProductAvailabilityForm;
