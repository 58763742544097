import React from 'react';
import { useSelector } from 'react-redux';

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { getWarehouseDocumentHistoryState } from '../../../../../features/inventory/get_warehouse_document_history';
import moment from "moment/moment";
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_DATE_ONLY_FORMAT, MISC_DATE_POLISH_FORMAT } from "../../../../../core/constants";

const WarehouseDocumentHistory = ({ onClickDocument }) => {
    const getWarehouseDocumentHistory = useSelector(getWarehouseDocumentHistoryState);

    return (
        <Grid container item>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID dokumentu</TableCell>
                            <TableCell align="center">Numer PZ</TableCell>
                            <TableCell align="center">Data utworzenia</TableCell>
                            <TableCell align="center">Data dostawy</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            getWarehouseDocumentHistory?.data?.content?.map((warehouseDocumentHistory, index) => (
                                <TableRow
                                    key={ warehouseDocumentHistory?.id }
                                    hover
                                    sx={ { cursor: 'pointer' } }
                                    onClick={ () => onClickDocument(index) }
                                >
                                    <TableCell>{ warehouseDocumentHistory?.id }</TableCell>
                                    <TableCell align="center">{ warehouseDocumentHistory?.iluoGRNDocumentNumber }</TableCell>
                                    <TableCell align="center">
                                        {
                                            moment(warehouseDocumentHistory?.createDate, MISC_DATE_ISO_FORMAT)
                                                .format(MISC_DATE_POLISH_FORMAT)
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            moment(warehouseDocumentHistory?.deliveryDate, MISC_DATE_ISO_DATE_ONLY_FORMAT)
                                                .format(MISC_DATE_POLISH_DATE_ONLY_FORMAT)
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default WarehouseDocumentHistory;
