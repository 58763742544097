import { deepFreeze } from '../../utils/misc_utils';

export const WarehouseDocumentTypeEnum = deepFreeze({
    ONLY_GRN_DOCUMENT: {
        value: 'ONLY_GRN_DOCUMENT',
        label: 'PZ'
    },
    GRN_AND_DELIVERY_DOCUMENTS: {
        value: 'GRN_AND_DELIVERY_DOCUMENTS',
        label: 'PZ + MM'
    },
    ONLY_DELIVERY_DOCUMENTS: {
        value: 'ONLY_DELIVERY_DOCUMENTS',
        label: 'MM'
    }
});