import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';

import useNotification from '../../../core/hooks/use_notification';
import { getInventoryExportNotificationData } from '../../../core/utils/notification_utils';
import { TileButton } from '../../common/button';
import { getInventoryExportAction, getInventoryExportState } from '../../../features/inventory/get_inventory_export';
import { ROUTE_WAREHOUSE_DOCUMENT_FORM } from '../../../core/constants';

const WarehousePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showNotification } = useNotification();

    const getInventoryExport = useSelector(getInventoryExportState);

    const onSubmit = () => {
        dispatch(getInventoryExportAction())
            .then(response => showNotification(getInventoryExportNotificationData(response)));
    };

    return (
        <Grid mb={ 6 }>
            <Grid mb={ 4 }>
                <Typography variant="h1">Magazyn</Typography>
            </Grid>
            <Grid mb={ 4 } container gap={ 4 } item justifyContent="center">
                        <TileButton onClick={ onSubmit } isLoading={ getInventoryExport?.loading }>
                            Generuj<br />plik Excel
                        </TileButton>
                        <TileButton onClick={ () => navigate(ROUTE_WAREHOUSE_DOCUMENT_FORM) }>
                            Dodaj dokument magazynowy
                        </TileButton>
            </Grid>

        </Grid>
    );
};

export default WarehousePage;
