import { ApiRequestStatusEnum } from '../enums/common/api';
import { AuthorizationErrorMessageEnum, AuthorizationSuccessMessageEnum, getAuthorizationErrorMessage } from '../enums/authorization/message';
import { getProductErrorMessage, ProductErrorMessageEnum, ProductSuccessMessageEnum } from '../enums/product/message';
import { getInventoryErrorMessage, InventoryErrorMessageEnum, InventorySuccessMessageEnum } from '../enums/inventory/message';
import { getPodErrorMessage, PodErrorMessageEnum, PodSuccessMessageEnum } from '../enums/pod/message';
import { getPriceErrorMessage, PriceErrorMessageEnum, PriceSuccessMessageEnum } from '../enums/price/message';
import { CommunicationErrorMessageEnum, CommunicationSuccessMessageEnum, getCommunicationErrorMessage } from '../enums/communication/message';
import { getStoreErrorMessage, StoreErrorMessageEnum, StoreSuccessMessageEnum } from '../enums/store/message';
import { CouponStatusEnum } from '../enums/price/coupon';
import { CustomerErrorMessageEnum, getCustomerErrorMessage } from '../enums/customer/message';
import { FoodPartnerErrorMessageEnum, FoodPartnerSuccessMessageEnum, getFoodPartnerErrorMessage } from '../enums/food_partner/message';
import { BenefitErrorMessageEnum, BenefitSuccessMessageEnum, getBenefitErrorMessage } from '../enums/benefit/message';
import { getReservationErrorMessage, ReservationErrorMessageEnum, ReservationSuccessMessageEnum } from '../enums/reservation/message';
import { PromotionCampaignStatusEnum } from '../enums/price/promotion_campaign';

//---------------------------------------------------------------------------------------------------------------------- Authorization

export const getTokenNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: AuthorizationSuccessMessageEnum.SIGN_IN
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getAuthorizationErrorMessage(response?.payload?.message, AuthorizationErrorMessageEnum.SIGN_IN)
        };
};

export const deleteTokenNotificationData = () => {
    return {
        status: ApiRequestStatusEnum.FULFILLED,
        message: AuthorizationSuccessMessageEnum.SIGN_OUT
    };
};

//---------------------------------------------------------------------------------------------------------------------- Communication


export const getNotificationsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getCommunicationErrorMessage(response?.payload?.message, CommunicationErrorMessageEnum.GET_NOTIFICATIONS)
    };
};


export const createNotificationNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: CommunicationSuccessMessageEnum.CREATE_NOTIFICATION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getCommunicationErrorMessage(response?.payload?.message, CommunicationErrorMessageEnum.CREATE_NOTIFICATION)
        };
};

export const getNotificationTypesNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getCommunicationErrorMessage(response?.payload?.message, CommunicationErrorMessageEnum.GET_NOTIFICATION_TYPES)
    };
};

export const deleteNotificationNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: CommunicationSuccessMessageEnum.DELETE_NOTIFICATION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getCommunicationErrorMessage(response?.payload?.message, CommunicationErrorMessageEnum.DELETE_NOTIFICATION)
        };
};

export const getDeliveryNotificationsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getCommunicationErrorMessage(response?.payload?.message, CommunicationErrorMessageEnum.GET_DELIVERY_NOTIFICATIONS)
    };
};

export const getDeliveryNotificationNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getCommunicationErrorMessage(response?.payload?.message, CommunicationErrorMessageEnum.GET_DELIVERY_NOTIFICATION)
    };
};

export const deleteDeliveryNotificationNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: CommunicationSuccessMessageEnum.DELETE_DELIVERY_NOTIFICATION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getCommunicationErrorMessage(response?.payload?.message, CommunicationErrorMessageEnum.DELETE_DELIVERY_NOTIFICATION)
        };
};

export const createDeliveryNotificationNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: CommunicationSuccessMessageEnum.CREATE_DELIVERY_NOTIFICATION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getCommunicationErrorMessage(response?.payload?.message, CommunicationErrorMessageEnum.CREATE_DELIVERY_NOTIFICATION)
        };
};

export const updateDeliveryNotificationNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: CommunicationSuccessMessageEnum.UPDATE_DELIVERY_NOTIFICATION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getCommunicationErrorMessage(response?.payload?.message, CommunicationErrorMessageEnum.UPDATE_DELIVERY_NOTIFICATION)
        };
};

//---------------------------------------------------------------------------------------------------------------------- Customer

export const getCustomersByPhoneNumberNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getCustomerErrorMessage(response?.payload?.message, CustomerErrorMessageEnum.GET_CUSTOMERS_BY_PHONE_NUMBER)
    };
};

export const getCustomersNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getCustomerErrorMessage(response?.payload?.message, CustomerErrorMessageEnum.GET_CUSTOMERS)
    };
};

//---------------------------------------------------------------------------------------------------------------------- Inventory

export const getProductBatchesNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.GET_PRODUCT_BATCHES)
    };
};

export const updateProductsNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: InventorySuccessMessageEnum.UPDATE_PRODUCTS_DEFINITION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.UPDATE_PRODUCTS_DEFINITION)
        };
};

export const getInventoryExportNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: InventorySuccessMessageEnum.GET_INVENTORY_EXPORT
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.GET_INVENTORY_EXPORT)
        };
};

export const getCounterpartiesNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.GET_COUNTERPARTIES)
    };
};

export const getAllInventoryProductsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.GET_ALL_PRODUCTS)
    };
};

export const getWarehouseProductBatchesNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.GET_WAREHOUSE_PRODUCT_BATCHES)
    };
};

export const createWarehouseDocumentNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? response?.payload === 'generateLabelsFailed'
            ? {
                status: ApiRequestStatusEnum.FULFILLED,
                message: InventorySuccessMessageEnum.PARTIALLY_CREATE_WAREHOUSE_DOCUMENT
            }
            : {
                status: ApiRequestStatusEnum.FULFILLED,
                message: InventorySuccessMessageEnum.CREATE_WAREHOUSE_DOCUMENT
            }
            : {
                status: ApiRequestStatusEnum.REJECTED,
                message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.CREATE_WAREHOUSE_DOCUMENT)
            };
};

export const getWarehouseDocumentHistoryNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.GET_WAREHOUSE_DOCUMENT_HISTORY)
    };
};

export const getAllWarehousesNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.GET_ALL_WAREHOUSES)
    };
};

export const getFoodPartnerOrdersNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.GET_FOOD_PARTNER_ORDERS)
    };
};

export const getFoodPartnerOrderNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.GET_FOOD_PARTNER_ORDER)
    };
};

export const sendFoodPartnerOrderNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: InventorySuccessMessageEnum.SEND_FOOD_PARTNER_ORDER
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.SEND_FOOD_PARTNER_ORDER)
        };
};

export const generateFoodPartnerOrderLabelsNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: InventorySuccessMessageEnum.GENERATE_FOOD_PARTNER_ORDER_LABELS
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.GENERATE_FOOD_PARTNER_ORDER_LABELS)
        };
};

export const updateFoodPartnerOrderStatusNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: InventorySuccessMessageEnum.UPDATE_FOOD_PARTNER_ORDER_STATUS
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.UPDATE_FOOD_PARTNER_ORDER_STATUS)
        };
};

export const createFoodPartnerOrderNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? response?.payload === 'generateLabelsFailed'
            ? {
                status: ApiRequestStatusEnum.FULFILLED,
                message: InventorySuccessMessageEnum.PARTIALLY_CREATE_FOOD_PARTNER_ORDER
            }
            : {
                status: ApiRequestStatusEnum.FULFILLED,
                message: InventorySuccessMessageEnum.CREATE_FOOD_PARTNER_ORDER
            }
            : {
                status: ApiRequestStatusEnum.REJECTED,
                message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.CREATE_FOOD_PARTNER_ORDER)
            };
};

export const updateFoodPartnerOrderNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? response?.payload === 'generateLabelsFailed'
            ? {
                status: ApiRequestStatusEnum.FULFILLED,
                message: InventorySuccessMessageEnum.PARTIALLY_UPDATE_FOOD_PARTNER_ORDER
            }
            : {
                status: ApiRequestStatusEnum.FULFILLED,
                message: InventorySuccessMessageEnum.UPDATE_FOOD_PARTNER_ORDER
            }
            : {
                status: ApiRequestStatusEnum.REJECTED,
                message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.UPDATE_FOOD_PARTNER_ORDER)
            };
};

export const getFoodPartnerOrderHistoryNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.GET_FOOD_PARTNER_ORDER_HISTORY)
    };
};

export const getFoodPartnerOrderAttachmentNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getInventoryErrorMessage(response?.payload?.message, InventoryErrorMessageEnum.GET_FOOD_PARTNER_ORDER_ATTACHMENT)
    };
};

//---------------------------------------------------------------------------------------------------------------------- Pod

export const getPodNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPodErrorMessage(response?.payload?.message, PodErrorMessageEnum.GET_POD)
    };
};

export const getPodsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPodErrorMessage(response?.payload?.message, PodErrorMessageEnum.GET_PODS)
    };
};

export const getAdvertisementsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPodErrorMessage(response?.payload?.message, PodErrorMessageEnum.GET_ADVERTISEMENTS)
    };
};

export const createAdvertisementsNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PodSuccessMessageEnum.CREATE_ADVERTISEMENTS
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPodErrorMessage(response?.payload?.message, PodErrorMessageEnum.CREATE_ADVERTISEMENTS)
        };
};

export const updateAdvertisementsDisplayOrderNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PodSuccessMessageEnum.UPDATE_ADVERTISEMENTS_DISPLAY_ORDER
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPodErrorMessage(response?.payload?.message, PodErrorMessageEnum.UPDATE_ADVERTISEMENTS_DISPLAY_ORDER)
        };
};

export const updateAdvertisementDisplayTimeNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PodSuccessMessageEnum.UPDATE_ADVERTISEMENT_DISPLAY_TIME
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPodErrorMessage(response?.payload?.message, PodErrorMessageEnum.UPDATE_ADVERTISEMENT_DISPLAY_TIME)
        };
};

export const updateAdvertisementProductsNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PodSuccessMessageEnum.UPDATE_ADVERTISEMENT_DISPLAY_TIME
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPodErrorMessage(response?.payload?.message, PodErrorMessageEnum.UPDATE_ADVERTISEMENT_DISPLAY_TIME)
        };
};

export const deleteAdvertisementNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PodSuccessMessageEnum.DELETE_ADVERTISEMENT
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPodErrorMessage(response?.payload?.message, PodErrorMessageEnum.DELETE_ADVERTISEMENT)
        };
};

//---------------------------------------------------------------------------------------------------------------------- Store

export const getBusinessDataNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getStoreErrorMessage(response?.payload?.message, StoreErrorMessageEnum.GET_BUSINESS_DATA)
    };
};

export const createBusinessDataNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getStoreErrorMessage(response?.payload?.message, StoreErrorMessageEnum.POST_BUSINESS_DATA)
    };
};


export const getBusinessClassificationsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getStoreErrorMessage(response?.payload?.message, StoreErrorMessageEnum.GET_BUSINESS_CLASSIFICATIONS)
    };
};

export const getBusinessDecisionsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getStoreErrorMessage(response?.payload?.message, StoreErrorMessageEnum.GET_BUSINESS_DECISIONS)
    };
};

export const updateBusinessNoteNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getStoreErrorMessage(response?.payload?.message, StoreErrorMessageEnum.UPDATE_BUSINESS_NOTE)
    };
};

export const deleteBusinessNoteNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getStoreErrorMessage(response?.payload?.message, StoreErrorMessageEnum.DELETE_BUSINESS_NOTE)
    };
};

export const getSuppliersNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getStoreErrorMessage(response?.payload?.message, StoreErrorMessageEnum.GET_SUPPLIERS)
    };
};

export const getSupplierNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getStoreErrorMessage(response?.payload?.message, StoreErrorMessageEnum.GET_SUPPLIER)
    };
};

export const updateSupplierStatusNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: StoreSuccessMessageEnum.UPDATE_SUPPLIER_STATUS
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getStoreErrorMessage(response?.payload?.message, StoreErrorMessageEnum.UPDATE_SUPPLIER_STATUS)
        };
};

export const getSupplierKeyNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getStoreErrorMessage(response?.payload?.message, StoreErrorMessageEnum.GET_SUPPLIER_KEY)
    };
};

export const getNewSupplierKeyNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getStoreErrorMessage(response?.payload?.message, StoreErrorMessageEnum.GET_NEW_SUPPLIER_KEY)
    };
};

export const createSupplierNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: StoreSuccessMessageEnum.CREATE_SUPPLIER
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getStoreErrorMessage(response?.payload?.message, StoreErrorMessageEnum.CREATE_SUPPLIER)
        };
};

export const updateSupplierNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: StoreSuccessMessageEnum.UPDATE_SUPPLIER
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getStoreErrorMessage(response?.payload?.message, StoreErrorMessageEnum.UPDATE_SUPPLIER)
        };
};

//---------------------------------------------------------------------------------------------------------------------- Price

export const getProductPromotionsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_PROMOTION)
    };
};

export const deleteProductPromotionsNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.DELETE_PROMOTION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.DELETE_PROMOTION)
        };
};

export const getProductExpirationPromotionsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_PROMOTION)
    };
};

export const deleteProductExpirationPromotionsNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.DELETE_PROMOTION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.DELETE_PROMOTION)
        };
};

export const getPromotionNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_PROMOTION)
    };
};

export const updatePromotionNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.UPDATE_PROMOTION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.UPDATE_PROMOTION)
        };
};

export const deletePromotionNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.DELETE_PROMOTION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.DELETE_PROMOTION)
        };
};

export const getPriceNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_PRICE)
    };
};

export const updatePricesNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.UPDATE_PRICES
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.UPDATE_PRICES)
        };
};

export const getCouponNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_COUPON)
    };
};

export const getCouponsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_COUPONS)
    };
};

export const updateCouponStatusNotificationData = (response, couponStatus) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: couponStatus === CouponStatusEnum.DELETED
                ? PriceSuccessMessageEnum.DELETE_COUPON
                : PriceSuccessMessageEnum.UPDATE_COUPON_STATUS
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, couponStatus === CouponStatusEnum.DELETED
                ? PriceErrorMessageEnum.DELETE_COUPON
                : PriceErrorMessageEnum.UPDATE_COUPON_STATUS)
        };
};

export const getCouponTranslationNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_COUPON_TRANSLATION)
    };
};

export const createCouponNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.CREATE_COUPON
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.CREATE_COUPON)
        };
};

export const updateCouponNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.UPDATE_COUPON
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.UPDATE_COUPON)
        };
};

export const updateCouponTranslationNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.UPDATE_COUPON_TRANSLATION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.UPDATE_COUPON_TRANSLATION)
        };
};

export const getAutomaticCouponSettingsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_AUTOMATIC_COUPON_SETTINGS)
    };
};

export const getAutomaticCouponSettingNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_AUTOMATIC_COUPON_SETTING)
    };
};

export const updateAutomaticCouponSettingNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.UPDATE_AUTOMATIC_COUPON_SETTING
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.UPDATE_AUTOMATIC_COUPON_SETTING)
        };
};

export const updateAutomaticCouponSettingStatusNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.UPDATE_AUTOMATIC_COUPON_SETTING_STATUS
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.UPDATE_AUTOMATIC_COUPON_SETTING_STATUS)
        };
};

export const getAutomaticCouponSettingTranslationNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_AUTOMATIC_COUPON_SETTING_TRANSLATION)
    };
};

export const updateAutomaticCouponSettingTranslationNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.UPDATE_AUTOMATIC_COUPON_SETTING_TRANSLATION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.UPDATE_AUTOMATIC_COUPON_SETTING_TRANSLATION)
        };
};

export const getAutomaticExpirationPromotionSettingsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_AUTOMATIC_EXPIRATION_PROMOTION_SETTINGS)
    };
};

export const getAutomaticExpirationPromotionSettingNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_AUTOMATIC_EXPIRATION_PROMOTION_SETTING)
    };
};

export const updateAutomaticExpirationPromotionSettingStatusActionNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.UPDATE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING_STATE
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.UPDATE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING_STATE)
        };
};

export const updateAutomaticExpirationPromotionSettingNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.UPDATE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.UPDATE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING)
        };
};

export const getPromotionCampaignNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_PROMOTION_CAMPAIGN)
    };
};

export const getPromotionCampaignsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_PROMOTION_CAMPAIGNS)
    };
};

export const updatePromotionCampaignStatusNotificationData = (response, couponStatus) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: couponStatus === PromotionCampaignStatusEnum.DELETED
                ? PriceSuccessMessageEnum.DELETE_PROMOTION_CAMPAIGN
                : PriceSuccessMessageEnum.UPDATE_PROMOTION_CAMPAIGN_STATUS
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, couponStatus === PromotionCampaignStatusEnum.DELETED
                ? PriceErrorMessageEnum.DELETE_PROMOTION_CAMPAIGN
                : PriceErrorMessageEnum.UPDATE_PROMOTION_CAMPAIGN_STATUS)
        };
};

export const createPromotionCampaignNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.CREATE_PROMOTION_CAMPAIGN
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.CREATE_PROMOTION_CAMPAIGN)
        };
};

export const updatePromotionCampaignNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.UPDATE_PROMOTION_CAMPAIGN
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.UPDATE_PROMOTION_CAMPAIGN)
        };
};

export const getPromotionCampaignNotificationNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_PROMOTION_CAMPAIGN_NOTIFICATION)
    };
};

export const updatePromotionCampaignNotificationNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.UPDATE_PROMOTION_CAMPAIGN_NOTIFICATION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.UPDATE_PROMOTION_CAMPAIGN_NOTIFICATION)
        };
};

export const deletePromotionCampaignNotificationNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.DELETE_PROMOTION_CAMPAIGN_NOTIFICATION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.DELETE_PROMOTION_CAMPAIGN_NOTIFICATION)
        };
};

export const getPromotionCampaignAdvertisementNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.GET_PROMOTION_CAMPAIGN_NOTIFICATION)
    };
};

export const updatePromotionCampaignAdvertisementNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.UPDATE_PROMOTION_CAMPAIGN_ADVERTISEMENT
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.UPDATE_PROMOTION_CAMPAIGN_ADVERTISEMENT)
        };
};

export const deletePromotionCampaignAdvertisementNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: PriceSuccessMessageEnum.DELETE_PROMOTION_CAMPAIGN_ADVERTISEMENT
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getPriceErrorMessage(response?.payload?.message, PriceErrorMessageEnum.DELETE_PROMOTION_CAMPAIGN_ADVERTISEMENT)
        };
};

//---------------------------------------------------------------------------------------------------------------------- Food Partner

export const getFoodPartnersNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.GET_FOOD_PARTNERS)
    };
};

export const getAllFoodPartnersNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.GET_ALL_FOOD_PARTNERS)
    };
};

export const getFoodPartnerNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.GET_FOOD_PARTNER)
    };
};

export const getFoodPartnersForReservationNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.GET_FOOD_PARTNERS_FOR_RESERVATION)
    };
};

export const createFoodPartnerNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: FoodPartnerSuccessMessageEnum.CREATE_FOOD_PARTNER
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.CREATE_FOOD_PARTNER)
        };
};

export const updateFoodPartnerNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: FoodPartnerSuccessMessageEnum.UPDATE_FOOD_PARTNER
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.UPDATE_FOOD_PARTNER)
        };
};

export const deleteFoodPartnerNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: FoodPartnerSuccessMessageEnum.DELETE_FOOD_PARTNER
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.DELETE_FOOD_PARTNER)
        };
};

export const getContactPersonsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.GET_CONTACT_PERSONS)
    };
};

export const getContactPersonNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.GET_CONTACT_PERSON)
    };
};

export const createContactPersonNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: FoodPartnerSuccessMessageEnum.CREATE_CONTACT_PERSON
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.CREATE_CONTACT_PERSON)
        };
};

export const updateContactPersonNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: FoodPartnerSuccessMessageEnum.UPDATE_CONTACT_PERSON
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.UPDATE_CONTACT_PERSON)
        };
};

export const deleteContactPersonNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: FoodPartnerSuccessMessageEnum.DELETE_CONTACT_PERSON
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.DELETE_CONTACT_PERSON)
        };
};

export const getDocumentsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.GET_DOCUMENTS)
    };
};

export const createDocumentNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: FoodPartnerSuccessMessageEnum.CREATE_DOCUMENT
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.CREATE_DOCUMENT)
        };
};

export const deleteDocumentNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: FoodPartnerSuccessMessageEnum.DELETE_DOCUMENT
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getFoodPartnerErrorMessage(response?.payload?.message, FoodPartnerErrorMessageEnum.DELETE_DOCUMENT)
        };
};

//---------------------------------------------------------------------------------------------------------------------- Product

export const getProductsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.GET_PRODUCTS)
    };
};

export const getAllProductProductsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.GET_ALL_PRODUCTS)
    };
};

export const getProductNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.GET_PRODUCT)
    };
};

export const getProductsForReservationNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.GET_PRODUCTS_FOR_RESERVATION)
    };
};

export const getProductsForPromotionNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.GET_PRODUCTS_FOR_PROMOTION)
    };
};

export const updateProductNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: ProductSuccessMessageEnum.UPDATE_PRODUCT
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.UPDATE_PRODUCT)
        };
};

export const updateProductDetailsNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: ProductSuccessMessageEnum.UPDATE_PRODUCT_DETAILS
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.UPDATE_PRODUCT_DETAILS)
        };
};

export const getQRCodeNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.GET_LABEL)
    };
};

export const getLanguagesNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.GET_LANGUAGES)
    };
};

export const getConstantsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.GET_CONSTANTS)
    };
};

export const getProducersNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.GET_PRODUCERS)
    };
};

export const getAllProducersNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.GET_ALL_PRODUCERS)
    };
};

export const getProducerNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.GET_PRODUCER)
    };
};

export const createProducerNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: ProductSuccessMessageEnum.CREATE_PRODUCER
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.CREATE_PRODUCER)
        };
};

export const updateProducerNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: ProductSuccessMessageEnum.UPDATE_PRODUCER
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.UPDATE_PRODUCER)
        };
};

export const deleteProducerNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: ProductSuccessMessageEnum.DELETE_PRODUCER
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getProductErrorMessage(response?.payload?.message, ProductErrorMessageEnum.DELETE_PRODUCER)
        };
};

//---------------------------------------------------------------------------------------------------------------------- Benefit

export const getEmployersNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getBenefitErrorMessage(response?.payload?.message, BenefitErrorMessageEnum.GET_EMPLOYERS)
    };
};

export const getAllEmployersNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getBenefitErrorMessage(response?.payload?.message, BenefitErrorMessageEnum.GET_ALL_EMPLOYER)
    };
};

export const getEmployerNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getBenefitErrorMessage(response?.payload?.message, BenefitErrorMessageEnum.GET_EMPLOYER)
    };
};

export const getEmployerDiscountNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getBenefitErrorMessage(response?.payload?.message, BenefitErrorMessageEnum.GET_EMPLOYER_DISCOUNT)
    };
};

export const updateEmployerDiscountNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: BenefitSuccessMessageEnum.UPDATE_EMPLOYER_DISCOUNT
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getBenefitErrorMessage(response?.payload?.message, BenefitErrorMessageEnum.UPDATE_EMPLOYER_DISCOUNT)
        };
};

export const deleteEmployerDiscountNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: BenefitSuccessMessageEnum.DELETE_EMPLOYER_DISCOUNT
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getBenefitErrorMessage(response?.payload?.message, BenefitErrorMessageEnum.DELETE_EMPLOYER_DISCOUNT)
        };
};

export const getEmployerReportNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: BenefitSuccessMessageEnum.CREATE_REPORT
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getBenefitErrorMessage(response?.payload?.message, BenefitErrorMessageEnum.CREATE_REPORT)
        };
};

export const updateEmployerNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: BenefitSuccessMessageEnum.UPDATE_EMPLOYER
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getBenefitErrorMessage(response?.payload?.message, BenefitErrorMessageEnum.UPDATE_EMPLOYER)
        };
};

export const updateEmployerStatusNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: BenefitSuccessMessageEnum.UPDATE_EMPLOYER_STATUS
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getBenefitErrorMessage(response?.payload?.message, BenefitErrorMessageEnum.UPDATE_EMPLOYER_STATUS)
        };
};

export const getEmployeesNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getBenefitErrorMessage(response?.payload?.message, BenefitErrorMessageEnum.GET_EMPLOYEES)
    };
};

export const getEmployeesToConfirmNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getBenefitErrorMessage(response?.payload?.message, BenefitErrorMessageEnum.GET_EMPLOYEES_TO_CONFIRM)
    };
};

export const updateEmployeeNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: BenefitSuccessMessageEnum.UPDATE_EMPLOYEE
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getBenefitErrorMessage(response?.payload?.message, BenefitErrorMessageEnum.UPDATE_EMPLOYEE)
        };
};

//---------------------------------------------------------------------------------------------------------------------- Reservation

export const getProductsAvailabilityNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getReservationErrorMessage(response?.payload?.message, ReservationErrorMessageEnum.GET_PRODUCTS_AVAILABILITY)
    };
};

export const getProductsAvailabilitySingleDayNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getReservationErrorMessage(response?.payload?.message, ReservationErrorMessageEnum.GET_PRODUCTS_AVAILABILITY_SINGLE_DAY)
    };
};

export const createProductAvailabilityNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: ReservationSuccessMessageEnum.CREATE_PRODUCT_AVAILABILITY
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getReservationErrorMessage(response?.payload?.message, ReservationErrorMessageEnum.CREATE_PRODUCT_AVAILABILITY)
        };
};

export const importProductAvailabilityNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: ReservationSuccessMessageEnum.IMPORT_PRODUCT_AVAILABILITY
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getReservationErrorMessage(response?.payload?.message, ReservationErrorMessageEnum.IMPORT_PRODUCT_AVAILABILITY)
        };
};

export const deleteProductAvailabilityNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: ReservationSuccessMessageEnum.DELETE_PRODUCT_AVAILABILITY
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getReservationErrorMessage(response?.payload?.message, ReservationErrorMessageEnum.DELETE_PRODUCT_AVAILABILITY)
        };
};

export const updateProductQuantityLimitNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: ReservationSuccessMessageEnum.UPDATE_PRODUCT_QUANTITY_LIMIT
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getReservationErrorMessage(response?.payload?.message, ReservationErrorMessageEnum.UPDATE_PRODUCT_QUANTITY_LIMIT)
        };
};

export const getReservationsNotificationData = (response) => {
    return {
        status: response?.meta?.requestStatus,
        message: getReservationErrorMessage(response?.payload?.message, ReservationErrorMessageEnum.GET_RESERVATIONS)
    };
};

export const deleteReservationNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: ReservationSuccessMessageEnum.DELETE_RESERVATION
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getReservationErrorMessage(response?.payload?.message, ReservationErrorMessageEnum.DELETE_RESERVATION)
        };
};

export const updateReservationStoreStatusNotificationData = (response) => {
    return response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED
        ? {
            status: ApiRequestStatusEnum.FULFILLED,
            message: ReservationSuccessMessageEnum.UPDATE_RESERVATION_STORE_STATUS
        }
        : {
            status: ApiRequestStatusEnum.REJECTED,
            message: getReservationErrorMessage(response?.payload?.message, ReservationErrorMessageEnum.UPDATE_RESERVATION_STORE_STATUS)
        };
};
