import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';
import moment from 'moment/moment';
import _ from 'lodash';
import deepmerge from 'deepmerge';

import { Button, DeleteButton, DuplicateButton, SubmitButton } from '../../../common/button';
import { GET_ROUTE_PROMOTION_CAMPAIGN_FORMS, MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT, ROUTE_PROMOTION_CAMPAIGN_FORMS_NEW } from '../../../../core/constants';
import { getPromotionCampaignsAction, getPromotionCampaignsState } from '../../../../features/price/get_promotion_campaigns';
import { getButtonText, getPromotionCampaignStatus, getUpdatePromotionCampaignStatus } from '../../../common/utils/promotion_campaign_utils';
import { getPromotionCampaignsNotificationData, updatePromotionCampaignStatusNotificationData } from '../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import useNotification from '../../../../core/hooks/use_notification';
import { getDefaultPaginationQueryValues } from '../../../../core/utils/api_utils';
import PaginationControls from '../../../common/pagination_controls';
import { updatePromotionCampaignStatusAction, updatePromotionCampaignStatusState } from '../../../../features/price/update_promotion_campaign_status';
import { PromotionCampaignStatusEnum } from '../../../../core/enums/price/promotion_campaign';
import SelectField from '../../../common/select_field';
import { FormRouteEnum } from '../../../../core/enums/common/route';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    sort: ['workingName', 'asc'],
    showArchived: false,
    filter: 'all'
};

const filterOptions = [
    {
        value: 'all',
        label: 'Wszystkie'
    },
    {
        value: 'repeatable',
        label: 'Powtarzalne'
    },
    {
        value: 'nonRepeatable',
        label: 'Jednorazowe'
    }
];

const ActivePromotionCampaignList = ({ tab }) => {
    const { showErrorMessage, showNotification } = useNotification();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const getPromotionCampaigns = useSelector(getPromotionCampaignsState);
    const updatePromotionCampaignStatus = useSelector(updatePromotionCampaignStatusState);

    const [queryParams, setQueryParams] = useState(location?.state?.queryParams || initialQueryParams);

    const refreshPromotionCampaignList = useCallback(() => {
        dispatch(getPromotionCampaignsAction(queryParams))
            .then(response => showErrorMessage(getPromotionCampaignsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshPromotionCampaignList();

    }, [queryParams, refreshPromotionCampaignList]);

    const onSelectFilterChange = (event) => {
        setQueryParams(prevState => deepmerge(prevState, { filter: event.target.value }));
    };

    const onUpdatePromotionCampaignStatus = (promotionCampaign, status = null) => {
        const promotionCampaignStatus = status || getUpdatePromotionCampaignStatus(promotionCampaign?.status);

        dispatch(updatePromotionCampaignStatusAction({ id: promotionCampaign?.id, status: promotionCampaignStatus }))
            .then(response => {
                showNotification(updatePromotionCampaignStatusNotificationData(response, promotionCampaignStatus));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshPromotionCampaignList();
            });
    };

    return (
        <Grid container>
            <Grid item mb={ 4 }>
                <SelectField
                    label={ 'Pokaż' }
                    value={ _.pick(queryParams, ['filter']).filter }
                    onChange={ onSelectFilterChange }
                    options={ filterOptions }
                />
            </Grid>
            <Grid container item mb={ 4 }>
                <Button onClick={ () => navigate(ROUTE_PROMOTION_CAMPAIGN_FORMS_NEW, { state: { action: FormRouteEnum.NEW, queryParams, tab } }) }>
                    Dodaj akcję promocyjną
                </Button>
            </Grid>
            {
                getPromotionCampaigns?.loading &&
                <Grid item width={ '100%' }>
                    <LinearProgress/>
                </Grid>
            }
            {
                getPromotionCampaigns?.data &&
                <Grid container>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nazwa robocza</TableCell>
                                    <TableCell align="center">Data obowiązywania</TableCell>
                                    <TableCell align="center">Powtarzalna</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Podgląd/edycja </TableCell>
                                    <TableCell align="center">Zmiana statusu</TableCell>
                                    <TableCell align="center">Duplikuj</TableCell>
                                    <TableCell align="center">Usuń</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getPromotionCampaigns?.data?.content?.map(promotionCampaign => (
                                        <TableRow
                                            key={ promotionCampaign?.id }
                                            hover
                                        >
                                            <TableCell>{ promotionCampaign?.workingName }</TableCell>
                                            <TableCell align="center">
                                                <Grid container>
                                                    <Grid container item justifyContent="center">
                                                        Od:&nbsp;
                                                        {
                                                            moment(promotionCampaign?.timeFrom, MISC_DATE_ISO_FORMAT)
                                                                .format(MISC_DATE_POLISH_FORMAT)
                                                        }
                                                    </Grid>
                                                    <Grid container item justifyContent="center">
                                                        Do:&nbsp;
                                                        {
                                                            moment(promotionCampaign?.timeTo, MISC_DATE_ISO_FORMAT)
                                                                .format(MISC_DATE_POLISH_FORMAT)
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    promotionCampaign?.repeatable
                                                        ? <CheckIcon color="success"/>
                                                        : <ClearIcon color="error"/>
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    getPromotionCampaignStatus(promotionCampaign?.status)
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                <EditIcon onClick={ () => navigate(GET_ROUTE_PROMOTION_CAMPAIGN_FORMS(promotionCampaign?.id), { state: { action: FormRouteEnum.EDIT, queryParams, tab } }) } cursor="pointer"/>
                                            </TableCell>
                                            <TableCell align="center">
                                                <SubmitButton onClick={ () => onUpdatePromotionCampaignStatus(promotionCampaign) } isLoading={ updatePromotionCampaignStatus?.loading }>
                                                    {
                                                        getButtonText(promotionCampaign?.status)
                                                    }
                                                </SubmitButton>
                                            </TableCell>
                                            <TableCell align="center">
                                                <DuplicateButton onClick={ () => navigate(ROUTE_PROMOTION_CAMPAIGN_FORMS_NEW, { state: { action: FormRouteEnum.NEW, promotionCampaignIdToDuplicate: promotionCampaign?.id, queryParams, tab } }) }/>
                                            </TableCell>
                                            <TableCell align="center">
                                                <DeleteButton onDeleteClick={ () => onUpdatePromotionCampaignStatus(promotionCampaign, PromotionCampaignStatusEnum.DELETED) } isLoading={ updatePromotionCampaignStatus?.loading }/>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getPromotionCampaigns?.data?.pageable?.pageNumber }
                            totalPages={ getPromotionCampaigns?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default ActivePromotionCampaignList;
