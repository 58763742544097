import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Check as CheckIcon, Clear as ClearIcon, Edit as EditIcon } from '@mui/icons-material';
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import SearchIcon from '@mui/icons-material/Search';

import { GET_ROUTE_FOOD_PARTNER_ORDER, GET_ROUTE_FOOD_PARTNER_ORDER_EDIT, MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT } from '../../../core/constants';
import { getFoodPartnerOrdersState } from '../../../features/inventory/get_food_partner_orders';
import { getFoodPartnerOrderEmailStatusIcon, getFoodPartnerOrderStatus } from '../../common/utils/food_partner_order_utils';
import { CustomIconButton, DeleteButton } from '../../common/button';
import { generateFoodPartnerOrderLabelsNotificationData, sendFoodPartnerOrderNotificationData, updateFoodPartnerOrderStatusNotificationData } from '../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../core/enums/common/api';
import useNotification from '../../../core/hooks/use_notification';
import { updateFoodPartnerOrderStatusAction, updateFoodPartnerOrderStatusState } from '../../../features/inventory/update_food_partner_order_status';
import { FoodPartnerOrderStatusEnum } from '../../../core/enums/inventory/food_partner_order';
import { sendFoodPartnerOrderAction, sendFoodPartnerOrderState } from '../../../features/inventory/send_food_partner_order';
import { generateFoodPartnerOrderLabelsAction, generateFoodPartnerOrderLabelsState } from '../../../features/inventory/generate_food_partner_order_labels';
import { FormRouteEnum } from '../../../core/enums/common/route';
import ConfirmDialog from '../common/confirm_dialog';

const FoodPartnerOrdersList = ({ refreshList }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();
    const navigate = useNavigate();

    const getFoodPartnerOrders = useSelector(getFoodPartnerOrdersState);
    const sendFoodPartnerOrder = useSelector(sendFoodPartnerOrderState);
    const generateFoodPartnerOrderLabels = useSelector(generateFoodPartnerOrderLabelsState);
    const updateFoodPartnerOrderStatus = useSelector(updateFoodPartnerOrderStatusState);

    const onSendFoodPartnerOrderEmail = (foodPartnerOrderId, generateLabels) => {
        dispatch(sendFoodPartnerOrderAction({ id: foodPartnerOrderId, generateLabels }))
            .then(response => {
                showNotification(sendFoodPartnerOrderNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    const onGenerateFoodPartnerOrderLabels = (foodPartnerOrderId) => {
        dispatch(generateFoodPartnerOrderLabelsAction({ id: foodPartnerOrderId }))
            .then(response => {
                showNotification(generateFoodPartnerOrderLabelsNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    const onCancelFoodPartnerOrder = (foodPartnerOrderId) => {
        dispatch(updateFoodPartnerOrderStatusAction({ id: foodPartnerOrderId, status: FoodPartnerOrderStatusEnum.CANCELLED.value }))
            .then(response => {
                showNotification(updateFoodPartnerOrderStatusNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Tytuł</TableCell>
                        <TableCell align="center">Data utworzenia </TableCell>
                        <TableCell align="center">Data zamówienia</TableCell>
                        <TableCell align="center">Status email</TableCell>
                        <TableCell align="center">Podział na lokalizacje</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Wyślij email</TableCell>
                        <TableCell align="center">Pobierz naklejki</TableCell>
                        <TableCell align="center">Podgląd/edycja</TableCell>
                        <TableCell align="center">Anuluj</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getFoodPartnerOrders?.data?.content?.map(foodPartnerOrder => (
                            <TableRow
                                key={ foodPartnerOrder?.id }
                                hover
                            >
                                <TableCell>{ foodPartnerOrder?.title }</TableCell>
                                <TableCell align="center">
                                    {
                                        moment(foodPartnerOrder?.createDate, MISC_DATE_ISO_FORMAT)
                                            .format(MISC_DATE_POLISH_FORMAT)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        moment(foodPartnerOrder?.orderDate, MISC_DATE_ISO_FORMAT)
                                            .format(MISC_DATE_POLISH_FORMAT)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        getFoodPartnerOrderEmailStatusIcon(foodPartnerOrder?.emailStatus)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        foodPartnerOrder?.locationDivision
                                            ? <CheckIcon color="success"/>
                                            : <ClearIcon color="error"/>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        getFoodPartnerOrderStatus(foodPartnerOrder?.status)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        ([FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value].includes(foodPartnerOrder?.status)) &&
                                        <ConfirmDialog
                                            isLoading={ sendFoodPartnerOrder?.loading }
                                            maxWidth={ 'md' }
                                            title={ foodPartnerOrder?.emailStatus !== 'UNSENT' ?
                                                'Czy na pewno chcesz ponownie wysłać maila do Dostawcy? Pamiętaj, żeby wysyłać maila tylko przy aktualizacji zamówienia. Możesz uwzględnić paczkę z naklejkami w mailu z aktualizacją, lub wysłać ją bez naklejek.' :
                                                'Czy chcesz uwzględnić paczkę z naklejkami w mailu z zamówieniem?' }
                                            cancelText={ 'Anuluj' }
                                            secondaryAction={ () => onSendFoodPartnerOrderEmail(foodPartnerOrder?.id, false)  }
                                            secondaryText={ 'Wyślij bez naklejek' }
                                            primaryAction={ () => onSendFoodPartnerOrderEmail(foodPartnerOrder?.id, true)  }
                                            primaryText={ 'Wyślij z naklejkami' }
                                            displayIcon={ true }
                                            icon={ <EmailIcon/> }/>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        <CustomIconButton
                                            title={ 'Pobierz naklejki' }
                                            isLoading={ generateFoodPartnerOrderLabels?.loading }
                                            onClick={ () => onGenerateFoodPartnerOrderLabels(foodPartnerOrder?.id) }
                                            icon={ <DownloadIcon/> }/>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        ([FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value, FoodPartnerOrderStatusEnum.DELIVERED.value].includes(foodPartnerOrder?.status))
                                            ? <EditIcon onClick={ () => navigate(GET_ROUTE_FOOD_PARTNER_ORDER_EDIT(foodPartnerOrder?.id), { state: { action: FormRouteEnum.EDIT } }) } cursor="pointer"/>
                                            : <SearchIcon onClick={ () => navigate(GET_ROUTE_FOOD_PARTNER_ORDER(foodPartnerOrder?.id), { state: { action: FormRouteEnum.VIEW } }) } cursor="pointer"/>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        [FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value, FoodPartnerOrderStatusEnum.DELIVERED.value].includes(foodPartnerOrder?.status) &&
                                        <DeleteButton
                                            onDeleteClick={ () => onCancelFoodPartnerOrder(foodPartnerOrder?.id) }
                                            isLoading={ updateFoodPartnerOrderStatus?.loading }/>
                                    }
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default FoodPartnerOrdersList;
