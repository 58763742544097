import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Divider, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment/moment';
import _ from 'lodash';

import useNotification from '../../../../../core/hooks/use_notification';
import { getFoodPartnerOrderAction, getFoodPartnerOrderState } from '../../../../../features/inventory/get_food_partner_order';
import { updateFoodPartnerOrderAction, updateFoodPartnerOrderState } from '../../../../../features/inventory/update_food_partner_order';
import { createWarehouseDocumentAction, createWarehouseDocumentState } from '../../../../../features/inventory/create_warehouse_document';
import { FormRouteEnum } from '../../../../../core/enums/common/route';
import { FoodPartnerOrderStatusEnum } from '../../../../../core/enums/inventory/food_partner_order';
import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import { isEmptyObject } from '../../../../../core/utils/misc_utils';
import DestinationsList from '../common/destination_list';
import AddDestinationDialog from '../../../common/warehouse_and_food_partner_order_form/add_destination_dialog';
import UpdateStatusButtons from '../common/update_status_buttons';
import { SelectFieldFormik } from '../../../../common/select_field';
import RadioGroupFormik from '../../../../common/radio_group';
import { yesNoOptions } from '../../../../common/form_utils';
import { DateTimePickerFormik } from '../../../../common/date_picker';
import Buttons from './buttons';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_ISO_FORMAT } from '../../../../../core/constants';
import { WarehouseDocumentTypeEnum } from '../../../../../core/enums/inventory/warehouse_document';
import { createWarehouseDocumentNotificationData, getFoodPartnerOrderNotificationData, updateFoodPartnerOrderNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import AdditionalOptions from '../common/additional_options';
import { updateAndSendFoodPartnerOrderAction, updateAndSendFoodPartnerOrderState } from '../../../../../features/inventory/update_and_send_food_partner_order';
import UploadFiles from '../../../common/warehouse_and_food_partner_order_form/upload_files';

const FormWithLocationDivision = ({ action, setAction }) => {
    const dispatch = useDispatch();
    const { showErrorMessage, showNotification } = useNotification();

    const getAllPods = useSelector(getAllPodsState);
    const getFoodPartnerOrder = useSelector(getFoodPartnerOrderState);
    const updateFoodPartnerOrder = useSelector(updateFoodPartnerOrderState);
    const updateAndSendFoodPartnerOrder = useSelector(updateAndSendFoodPartnerOrderState);
    const createWarehouseDocument = useSelector(createWarehouseDocumentState);

    const disableForm = action === FormRouteEnum.VIEW;
    const foodPartnerOrderStatus = getFoodPartnerOrder?.data?.status;

    const initialValues = useCallback(() => {
        const destinationsList = [];
        const productsList = [];

        Object.keys(getFoodPartnerOrder?.data?.positions)?.forEach((key, index) => {
            destinationsList.push({
                position: index,
                name: getAllPods?.data?.map(pod => ({ id: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + pod?.serviceId })).find(pod => pod?.id === key)?.label,
                destinationPodId: key
            });

            productsList[index] = getFoodPartnerOrder?.data?.positions?.[key]?.map((product, index) => ({
                position: index,
                name: product?.productName,
                productId: product?.productId,
                quantity: product?.quantity,
                expirationDate: moment(product?.expirationDate) || '',
                price: product?.purchaseNetPrice || ''
            }));
        });

        return {
            foodPartnerId: getFoodPartnerOrder?.data?.foodPartnerId || '',
            locationDivision: true,
            orderDate: moment(getFoodPartnerOrder?.data?.orderDate) || '',
            productsList: productsList,
            destinationsList: destinationsList,
            attachments: getFoodPartnerOrder?.data?.attachments || [],
            attachmentIdsToDelete: []
        };
    }, [getAllPods?.data, getFoodPartnerOrder?.data?.attachments, getFoodPartnerOrder?.data?.foodPartnerId, getFoodPartnerOrder?.data?.orderDate, getFoodPartnerOrder?.data?.positions]);

    const onChangeSetDate = (newDate, allDates, formik) => {
        formik?.values?.destinationsList?.forEach((_, index) => {
            const productsList = formik?.values?.productsList[index]?.map(product => {
                if (!allDates && product?.expirationDate) {
                    return product;
                }

                return ({ ...product, expirationDate: newDate });
            });

            if (productsList?.length !== 0) {
                formik.setFieldValue(`productsList.${ index }`, productsList);
            }
        });
    };

    const validateOnSubmit = (formik) => {
        const errors = validate(formik.values);

        if (errors.productsList.length ||
            errors.products.length ||
            !isEmptyObject(errors.destinationsList)) {
            formik.setErrors(errors);
        }
    };

    const onUpdate = (formik, sendEmail, generateLabels) => {
        validateOnSubmit(formik);

        const locationProducts = [
            ...formik.values.destinationsList.map((destination, index) => (
                {
                    podId: destination.destinationPodId,
                    products: [
                        ...formik?.values?.productsList?.[index]?.map(product => ({
                            productId: product?.productId,
                            quantity: product?.quantity,
                            price: product?.price,
                            expirationDate: product?.expirationDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
                        }))
                    ]
                }
            ))
        ];
        const form = {
            locationProducts,
            generateLabels,
            orderDate: formik?.values?.orderDate?.format(MISC_DATE_ISO_FORMAT),
            attachmentIdsToDelete: formik?.values?.attachmentIdsToDelete
        };
        const attachments = formik?.values?.attachments || [];
        const dispatchAction = sendEmail ? updateAndSendFoodPartnerOrderAction : updateFoodPartnerOrderAction;

        dispatch(dispatchAction({ id: getFoodPartnerOrder?.data?.id, form, attachments }))
            .then(response => {
                showNotification(updateFoodPartnerOrderNotificationData(response));

                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    dispatch(getFoodPartnerOrderAction(getFoodPartnerOrder?.data?.id))
                        .then(response => showErrorMessage(getFoodPartnerOrderNotificationData(response)));
                }
            });
    };

    const onGenerateWarehouseDocument = (formik, generateLabels) => {
        validateOnSubmit(formik);

        const documents = [
            ...formik.values.destinationsList.map((destination, index) => (
                {
                    destinationPodId: destination.destinationPodId,
                    documentProducts: [
                        ...formik?.values?.productsList?.[index]?.map(product => ({
                            productId: product?.productId,
                            quantity: product?.quantity,
                            expirationDate: product?.expirationDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
                            price: product?.price
                        }))
                    ]
                }
            ))
        ];

        const form = {
            ..._.omit(formik?.values, [
                'orderDate',
                'productsList',
                'destinationsList',
                'locationDivision'
            ]),
            documents,
            generateLabels,
            deliveryDate: formik?.values?.orderDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
            type: WarehouseDocumentTypeEnum.GRN_AND_DELIVERY_DOCUMENTS.value,
            foodPartnerOrderId: getFoodPartnerOrder?.data?.id
        };

        dispatch(createWarehouseDocumentAction(form))
            .then(response => {
                showNotification(createWarehouseDocumentNotificationData(response));

                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    dispatch(getFoodPartnerOrderAction(getFoodPartnerOrder?.data?.id))
                        .then(response => {
                            showErrorMessage(getFoodPartnerOrderNotificationData(response));
                            response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && setAction(FormRouteEnum.VIEW);
                        });
                }
            });
    };

    const validate = (values) => {
        const errors = {
            productsList: [],
            products: [],
            destinationsList: {}
        };

        if (values.destinationsList.length === 0) {
            errors.destinationsList = 'Wybierz minimum 1 lokalizację';
        } else {
            values.destinationsList.forEach((_, index) => {
                if (values.productsList[index] === undefined || values.productsList[index].length === 0) {
                    errors.productsList[index] = 'Wybierz minimum 1 produkt';
                } else {
                    values.productsList[index].forEach((product, productIndex) => {
                        if (!product?.quantity) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [productIndex]: {
                                    ...errors.products?.[index]?.[productIndex],
                                    quantity: 'Pole wymagane'
                                }
                            };
                        } else {
                            if (product?.quantity <= 0) {
                                const oldValues = errors.products[index];
                                errors.products[index] = {
                                    ...oldValues,
                                    [productIndex]: {
                                        ...errors.products?.[index]?.[productIndex],
                                        quantity: 'Proszę podać wartość całkowitą dodatnią'
                                    }
                                };
                            }
                        }

                        if (foodPartnerOrderStatus === FoodPartnerOrderStatusEnum.DELIVERED.value && !product?.expirationDate) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [productIndex]: {
                                    ...errors.products?.[index]?.[productIndex],
                                    expirationDate: 'Pole wymagane'
                                }
                            };
                        }

                        if (!product?.price) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [productIndex]: {
                                    ...errors.products?.[index]?.[productIndex],
                                    price: 'Pole wymagane'
                                }
                            };
                        } else {
                            if (product?.price <= 0) {
                                const oldValues = errors.products[index];
                                errors.products[index] = {
                                    ...oldValues,
                                    [productIndex]: {
                                        ...errors.products?.[index]?.[productIndex],
                                        price: 'Proszę podać wartość dodatnią'
                                    }
                                };
                            }
                        }

                        if (!product?.expirationDate) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [productIndex]: {
                                    ...errors.products?.[index]?.[productIndex],
                                    expirationDate: 'Pole wymagane'
                                }
                            };
                        }
                    });
                }
            });
        }

        return errors;
    };

    return (
        <Formik initialValues={ initialValues() }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 2 } paddingTop={ 2 }>
                    <UpdateStatusButtons
                        foodPartnerOrderStatus={ foodPartnerOrderStatus }
                        foodPartnerOrderId={ getFoodPartnerOrder?.data?.id }/>
                    {
                        !disableForm &&
                        <Grid container item mb={ 2 }>
                            <Divider style={ { width: '100%' } }/>
                        </Grid>
                    }
                    <Grid container item rowGap={ 2 } mb={ 2 }>
                        <Grid container item gap={ 6 }>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="foodPartnerId"
                                    label="Dostawca"
                                    options={ [{ value: getFoodPartnerOrder?.data?.foodPartnerId, label: getFoodPartnerOrder?.data?.foodPartnerName }] }
                                    required
                                    disabled
                                />
                            </Grid>
                            <Grid item>
                                <RadioGroupFormik
                                    name="locationDivision"
                                    label="Podział na lokalizacje"
                                    options={ yesNoOptions }
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item>
                                <DateTimePickerFormik
                                    name="orderDate"
                                    label="Data zamówienia"
                                    required
                                    InputLabelProps={ { shrink: true } }
                                    disabled={ ![FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value].includes(foodPartnerOrderStatus) }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        [FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value].includes(foodPartnerOrderStatus) &&
                        <AdditionalOptions
                            onChangeSetDate={ (newDate, allDates) => onChangeSetDate(newDate, allDates, formik) }/>
                    }
                    <Grid container item mb={ 2 }>
                        <Divider style={ { width: '100%' } }/>
                    </Grid>
                    <DestinationsList
                        form={ {
                            foodPartnerId: formik?.values?.foodPartnerId,
                            deliveryDate: formik?.values?.orderDate
                        } }
                        formik={ formik }
                        disableForm={ disableForm }
                        withExpirationDate
                        foodPartnerOrderStatus={ foodPartnerOrderStatus }/>
                    {
                        [FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value].includes(foodPartnerOrderStatus) &&
                        <AddDestinationDialog
                            formik={ formik }/>
                    }
                    <UploadFiles
                        formik={ formik }
                        downloadOption
                        addAndRemoveOptions={ [FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value].includes(foodPartnerOrderStatus) }
                    />
                    <Buttons
                        onSave={ (sendEmail, generateLabels) => onUpdate(formik, sendEmail, generateLabels) }
                        displaySaveAction={ [FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value].includes(foodPartnerOrderStatus) }
                        displaySaveAndSendAction={ [FoodPartnerOrderStatusEnum.CREATED.value, FoodPartnerOrderStatusEnum.IN_PROGRESS.value].includes(foodPartnerOrderStatus) }
                        mailAlreadySent={ getFoodPartnerOrder?.data?.emailStatus !== 'UNSENT' }
                        onGenerate={ (generateLabels) => onGenerateWarehouseDocument(formik, generateLabels) }
                        displayGenerateWarehouseDocumentAction={ foodPartnerOrderStatus === FoodPartnerOrderStatusEnum.DELIVERED.value }
                        isLoading={ updateFoodPartnerOrder?.loading || updateAndSendFoodPartnerOrder?.loading || createWarehouseDocument?.loading }
                    />
                </Grid>
            }
        </Formik>
    );
};

export default FormWithLocationDivision;
