import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { tabsClasses } from '@mui/material/Tabs';
import { Box, Grid, LinearProgress, Tab, Tabs } from '@mui/material';

import useNotification from '../../../../core/hooks/use_notification';
import { getConstantsAction, getConstantsState, invalidateConstants } from '../../../../features/product/get_constants';
import { getAllPodsAction, getAllPodsState } from '../../../../features/pod/get_all_pods';
import { getAllProductsAction, getAllProductsState } from '../../../../features/product/get_all_products';
import { getAllFoodPartnersAction, getAllFoodPartnersState } from '../../../../features/food_partner/get_all_food_partners';
import { FormRouteEnum } from '../../../../core/enums/common/route';
import { Button } from '../../../common/button';
import { ROUTE_PROMOTIONS } from '../../../../core/constants';
import { getAllFoodPartnersNotificationData, getAllProductProductsNotificationData, getConstantsNotificationData, getPodsNotificationData, getPromotionCampaignNotificationData } from '../../../../core/utils/notification_utils';
import { getPromotionCampaignAction, getPromotionCampaignState, invalidatePromotionCampaign } from '../../../../features/price/get_promotion_campaign';
import PromotionCampaignForm from './forms/promotion_campaign_form';
import PromotionCampaignNotificationForm from './forms/promotion_campaign_notification';
import PromotionCampaignAdvertisementForm from './forms/promotion_campaign_advertisement';
import { isEmptyObject } from '../../../../core/utils/misc_utils';
import { getPromotionCampaignNotificationAction } from '../../../../features/price/get_promotion_campaign_notification';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { getPromotionCampaignAdvertisementAction } from '../../../../features/price/get_promotion_campaign_advertisement';

const PromotionCampaignFormsPage = () => {
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { promotionCampaignId } = useParams();

    const getPromotionCampaign = useSelector(getPromotionCampaignState);
    const getConstants = useSelector(getConstantsState);
    const getAllPods = useSelector(getAllPodsState);
    const getAllProducts = useSelector(getAllProductsState);
    const getAllFoodPartners = useSelector(getAllFoodPartnersState);

    const [tab, setTab] = useState(0);

    const [promotionCampaignAdvertisementToDuplicate, setPromotionCampaignAdvertisementToDuplicate] = useState(null);
    const [promotionCampaignNotificationToDuplicate, setPromotionCampaignNotificationToDuplicate] = useState(null);

    const createNewForm = location?.state?.action === FormRouteEnum.NEW;
    const disableForm = location?.state?.action === FormRouteEnum.VIEW;

    const promotionCampaignIdToDuplicate = location?.state?.promotionCampaignIdToDuplicate;
    const duplicateMode = !isEmptyObject(promotionCampaignIdToDuplicate);

    const fetchData = useCallback(() => {
        const id = promotionCampaignId || promotionCampaignIdToDuplicate;
        id && dispatch(getPromotionCampaignAction(id))
            .then(response => showErrorMessage(getPromotionCampaignNotificationData(response)));

        dispatch(getAllFoodPartnersAction())
            .then(response => showErrorMessage(getAllFoodPartnersNotificationData(response)));
        dispatch(getConstantsAction())
            .then(response => showErrorMessage(getConstantsNotificationData(response)));
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
        dispatch(getAllProductsAction())
            .then(response => showErrorMessage(getAllProductProductsNotificationData(response)));

        if (promotionCampaignIdToDuplicate) {
            dispatch(getPromotionCampaignNotificationAction(promotionCampaignIdToDuplicate))
                .then(response => {
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && setPromotionCampaignNotificationToDuplicate(response?.payload);
                });
            dispatch(getPromotionCampaignAdvertisementAction(promotionCampaignIdToDuplicate))
                .then(response => {
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && setPromotionCampaignAdvertisementToDuplicate(response?.payload);
                });
        }
    }, [dispatch, promotionCampaignId, promotionCampaignIdToDuplicate, setPromotionCampaignNotificationToDuplicate, setPromotionCampaignAdvertisementToDuplicate, showErrorMessage]);

    useEffect(() => {
        fetchData();

        return () => {
            dispatch(invalidateConstants());
            dispatch(invalidatePromotionCampaign());
        };
    }, [dispatch, fetchData]);

    const renderView = () => {
        if (
            getPromotionCampaign?.loading
            || getConstants?.loading
            || getAllPods?.loading
            || getAllProducts?.loading
            || getAllFoodPartners?.loading
        ) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        if (getConstants?.data && getAllPods?.data && getAllProducts?.data && getAllFoodPartners?.data && (!createNewForm ? getPromotionCampaign?.data : true)) {
            if (tab === 0) {
                return (
                    <PromotionCampaignForm
                        createNewForm={ createNewForm }
                        promotionCampaignId={ promotionCampaignId }
                        refreshData={ () => fetchData() }
                        duplicateMode={ duplicateMode }
                        promotionCampaignAdvertisementToDuplicate={ promotionCampaignAdvertisementToDuplicate }
                        promotionCampaignNotificationToDuplicate={ promotionCampaignNotificationToDuplicate }
                        disableForm={ disableForm }/>
                );
            }
            if (tab === 1) {
                return (
                    <PromotionCampaignAdvertisementForm
                        promotionCampaignId={ promotionCampaignId }
                        disableForm={ disableForm }/>
                )
            }
            if (tab === 2) {
                return (
                    <PromotionCampaignNotificationForm
                        promotionCampaignId={ promotionCampaignId }
                        disableForm={ disableForm }/>
                )
            }
        }

        return <></>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_PROMOTIONS, { state: { queryParams: location?.state?.queryParams, tab: location?.state?.tab } }) }>
                        Wróć
                    </Button>
                </Grid>
                <Grid container item>
                    <Tabs
                        value={ tab }
                        onChange={ (_, value) => setTab(value) }
                        variant="scrollable"
                        scrollButtons
                        sx={ {
                            [`& .${ tabsClasses.scrollButtons }`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        } }
                    >
                        <Tab label="Informacje ogólne" sx={ { justifyContent: 'flexEnd' } }/>
                        <Tab label="Grafiki" disabled={ createNewForm } sx={ { justifyContent: 'flexEnd' } }/>
                        <Tab label="Powiadomienia" disabled={ createNewForm } sx={ { justifyContent: 'flexEnd' } }/>
                    </Tabs>
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default PromotionCampaignFormsPage;
