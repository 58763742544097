import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';
import moment from 'moment/moment';
import deepmerge from 'deepmerge';

import { Button, DuplicateButton } from '../../../common/button';
import { GET_ROUTE_PROMOTION_CAMPAIGN_FORMS, MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT, ROUTE_PROMOTION_CAMPAIGN_FORMS_NEW } from '../../../../core/constants';
import { getPromotionCampaignsAction, getPromotionCampaignsState } from '../../../../features/price/get_promotion_campaigns';
import { getPromotionCampaignStatus } from '../../../common/utils/promotion_campaign_utils';
import { getPromotionCampaignsNotificationData } from '../../../../core/utils/notification_utils';
import useNotification from '../../../../core/hooks/use_notification';
import { getDefaultPaginationQueryValues } from '../../../../core/utils/api_utils';
import PaginationControls from '../../../common/pagination_controls';
import { FormRouteEnum } from '../../../../core/enums/common/route';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    sort: ['workingName', 'asc'],
    showArchived: true
};

const ArchivedPromotionCampaignList = ({ tab }) => {
    const { showErrorMessage } = useNotification();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const getPromotionCampaigns = useSelector(getPromotionCampaignsState);

    const [queryParams, setQueryParams] = useState(location?.state?.queryParams || initialQueryParams);

    const refreshPromotionCampaignList = useCallback(() => {
        dispatch(getPromotionCampaignsAction(queryParams))
            .then(response => showErrorMessage(getPromotionCampaignsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshPromotionCampaignList();

    }, [queryParams, refreshPromotionCampaignList]);

    return (
        <Grid container>
            <Grid container item mb={ 4 }>
                <Button onClick={ () => navigate(ROUTE_PROMOTION_CAMPAIGN_FORMS_NEW, { state: { action: FormRouteEnum.NEW, queryParams, tab } }) }>
                    Dodaj akcję promocyjną
                </Button>
            </Grid>
            {
                getPromotionCampaigns?.loading &&
                <Grid item width={ '100%' }>
                    <LinearProgress/>
                </Grid>
            }
            {
                getPromotionCampaigns?.data &&
                <Grid container>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nazwa robocza</TableCell>
                                    <TableCell align="center">Data obowiązywania</TableCell>
                                    <TableCell align="center">Powtarzalna</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Podgląd</TableCell>
                                    <TableCell align="center">Duplikuj</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getPromotionCampaigns?.data?.content?.map(promotionCampaign => (
                                        <TableRow
                                            key={ promotionCampaign?.id }
                                            hover
                                        >
                                            <TableCell>{ promotionCampaign?.workingName }</TableCell>
                                            <TableCell align="center">
                                                <Grid container>
                                                    <Grid container item justifyContent="center">
                                                        Od:&nbsp;
                                                        {
                                                            moment(promotionCampaign?.timeFrom, MISC_DATE_ISO_FORMAT)
                                                                .format(MISC_DATE_POLISH_FORMAT)
                                                        }
                                                    </Grid>
                                                    <Grid container item justifyContent="center">
                                                        Do:&nbsp;
                                                        {
                                                            moment(promotionCampaign?.timeTo, MISC_DATE_ISO_FORMAT)
                                                                .format(MISC_DATE_POLISH_FORMAT)
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    promotionCampaign?.repeatable
                                                        ? <CheckIcon color="success"/>
                                                        : <ClearIcon color="error"/>
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    getPromotionCampaignStatus(promotionCampaign?.status)
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                <SearchIcon onClick={ () => navigate(GET_ROUTE_PROMOTION_CAMPAIGN_FORMS(promotionCampaign?.id), { state: { action: FormRouteEnum.VIEW, queryParams, tab } }) } cursor="pointer"/>
                                            </TableCell>
                                            <TableCell align="center">
                                                <DuplicateButton onClick={ () => navigate(ROUTE_PROMOTION_CAMPAIGN_FORMS_NEW, { state: { action: FormRouteEnum.NEW, promotionCampaignIdToDuplicate: promotionCampaign?.id, queryParams, tab } }) }/>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getPromotionCampaigns?.data?.pageable?.pageNumber }
                            totalPages={ getPromotionCampaigns?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default ArchivedPromotionCampaignList;
