import React from 'react';
import { useSelector } from 'react-redux';

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment/moment';

import { MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT } from '../../../../../core/constants';
import { getFoodPartnerOrderHistoryState } from '../../../../../features/inventory/get_food_partner_order_history';
import { getFoodPartnerOrderStatus } from '../../../../common/utils/food_partner_order_utils';

const FoodPartnerOrderHistory = ({ onClickOrder }) => {
    const getFoodPartnerOrderHistory = useSelector(getFoodPartnerOrderHistoryState);

    return (
        <Grid container item>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Tytuł</TableCell>
                            <TableCell align="center">Data utworzenia</TableCell>
                            <TableCell align="center">Data zamówienia</TableCell>
                            <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            getFoodPartnerOrderHistory?.data?.content?.map((foodPartnerOrder, index) => (
                                <TableRow
                                    key={ foodPartnerOrder?.id }
                                    hover
                                    sx={ { cursor: 'pointer' } }
                                    onClick={ () => onClickOrder(index) }
                                >
                                    <TableCell>{ foodPartnerOrder?.title }</TableCell>
                                    <TableCell align="center">
                                        {
                                            moment(foodPartnerOrder?.createDate, MISC_DATE_ISO_FORMAT)
                                                .format(MISC_DATE_POLISH_FORMAT)
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            moment(foodPartnerOrder?.orderDate, MISC_DATE_ISO_FORMAT)
                                                .format(MISC_DATE_POLISH_FORMAT)
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            getFoodPartnerOrderStatus(foodPartnerOrder?.status)
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default FoodPartnerOrderHistory;
