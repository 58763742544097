import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { getPodsState } from '../../../features/pod/get_pods';

const PodsList = ({ route }) => {
    const getPods = useSelector(getPodsState);
    const navigate = useNavigate();

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Partner</TableCell>
                        <TableCell>Numer</TableCell>
                        <TableCell>Adres</TableCell>
                        <TableCell align="center">Typ</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getPods?.data?.content?.map(pod => (
                            <TableRow
                                key={ pod?.id }
                                hover
                                sx={ { cursor: 'pointer' } }
                                onClick={ () => navigate(route(pod?.id)) }
                            >
                                <TableCell>
                                    {
                                        pod?.indoorPartner?.name
                                    }
                                </TableCell>
                                <TableCell>{ pod?.serviceId }</TableCell>
                                <TableCell>
                                    {
                                        pod?.address
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        pod?.locationType
                                    }
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PodsList;
