import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useNotification from '../../../core/hooks/use_notification';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { getDefaultPaginationQueryValues } from '../../../core/utils/api_utils';
import deepmerge from 'deepmerge';
import PaginationControls from '../../common/pagination_controls';
import { Button } from '../../common/button';
import { getProducersAction, getProducersState } from "../../../features/product/get_producers";
import { getProducersNotificationData } from "../../../core/utils/notification_utils";
import { ROUTE_PRODUCER_FORMS_NEW } from "../../../core/constants";
import ProducerFilters from "./filters";
import ProducersList from "./producers_list";

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    query: ''
};

const ProducersPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showErrorMessage } = useNotification();

    const getProducers = useSelector(getProducersState);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const refreshList = useCallback(() => {
        dispatch(getProducersAction(queryParams))
            .then(response => showErrorMessage(getProducersNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [dispatch, queryParams, refreshList, showErrorMessage]);

    return (
        <Grid>
            <Box mb={ 4 }>
                <Typography variant="h1">Producenci</Typography>
            </Box>
            <Box mb={ 4 }>
                <ProducerFilters
                    onClear={ () => setQueryParams(prevState => deepmerge(prevState, { query: '' })) }
                    onSearchInputChange={ query => setQueryParams(prevState => deepmerge(prevState, { query })) }
                />
            </Box>
            <Box mb={ 2 }>
                <Button onClick={ () => navigate(ROUTE_PRODUCER_FORMS_NEW) }>
                    Dodaj producenta
                </Button>
            </Box>
            {
                getProducers?.loading &&
                <LinearProgress/>
            }
            {
                getProducers?.data?.content &&
                <Box mb={ 4 }>
                    <ProducersList
                        refreshList={ refreshList }/>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getProducers?.data?.pageable?.pageNumber }
                            totalPages={ getProducers?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Box>
            }
        </Grid>
    );
};

export default ProducersPage;
