import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, Typography } from '@mui/material';

import { TileButton } from '../../common/button';
import { updatePricesNotificationData } from '../../../core/utils/notification_utils';
import { updatePricesAction, updatePricesState } from '../../../features/price/update_prices';
import useNotification from '../../../core/hooks/use_notification';

const PricesPage = () => {
    const dispatch = useDispatch();
    const updatePrices = useSelector(updatePricesState);
    const { showNotification } = useNotification();

    const onSubmit = () => {
        dispatch(updatePricesAction())
            .then(response => showNotification(updatePricesNotificationData(response)));
    };

    return (
        <Grid>
            <Box mb={ 4 }>
                <Typography variant="h1">Ceny</Typography>
            </Box>
            <Grid mb={ 4 } container item justifyContent="center">
                <TileButton onClick={ onSubmit } isLoading={ updatePrices?.loading }>
                    Zaktualizuj ceny
                </TileButton>
            </Grid>
        </Grid>
    );
};

export default PricesPage;