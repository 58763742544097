import { combineReducers } from '@reduxjs/toolkit';

import getPod from './get_pod';
import getPods from './get_pods';
import getAllPods from './get_all_pods';
import getAdvertisements from './get_advertisements';
import createAdvertisements from './create_advertisements';
import updateAdvertisementsDisplayOrder from './update_advertisements_display_order';
import updateAdvertisementDisplayTime from './update_advertisement_display_time';
import updateAdvertisementProducts from './update_advertisement_products';
import deleteAdvertisement from './delete_advertisement';
import { webClientFileDownload } from '../../core/web_client';
import { extractResponseData } from '../../core/utils/api_utils';

export const getAdvertisementFile = async (fileUrl) => {
    const response = await webClientFileDownload.get(fileUrl);
    return window.URL.createObjectURL(new Blob([extractResponseData(response)]));
};

export default combineReducers({
    getPod, getPods, getAllPods, getAdvertisements, createAdvertisements, updateAdvertisementsDisplayOrder,
    updateAdvertisementDisplayTime, updateAdvertisementProducts, deleteAdvertisement
});
