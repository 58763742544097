import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useNotification from '../../../../../core/hooks/use_notification';
import { deletePromotionCampaignAdvertisementNotificationData, getPromotionCampaignAdvertisementNotificationData, updatePromotionCampaignAdvertisementNotificationData } from '../../../../../core/utils/notification_utils';
import * as Yup from 'yup';
import { Box, CircularProgress, FormHelperText, Grid, LinearProgress, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { SubmitButton } from '../../../../common/button';
import RadioGroupFormik from '../../../../common/radio_group';
import { yesNoOptions } from '../../../../common/form_utils';
import { deletePromotionCampaignAdvertisementAction, deletePromotionCampaignAdvertisementState } from '../../../../../features/price/delete_promotion_campaign_advertisement';
import { updatePromotionCampaignAdvertisementAction, updatePromotionCampaignAdvertisementState } from '../../../../../features/price/update_promotion_campaign_advertisement';
import { getPromotionCampaignAdvertisementAction, getPromotionCampaignAdvertisementState } from '../../../../../features/price/get_promotion_campaign_advertisement';
import FileInput from '../../../../common/file_input';
import { isNullOrUndefined } from '../../../../../core/utils/misc_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { getAdvertisementFileAsBlob } from '../../../../../features/price';

const PromotionCampaignAdvertisementForm = ({ promotionCampaignId, disableForm }) => {
    const { showErrorMessage, showNotification } = useNotification();
    const dispatch = useDispatch();

    const getPromotionCampaignAdvertisement = useSelector(getPromotionCampaignAdvertisementState);
    const updatePromotionCampaignAdvertisement = useSelector(updatePromotionCampaignAdvertisementState);
    const deletePromotionCampaignAdvertisement = useSelector(deletePromotionCampaignAdvertisementState);

    const [picture, setPicture] = useState(null);
    const [pictureLoading, setPictureLoading] = useState(false);

    useEffect(() => {
        dispatch(getPromotionCampaignAdvertisementAction(promotionCampaignId))
            .then(response => {
                showErrorMessage(getPromotionCampaignAdvertisementNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && response?.payload?.hasAdvertisement) {
                    setPictureLoading(true);
                    getAdvertisementFileAsBlob(response?.payload?.fileUrl)
                        .then(file => setPicture(file))
                        .finally(() => setPictureLoading(false));
                }
            });
    }, [dispatch, showErrorMessage, promotionCampaignId]);


    const initialValues = {
        hasAdvertisement: getPromotionCampaignAdvertisement?.data?.hasAdvertisement || false,
        picture: null
    };

    const schema = Yup.object().shape({
        hasAdvertisement: Yup.bool().required(),
        picture: Yup.mixed().when('hasAdvertisement', {
            is: true,
            then: Yup.mixed().required(),
            otherwise: Yup.mixed().notRequired()
        }),
    });

    const onAttach = (file, formik) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);

        image.onload = () => {
            const height = image.height;
            const width = image.width;

            if (height * 9 / 16 !== width) {
                const errors = {
                    ...formik.errors,
                    picture: 'Plik powinien mieć proporcje 9:16'
                };

                formik.setErrors(errors);
            } else {
                let reader = new FileReader();
                reader.onloadend = () => setPicture(reader.result);
                reader.readAsDataURL(file);

                formik.setFieldValue('picture', file);
            }
        };
    };

    const onRemove = (formik) => {
        formik.setFieldValue('picture', null);
        setPicture(null);
    };

    const onSubmit = (values) => {
        if (disableForm) return;

        if (values.hasAdvertisement) {
            const picture = values.picture;

            dispatch(updatePromotionCampaignAdvertisementAction({ picture, promotionCampaignId }))
                .then(response => {
                    showNotification(updatePromotionCampaignAdvertisementNotificationData(response));
                });
        } else {
            dispatch(deletePromotionCampaignAdvertisementAction(promotionCampaignId))
                .then(response => {
                    showNotification(deletePromotionCampaignAdvertisementNotificationData(response));
                    if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                        setPicture(null);
                    }
                });
        }
    };

    return (
        <>
            {
                getPromotionCampaignAdvertisement?.loading &&
                <Grid item width={ '100%' }>
                    <LinearProgress/>
                </Grid>
            }
            {
                getPromotionCampaignAdvertisement?.data &&
                <Formik
                    initialValues={ initialValues }
                    validationSchema={ schema }
                    validateOnChange={ false }
                    validateOnBlur={ false }
                    onSubmit={ onSubmit }>
                    { (formik) =>
                        <Grid component={ Form } container rowGap={ 4 }>
                            <Grid container item>
                                <RadioGroupFormik
                                    name="hasAdvertisement"
                                    label="Wyświetlaj reklamę"
                                    options={ yesNoOptions }
                                    disabled={ disableForm }
                                />
                            </Grid>
                            {
                                formik.values.hasAdvertisement &&
                                <Grid container item md={ 12 } lg={ 6 } direction="column" rowGap={ 4 }>
                                    <Grid container item justifyContent="center">
                                        {
                                            pictureLoading &&
                                            <Box sx={ { display: 'flex' } }>
                                                <CircularProgress/>
                                            </Box>
                                        }
                                        {
                                            !pictureLoading &&
                                            <>
                                                {
                                                    picture ?
                                                        <img src={ picture }
                                                             alt="Nie udało się wczytać zdjęcia reklamy"
                                                             width={ 280 }
                                                             height={ 500 }/>
                                                        : <Typography width={ 400 } textAlign="center" variant="h6">Brak
                                                            reklamy</Typography>
                                                }
                                            </>
                                        }
                                    </Grid>
                                    {
                                        !disableForm &&
                                        <Grid container item>
                                            <FileInput
                                                label="Zdjęcie reklamy"
                                                text="Dodaj lub przeciągnij plik png/jpg"
                                                errorMessage="Niepoprawny plik. Wymagany png lub jpg."
                                                validate={ file => ['image/png', 'image/jpeg'].includes(file.type) }
                                                isAttached={ !isNullOrUndefined(formik.values.attachedFile) }
                                                onAttach={ (file) => onAttach(file, formik) }
                                                onRemove={ () => onRemove(formik) }
                                            />
                                            {
                                                formik?.errors?.picture &&
                                                <FormHelperText error>
                                                    { formik?.errors?.picture }
                                                </FormHelperText>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            }

                            <Grid container item mt={ 2 } justifyContent="flex-end">
                                <SubmitButton
                                    isLoading={ updatePromotionCampaignAdvertisement?.loading || deletePromotionCampaignAdvertisement?.loading }>
                                    Zapisz
                                </SubmitButton>
                            </Grid>
                        </Grid>
                    }
                </Formik>
            }
        </>
    );
};

export default PromotionCampaignAdvertisementForm;