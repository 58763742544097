import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';

import { Button } from '../../../../common/button';
import { ROUTE_FOOD_PARTNERS_ORDER } from '../../../../../core/constants';
import ConfirmDialog from '../../../common/confirm_dialog';

const Buttons = ({ isLoading, onSave, displaySaveAction, displaySaveAndSendAction, mailAlreadySent, onGenerate, displayGenerateWarehouseDocumentAction }) => {
    const navigate = useNavigate();

    return (
        <Grid container item mt={ 2 } justifyContent="space-between">
            <Grid item>
                <Button onClick={ () => navigate(ROUTE_FOOD_PARTNERS_ORDER) }>Wróć</Button>
            </Grid>
            <Grid item>
                <Grid container item gap={ 2 }>
                    {
                        displaySaveAction &&
                        <ConfirmDialog
                            isLoading={ isLoading }
                            title={ 'Wygenerować paczkę naklejek?' }
                            secondaryAction={ () => onSave(false, false) }
                            secondaryText={ 'Nie pobieraj' }
                            primaryAction={ () => onSave(false, true) }
                            primaryText={ 'Pobierz' }
                            displayIcon={ false }
                            buttonText={ 'Zapisz' }/>
                    }
                    {
                        displaySaveAndSendAction &&
                        <ConfirmDialog
                            isLoading={ isLoading }
                            maxWidth={ 'md' }
                            title={ mailAlreadySent ?
                                'Czy na pewno chcesz ponownie wysłać maila do Dostawcy? Pamiętaj, żeby wysyłać maila tylko przy aktualizacji zamówienia. Możesz uwzględnić paczkę z naklejkami w mailu z aktualizacją, lub wysłać ją bez naklejek.' :
                                'Czy chcesz uwzględnić paczkę z naklejkami w mailu z zamówieniem?' }
                            cancelText={ 'Anuluj' }
                            secondaryAction={ () => onSave(true, false) }
                            secondaryText={ 'Zapisz i wyślij bez naklejek' }
                            primaryAction={ () => onSave(true, true) }
                            primaryText={ 'Zapisz i wyślij z naklejkami' }
                            displayIcon={ false }
                            buttonText={ 'Zapisz i wyślij' }/>
                    }
                    {
                        displayGenerateWarehouseDocumentAction &&
                        <ConfirmDialog
                            isLoading={ isLoading }
                            title={ 'Wygenerować paczkę naklejek?' }
                            secondaryAction={ () => onGenerate(false) }
                            secondaryText={ 'Nie pobieraj' }
                            primaryAction={ () => onGenerate(true) }
                            primaryText={ 'Pobierz' }
                            displayIcon={ false }
                            buttonText={ 'Przyjmij na magazyn' }/>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Buttons;
