import React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import { Button } from '../../../../common/button';
import { getFoodPartnerState } from '../../../../../features/food_partner/get_food_partner';
import ConfirmDialog from '../../../common/confirm_dialog';

const SummaryButtons = ({ onReturn, isLoading, onSave, onSaveAndSend }) => {
    const getFoodPartner = useSelector(getFoodPartnerState);

    return (
        <Grid container item mt={ 2 } justifyContent="space-between">
            <Grid item>
                <Button onClick={ () => onReturn() }>Wróć</Button>
            </Grid>
            <Grid item>
                <Grid container item gap={ 2 }>
                    <ConfirmDialog
                        isLoading={ isLoading }
                        title={ 'Wygenerować paczkę naklejek?' }
                        secondaryAction={ () => onSave(false) }
                        secondaryText={ 'NIe pobieraj' }
                        primaryAction={ () => onSave(true) }
                        primaryText={ 'Wygeneruj' }
                        displayIcon={ false }
                        buttonText={ 'Zapisz' }/>
                    {
                        getFoodPartner?.data?.containsContactPersons &&
                        <ConfirmDialog
                            isLoading={ isLoading }
                            maxWidth={ 'md' }
                            title={ 'Czy chcesz uwzględnić paczkę z naklejkami w mailu z zamówieniem?' }
                            secondaryAction={ () => onSaveAndSend(false) }
                            secondaryText={ 'Zapisz i wyślij bez naklejek' }
                            primaryAction={ () => onSaveAndSend(true) }
                            primaryText={ 'Zapisz i wyślij z naklejkami' }
                            displayIcon={ false }
                            buttonText={ 'Zapisz i wyślij' }/>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SummaryButtons;