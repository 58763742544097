import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { Divider, Grid } from '@mui/material';
import { Form, Formik } from 'formik';

import { getEmployerDiscountState } from '../../../../../features/benefit/get_employer_discount';
import useNotification from '../../../../../core/hooks/use_notification';
import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import TextFieldFormik from '../../../../common/text_field';
import { SelectFieldFormik, SelectFieldMultipleFormik } from '../../../../common/select_field';
import { SubmitButton } from '../../../../common/button';
import { deleteEmployerDiscountAction, deleteEmployerDiscountState } from '../../../../../features/benefit/delete_employer_discount';
import { deleteEmployerDiscountNotificationData, updateEmployerDiscountNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { updateEmployerDiscountAction, updateEmployerDiscountState } from '../../../../../features/benefit/update_employer_discount';

const discountTypeOptions = [
    { value: 'PERCENTAGE', label: 'Procentowa' },
    { value: 'AMOUNT', label: 'Kwotowa' },
];

const limitTypeOptions = [
    { value: 'NO_LIMIT', label: 'Bez limitu' },
    { value: 'DAILY_LIMIT', label: 'Limit dzienny' },
    { value: 'WEEKLY_LIMIT', label: 'Limit tygodniowy' },
];

const DiscountForm = ({ employerId, refreshDiscount }) => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();

    const getAllPods = useSelector(getAllPodsState);
    const getEmployerDiscount = useSelector(getEmployerDiscountState);
    const updateEmployerDiscount = useSelector(updateEmployerDiscountState);
    const deleteEmployerDiscount = useSelector(deleteEmployerDiscountState);

    const initialValues = {
        podIds: getEmployerDiscount?.data?.podIds || [],
        discountType: getEmployerDiscount?.data?.discountType || '',
        discount: getEmployerDiscount?.data?.discount || '',
        limitType: getEmployerDiscount?.data?.limitType || ''
    };

    const schema = Yup.object().shape({
        podIds: Yup.array().min(1),
        discountType: Yup.string().required(),
        discount: Yup.number().required(),
        limitType: Yup.string().required()
    });

    const onLimitTypeChange = (value, formik) => {
        if (value === limitTypeOptions[0].value) {
            formik.setFieldValue('discountType', discountTypeOptions[0].value);
        } else {
            formik.setFieldValue('discountType', discountTypeOptions[1].value);
        }
    };

    const onDeleteEmployerDiscount = () => {
        dispatch(deleteEmployerDiscountAction(employerId))
            .then(response => {
                showNotification(deleteEmployerDiscountNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshDiscount();
            });
    };

    const onSubmit = (values) => {
        dispatch(updateEmployerDiscountAction({ employerId, form: { ...values } }))
            .then(response => {
                showNotification((updateEmployerDiscountNotificationData(response)));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshDiscount();
            });
    };

    return (
        <Grid container rowGap={ 4 }>
            <Grid container item>
                <SubmitButton onClick={ () => onDeleteEmployerDiscount() } isLoading={ deleteEmployerDiscount?.loading }>
                    Usuń obniżkę
                </SubmitButton>
            </Grid>
            <Grid container item>
                <Divider style={ { width: '100%' } }/>
            </Grid>
            <Formik
                initialValues={ initialValues }
                validationSchema={ schema }
                validateOnChange={ false }
                validateOnBlur={ false }
                onSubmit={ onSubmit }
            >
                { (formik) =>
                    <Grid component={ Form } container rowGap={ 4 }>
                        <Grid container item gap={ 4 }>
                            <Grid container item gap={ 4 }>
                                <Grid item xs={ 3 }>
                                    <SelectFieldFormik
                                        name="limitType"
                                        label="Typ limitu"
                                        options={ limitTypeOptions }
                                        required
                                        onChange={ (value) => onLimitTypeChange(value, formik) }
                                    />
                                </Grid>
                                <Grid item>
                                    <TextFieldFormik
                                        name="discount"
                                        label="Obniżka"
                                        type="number"
                                        required
                                        inputProps={ { min: '0.01', max: '100.00', step: '0.01' } }/>
                                </Grid>
                                <Grid item xs={ 3 }>
                                    <SelectFieldFormik
                                        name="discountType"
                                        label="Typ obniżki"
                                        options={ discountTypeOptions }
                                        required
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={ 10 }>
                                <SelectFieldMultipleFormik
                                    name="podIds"
                                    label="Wybierz pody - wiele"
                                    options={ getAllPods?.data?.map(pod => ({ value: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + pod?.serviceId })) }
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <SubmitButton isLoading={ updateEmployerDiscount?.loading }>Zapisz</SubmitButton>
                        </Grid>
                    </Grid>
                }
            </Formik>
        </Grid>
    );
};

export default DiscountForm;
