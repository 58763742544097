import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, LinearProgress, Stack } from '@mui/material';
import deepmerge from 'deepmerge';

import useNotification from '../../../../core/hooks/use_notification';
import { Button } from '../../../common/button';
import { ROUTE_PROMOTIONS } from '../../../../core/constants';
import { getDefaultPaginationQueryValues } from '../../../../core/utils/api_utils';
import { getPodsNotificationData, getPriceNotificationData, getProductNotificationData, getProductPromotionsNotificationData } from '../../../../core/utils/notification_utils';
import PaginationControls from '../../../common/pagination_controls';
import { getAllPodsAction, getAllPodsState } from '../../../../features/pod/get_all_pods';
import PromotionsFormDialog from './form/promotions_form_dialog';
import DeletePromotionsFormDialog from './form/delete_promotions_form_dialog';
import { getPriceAction, getPriceState } from '../../../../features/price/get_price';
import { getProductPromotionsAction, getProductPromotionsState, invalidateProductPromotions, } from '../../../../features/price/get_product_promotions';
import ProductPromotionsFilters from './product_promotions_filters';
import ProductPromotionsList from './product_promotions_list';
import { getProductAction, getProductState } from '../../../../features/product/get_product';
import Item from '../../../common/item';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    podId: '',
};

const ProductPromotionsPage = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();
    const { productId } = useParams();
    const location = useLocation();

    const getProduct = useSelector(getProductState)
    const getProductPromotions = useSelector(getProductPromotionsState);
    const getAllPods = useSelector(getAllPodsState);
    const getPrice = useSelector(getPriceState);

    const [queryParams, setQueryParams] = useState({ ...initialQueryParams, productId });

    const refreshList = useCallback(() => {
        dispatch(getProductPromotionsAction(queryParams))
            .then(response => showErrorMessage(getProductPromotionsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();

        return () => {
            dispatch(invalidateProductPromotions());
        };
    }, [dispatch, refreshList]);

    useEffect(() => {
        dispatch(getProductAction({ id: productId }))
            .then(respone => showErrorMessage(getProductNotificationData(respone)))
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
        dispatch(getPriceAction(productId))
            .then(response => showErrorMessage(getPriceNotificationData(response)));
    }, [dispatch, productId, showErrorMessage]);

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid item>
                    <Button onClick={ () => navigate(ROUTE_PROMOTIONS, { state: { queryParams: location?.state?.queryParams, tab: location?.state?.tab } }) }>
                        Wróć
                    </Button>
                </Grid>
                {
                    (getProduct?.loading || getAllPods?.loading || getPrice?.loading) &&
                    <LinearProgress/>
                }
                {
                    getProduct?.data &&
                    <Item>
                        <Stack direction={'row'} spacing={2}>
                            <span><b>Promocja na towar</b></span>
                            <span>{getProduct?.data.name}</span>
                        </Stack>
                    </Item>
                }
                <Grid container item>
                    {
                        (getAllPods?.data && getPrice?.data) &&
                        <Grid container item rowGap={ 2 }>
                            <ProductPromotionsFilters
                                onSelectPodChange={ podId => setQueryParams(prevState => deepmerge(prevState, { podId })) }
                            />
                            <Grid container item columnGap={ 2 } rowGap={ 2 }>
                                <Grid item>
                                    <PromotionsFormDialog
                                        productId={ productId }
                                        purchasePrice={ getProduct?.data?.purchaseGrossPrice }
                                        refreshList={ refreshList }
                                    />
                                </Grid>
                                <Grid item>
                                    <DeletePromotionsFormDialog
                                        productId={ productId }
                                        refreshList={ refreshList }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
            {
                (getProductPromotions?.loading || getAllPods?.loading) &&
                <LinearProgress/>
            }
            {
                (getProductPromotions?.data?.content && getAllPods?.data) &&
                <Grid container>
                    <ProductPromotionsList
                        refreshList={ refreshList }/>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getProductPromotions?.data?.pageable?.pageNumber }
                            totalPages={ getProductPromotions?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default ProductPromotionsPage;