import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import { FormHelperText, Grid, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import DialogTitle from '@mui/material/DialogTitle';

import { Button, SubmitButton } from '../../../common/button';
import FileInput from '../../../common/file_input';
import { isNullOrUndefined } from '../../../../core/utils/misc_utils';
import { SelectFieldFormik, SelectFieldMultipleFormik } from '../../../common/select_field';
import { getAllPodsState } from '../../../../features/pod/get_all_pods';
import { createAdvertisementsAction, createAdvertisementsState } from '../../../../features/pod/create_advertisements';
import useNotification from '../../../../core/hooks/use_notification';
import RadioGroupFormik from '../../../common/radio_group';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { createAdvertisementsNotificationData, getAllProductProductsNotificationData } from '../../../../core/utils/notification_utils';
import { yesNoOptions } from '../../../common/form_utils';
import { DateTimePickerFormik } from '../../../common/date_picker';
import { parseDateString } from '../../../../core/utils/date_utils';
import { MISC_DATE_ISO_FORMAT } from '../../../../core/constants';
import { getAllProductsAction } from "../../../../features/product/get_all_products";
import { AutocompleteMultipleSearchOnTypeFormik } from "../../../common/autocomplete";

const AdvertisementsFormDialog = ({ podId, advertisementCount, refreshList }) => {
    const { showNotification, showErrorMessage } = useNotification();
    const dispatch = useDispatch();

    const getAllPods = useSelector(getAllPodsState);
    const createAdvertisements = useSelector(createAdvertisementsState);

    const mapProductsOptions = useCallback((data) => {
        return data?.map(product => ({
            key: product?.id + Math.random(),
            value: product?.id,
            label: product?.name
        }));
    }, []);

    const [open, setOpen] = useState(false);
    const [picture, setPicture] = useState(null);
    const [productsOptions, setProductsOptions] = useState([]);
    const [loadingProductsOptions, setLoadingProductsOptions] = useState(false);

    const orderOptions = useCallback(() => {
        if (_.isNumber(advertisementCount)) {
            const result = _.times(advertisementCount, num => num + 1);
            result.push(advertisementCount + 1);

            return result?.map(order => ({
                key: order,
                value: order,
                label: order
            }));
        } else {
            return [
                {
                    key: 1,
                    value: 1,
                    label: 'Pierwszy'
                },
                {
                    key: -1,
                    value: -1,
                    label: 'Ostatni'
                }
            ];
        }
    }, [advertisementCount]);

    const initialValues = {
        podIds: !!podId ? [podId] : [],
        displayOrder: !podId ? 1 : '',
        withDates: false,
        displayTimeFrom: '',
        displayTimeTo: '',
        products: [],
        picture: null
    };

    const schema = Yup.object().shape({
        displayOrder: Yup.number().required(),
        podIds: Yup.array().min(!podId ? 2 : 1),
        displayTimeFrom: Yup.date().when('withDates', {
            is: true,
            then: Yup.date()
                .transform(parseDateString)
                .nullable()
                .required(),
            otherwise: Yup.date()
                .transform(parseDateString)
                .nullable()
                .notRequired()
        }),
        displayTimeTo: Yup.date().when('withDates', {
            is: true,
            then: Yup.date()
                .transform(parseDateString)
                .nullable()
                .required(),
            otherwise: Yup.date()
                .transform(parseDateString)
                .nullable()
                .notRequired()
        }),
        products: Yup.array(),
        picture: Yup.mixed().required()
    });

    const onOpen = () => {
        setOpen(true);
        setProductsOptions([]);
    }

    const onClose = () => {
        setOpen(false);
        setPicture(null);
    };

    const onAttach = (file, formik) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);

        image.onload = () => {
            const height = image.height;
            const width = image.width;

            if (height * 9 / 16 !== width) {
                const errors = {
                    ...formik.errors,
                    picture: 'Plik powinien mieć proporcje 9:16'
                };

                formik.setErrors(errors);
            } else {
                let reader = new FileReader();
                reader.onloadend = () => setPicture(reader.result);
                reader.readAsDataURL(file);

                formik.setFieldValue('picture', file);
            }
        };
    };

    const onRemove = (formik) => {
        formik.setFieldValue('picture', null);
        setPicture(null);
    };

    const onProductsInputChange = (query, formik) => {
        if (query.length >= 3) {
            setProductsOptions([]);
            setLoadingProductsOptions(true);

            getProductsDebounced(query, formik);
        }
    };

    const getProductsDebounced = _.debounce((query, formik) => {
        dispatch(getAllProductsAction({ query }))
            .then(response => {
                showErrorMessage(getAllProductProductsNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    const newProductsOptions = mapProductsOptions(response?.payload).concat(formik.values.products);
                    const newDistinctProductsOptions = [...new Map(newProductsOptions.map((option) => [option['value'], option])).values()];
                    setProductsOptions(newDistinctProductsOptions);
                }
                setLoadingProductsOptions(false);
            });
    }, 600)

    const onSubmit = (values) => {
        const displayTimeFrom = !values.withDates ? undefined : values?.displayTimeFrom?.format(MISC_DATE_ISO_FORMAT);
        const displayTimeTo = !values.withDates ? undefined : values?.displayTimeTo?.format(MISC_DATE_ISO_FORMAT);
        const productIds = _.pick(values, ['products'])?.products?.map(product => product?.value) || [];

        const form = {
            ..._.omit(values, [
                'picture',
                (!podId && values.displayOrder !== 1) && 'displayOrder',
                'displayTimeFrom',
                'displayTimeTo',
                'products',
                'withDates'
            ]),
            displayTimeFrom,
            displayTimeTo,
            productIds
        };
        const picture = values.picture;

        dispatch(createAdvertisementsAction({ form, picture }))
            .then(response => {
                showNotification(createAdvertisementsNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    onClose();
                    refreshList && refreshList();
                }
            });
    };

    return (
        <>
            <Button onClick={ onOpen }>
                Dodaj reklamę
            </Button>
            <Dialog open={ open } onClose={ onClose } fullWidth={ true } maxWidth={ 'lg' }>
                <DialogTitle>Reklama</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={ schema }
                        validateOnChange={ false }
                        validateOnBlur={ false }
                        onSubmit={ onSubmit }>
                        { (formik) =>
                            <Grid component={ Form } container rowGap={ 4 }>
                                <Grid container item md={ 12 } lg={ 6 } direction="column" rowGap={ 4 }>
                                    <Grid container item justifyContent="center">
                                        { picture
                                            ? <img src={ picture }
                                                   alt="Nie udało się wczytać zdjęcia reklamy"
                                                   width={ 280 }
                                                   height={ 500 }/>
                                            : <Typography width={ 400 } textAlign="center" variant="h6">Brak zdjęcia</Typography>
                                        }
                                    </Grid>
                                    <Grid container item>
                                        <FileInput
                                            label="Zdjęcie reklamy"
                                            text="Dodaj lub przeciągnij plik png/jpg"
                                            errorMessage="Niepoprawny plik. Wymagany png lub jpg."
                                            validate={ file => ['image/png', 'image/jpeg'].includes(file.type) }
                                            isAttached={ !isNullOrUndefined(formik.values.attachedFile) }
                                            onAttach={ (file) => onAttach(file, formik) }
                                            onRemove={ () => onRemove(formik) }
                                        />
                                        {
                                            formik?.errors?.picture &&
                                            <FormHelperText error>
                                                { formik?.errors?.picture }
                                            </FormHelperText>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container item md={ 12 } lg={ 6 } rowGap={ 2 } alignContent="space-between">
                                    <Grid container item rowGap={ 2 }>
                                        <Grid container item>
                                            <SelectFieldMultipleFormik
                                                name="podIds"
                                                label="Wybierz pody - wiele"
                                                options={ getAllPods?.data?.map(pod => ({ value: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + pod?.serviceId })) }
                                                disabled={ !!podId }
                                                required={ !podId }
                                            />
                                        </Grid>
                                        <Grid container item>
                                            <Grid item xs={ 6 }>
                                                <SelectFieldFormik
                                                    name="displayOrder"
                                                    label="Wybierz kolejność"
                                                    options={ orderOptions() }
                                                    required
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item>
                                            <RadioGroupFormik
                                                name="withDates"
                                                label="Z datami obowiązywania"
                                                options={ yesNoOptions }
                                                onChange={ (value) => {
                                                    if (value === true) {
                                                        formik.setFieldValue('displayTimeFrom', '');
                                                        formik.setFieldValue('displayTimeTo', '');
                                                    }
                                                } }
                                            />
                                        </Grid>
                                        {
                                            formik.values.withDates === true &&
                                            <Grid container item>
                                                <Grid item xs={ 6 }>
                                                    <DateTimePickerFormik
                                                        name="displayTimeFrom"
                                                        label="Data od"
                                                        required={ formik.values.withDates === true }
                                                        InputLabelProps={ { shrink: true } }
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        {
                                            formik.values.withDates === true &&
                                            <Grid container item>
                                                <Grid item xs={ 6 }>
                                                    <DateTimePickerFormik
                                                        name="displayTimeTo"
                                                        label="Data do"
                                                        required={ formik.values.withDates === true }
                                                        InputLabelProps={ { shrink: true } }
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid container item md={ 12 } rowGap={ 2 }>
                                            <AutocompleteMultipleSearchOnTypeFormik
                                                options={ productsOptions }
                                                onInputChange={ (value) => onProductsInputChange(value, formik) }
                                                name="products"
                                                label="Produkty"
                                                loading={ loadingProductsOptions }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item mt={ 2 } justifyContent="flex-end">
                                        <DialogActions sx={ { gap: 2 } }>
                                            <Button onClick={ onClose }>Zamknij</Button>
                                            <SubmitButton isLoading={ createAdvertisements?.loading }>Zapisz</SubmitButton>
                                        </DialogActions>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AdvertisementsFormDialog;
