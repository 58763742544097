import React from 'react';

import { FormHelperText, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FieldArray } from 'formik';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import TextFieldFormik from '../../../../common/text_field';
import { isEmptyObject } from '../../../../../core/utils/misc_utils';
import DatePickerFormik from '../../../../common/date_picker';

const ProductsList = ({ formik, productsListIndex, withExpirationDate, disabled }) => {
    const onRemoveProduct = (position) => {
        const productsList = formik?.values?.productsList[productsListIndex];
        const index = productsList?.findIndex(product => product?.position === position);

        if (index !== -1) {
            productsList.splice(index, 1);
            formik.setFieldValue(`productsList.${ productsListIndex }`, productsList);
        }
    };

    return (
        <FieldArray
            name={ `productsList.${ productsListIndex }` }
            render={ () => (
                <Grid container gap={ 2 }>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={ { width: '5%' } }>Lp.</TableCell>
                                    <TableCell>Nazwa</TableCell>
                                    <TableCell align="center" style={ { width: '12%' } }>Ilość szt.</TableCell>
                                    {
                                        withExpirationDate &&
                                        <TableCell align="center" style={ { width: '15%' } }>Data przydatności</TableCell>
                                    }
                                    <TableCell align="center" style={ { width: '15%' } }>Cena</TableCell>
                                    {
                                        !disabled &&
                                        <TableCell align="center" style={ { width: '5%' } }>Usuń</TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    formik?.values?.productsList[productsListIndex]?.map((product, index) => (
                                        <TableRow
                                            key={ index }
                                            hover
                                        >
                                            <TableCell>{ index + 1 }</TableCell>
                                            <TableCell>
                                                <TextFieldFormik
                                                    name={ `productsList.${ productsListIndex }.${ index }.name` }
                                                    disabled/>
                                            </TableCell>
                                            <TableCell align="center">
                                                <TextFieldFormik
                                                    name={ `productsList.${ productsListIndex }.${ index }.quantity` }
                                                    type="number"
                                                    inputProps={ { min: '1', step: '1' } }
                                                    style={ { background: 'white' } }
                                                    disabled={ disabled }/>
                                                <FormHelperText error>
                                                    <span>
                                                        {
                                                            !isEmptyObject(formik?.errors?.products?.[productsListIndex]?.[index]?.quantity) &&
                                                            formik?.errors?.products?.[productsListIndex]?.[index]?.quantity
                                                        }
                                                    </span>
                                                </FormHelperText>
                                            </TableCell>
                                            {
                                                withExpirationDate &&
                                                <TableCell align="center">
                                                    <DatePickerFormik
                                                        name={ `productsList.${ productsListIndex }.${ index }.expirationDate` }
                                                        InputLabelProps={ { shrink: true } }
                                                        style={ { background: 'white', width: 150 } }
                                                        disabled={ disabled }
                                                    />
                                                    <FormHelperText error>
                                                        <span>
                                                            {
                                                                !isEmptyObject(formik?.errors?.products?.[productsListIndex]?.[index]?.expirationDate) &&
                                                                formik?.errors?.products?.[productsListIndex]?.[index]?.expirationDate
                                                            }
                                                        </span>
                                                    </FormHelperText>
                                                </TableCell>
                                            }
                                            <TableCell align="center">
                                                <TextFieldFormik
                                                    name={ `productsList.${ productsListIndex }.${ index }.price` }
                                                    type="number"
                                                    inputProps={ { min: '0.01', max: '100.00', step: '0.01' } }
                                                    style={ { background: 'white' } }
                                                    disabled={ disabled }/>
                                                <FormHelperText error>
                                                    <span>
                                                        {
                                                            !isEmptyObject(formik?.errors?.products?.[productsListIndex]?.[index]?.price) &&
                                                            formik?.errors?.products?.[productsListIndex]?.[index]?.price
                                                        }
                                                    </span>
                                                </FormHelperText>
                                            </TableCell>
                                            {
                                                !disabled &&
                                                <TableCell align="center">
                                                    <IconButton
                                                        onClick={ () => onRemoveProduct(product?.position) }
                                                        title="Usuń">
                                                        <RemoveCircleIcon/>
                                                    </IconButton>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <FormHelperText error>
                        <span>
                            {
                                !isEmptyObject(formik?.errors?.productsList?.length) &&
                                formik?.errors?.productsList[productsListIndex]
                            }
                        </span>
                    </FormHelperText>
                </Grid>
            ) }
        />
    );
};

export default ProductsList;
