import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import { getFoodPartnerOrderHistoryState } from '../../../../../features/inventory/get_food_partner_order_history';

const FoodPartnerOrderHistoryDetails = ({ orderIndex, locationDivision }) => {
    const getFoodPartnerOrderHistory = useSelector(getFoodPartnerOrderHistoryState);
    const getAllPods = useSelector(getAllPodsState);

    return (
        <Grid container item>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nazwa produktu</TableCell>
                            <TableCell align="center">Ilość</TableCell>
                            <TableCell align="center">Cena</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !locationDivision &&
                            getFoodPartnerOrderHistory?.data?.content?.[orderIndex]?.positions?.map((position, index) => (
                                <TableRow
                                    key={ index + position?.productId }
                                    hover
                                >
                                    <TableCell>{ position?.productName }</TableCell>
                                    <TableCell align="center">{ position?.quantity }</TableCell>
                                    <TableCell align="center">{ position?.purchaseNetPrice?.toFixed(2) }</TableCell>
                                </TableRow>
                            ))
                        }
                        {
                            (getAllPods?.data && locationDivision) &&
                            Object.keys(getFoodPartnerOrderHistory?.data?.content?.[orderIndex]?.positions)?.map((key, index) => (
                                <Fragment key={ index }>
                                    <TableRow
                                        key={ key }
                                        hover
                                    >
                                        <TableCell colSpan={ 3 } align="center">
                                            {
                                                getAllPods?.data?.map(pod => ({ id: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + pod?.serviceId }))
                                                    .find(pod => pod?.id === key)?.label
                                            }
                                        </TableCell>
                                    </TableRow>
                                    {
                                        getFoodPartnerOrderHistory?.data?.content?.[orderIndex]?.positions?.[key]?.map((position, index) => (
                                            <TableRow
                                                key={ index + key }
                                                hover
                                            >
                                                <TableCell>{ position?.productName }</TableCell>
                                                <TableCell align="center">{ position?.quantity }</TableCell>
                                                <TableCell align="center">{ position?.purchaseNetPrice?.toFixed(2) }</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </Fragment>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default FoodPartnerOrderHistoryDetails;
